import { createStore, applyMiddleware, combineReducers,compose } from "redux";
import thunk from "redux-thunk";
import {usersReducer,getReviewData} from "../Reducers/DatatableQuiz.js";
import quizviewReducer from "../Reducers/quizReducer";
import EditRanking from "../Reducers/EditRanking";
import EditAnecdotesTagsReducer from "../Reducers/EditAnecdotesTags";
import MyReview from "../Reducers/MyReview";
import ReferalReducer from "../Reducers/Referal";
import RaiseCallRequestReducer from "../Reducers/RaiseCallRequest";
import Raiserequest from "../Reducers/Raiserequest";
import Totalvideos from "../Reducers/Totalvideos";
import Pendingvideos from "../Reducers/PendingVideo";
import TotalCompletedReducer from "../Reducers/Completedvideo";
import {leaderboard,sampleleaderboard} from "../Reducers/leadboard.js";
import UpdateAppSettings from "../Reducers/appsettings.js"
// import {RankingDataNewReducer} from "../Reducers/RankingData.js";
import { RankingDataNewReducer, AnecdotesDataReducer,getpreviousvideo } from "../Reducers/RankingData.js";
import {DATACOUNTstaging,DATACOUNTprod} from "../Reducers/Datacount.js";

const rootReducer = combineReducers({
  users: usersReducer,
  quiz: quizviewReducer,
  reviewdata:getReviewData,
  rankingedit: EditRanking,
  datacountstaging:DATACOUNTstaging,
  datacountprod:DATACOUNTprod,
  rankingdata:RankingDataNewReducer,
  anecdotesdata:AnecdotesDataReducer,
  anecdotesedit: EditAnecdotesTagsReducer,
  perviousvideo:getpreviousvideo,
  myreview: MyReview,
  referal: ReferalReducer,
  raisecallrequest: RaiseCallRequestReducer,
  raiserequest: Raiserequest,
  totalvideos: Totalvideos,
  pendingvideo:Pendingvideos,
  completedvideo:TotalCompletedReducer,
  leaderboard:leaderboard,
  updatesettings:UpdateAppSettings,
  sampleleaderboard:sampleleaderboard

});

// const store = createStore(rootReducer, applyMiddleware(thunk));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
