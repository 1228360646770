import React from 'react';
import { Link } from 'react-router-dom';

const YourComponent = () => {
  return (
    <>
      <div className="row mt-5 m-5 ">
    
        <div className="col-md-3 mt-5">
            <Link to="/AppSettings">
          <div className="card text-center shadow-5-strong">
            <center>
            <img
              src="Images/video-settings.png"
              alt="Video Management"
              className="mt-5"
              height={80}
              width={80}
            />
            </center>
            <div className="card-body">
              <h5 className="card-title">Change Signal App Version</h5>
            </div>
          </div>
          </Link>
        </div>
    
       
      </div>

      
    </>
  );
};

export default YourComponent;
