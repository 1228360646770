import axios from 'axios';
import {baseURL} from "../../Config/config"

export const FETCH_LEADERBOARD_REQUEST = 'FETCH_LEADERBOARD_REQUEST';
export const FETCH_LEADERBOARD_SUCCESS = 'FETCH_LEADERBOARD_SUCCESS';
export const FETCH_LEADERBOARD_FAILURE = 'FETCH_LEADERBOARD_FAILURE';

export const fetchLEADERBOARDRequest = () => ({
  type: FETCH_LEADERBOARD_REQUEST,
});

export const fetchLEADERBOARDSuccess = (data) => ({
  type: FETCH_LEADERBOARD_SUCCESS,
  payload: data,
});

export const fetchLEADERBOARDFailure = (error) => ({
  type: FETCH_LEADERBOARD_FAILURE,
  payload: error,
});

// export const fetchLEADERBOARD = (selectedMonth) => async (dispatch) => {
//   dispatch(fetchLEADERBOARDRequest());

//   try {
//     const response = await axios.get(`${baseURL}/Admin/fgbscore/getmonthwise/${selectedMonth}`);
//     dispatch(fetchLEADERBOARDSuccess(response.data.data));
//   } catch (error) {
//     dispatch(fetchLEADERBOARDFailure(error.message));
//   }
// };
export const fetchLEADERBOARD = (selectedMonth,selectedYear) => async (dispatch) => {
  dispatch(fetchLEADERBOARDRequest());

  console.log("Selected Year",selectedYear);
  try {
    const [response, response2] = await Promise.all([
      axios.get(`${baseURL}/Admin/fgbscore/getmonthwise/${selectedMonth}/${selectedYear}`),
      axios.get(`${baseURL}/Admin/calculatetotalscore`),
    ]);

    const data = response.data.data;
    // const data2 = response2.data.data;
    if (data.length > 0) {
      dispatch(fetchLEADERBOARDSuccess(data));
    } else {
      dispatch(fetchLEADERBOARDFailure('No data available for the selected month.'));
    }
  } catch (error) {
    dispatch(fetchLEADERBOARDFailure(error.message));
  }
};



export const FETCH_SAMPLELEADERBOARD_REQUEST = 'FETCH_SAMPLELEADERBOARD_REQUEST';
export const FETCH_SAMPLELEADERBOARD_SUCCESS = 'FETCH_SAMPLELEADERBOARD_SUCCESS';
export const FETCH_SAMPLELEADERBOARD_FAILURE = 'FETCH_SAMPLELEADERBOARD_FAILURE';

export const fetchSAMPLELEADERBOARDRequest = () => ({
  type: FETCH_SAMPLELEADERBOARD_REQUEST,
});

export const fetchSAMPLELEADERBOARDSuccess = (data) => ({
  type: FETCH_SAMPLELEADERBOARD_SUCCESS,
  payload: data,
});

export const fetchSAMPLELEADERBOARDFailure = (error) => ({
  type: FETCH_SAMPLELEADERBOARD_FAILURE,
  payload: error,
});

export const fetchSAMPLELEADERBOARD = (selectedMonth,selectedYear) => async (dispatch) => {
  dispatch(fetchSAMPLELEADERBOARDRequest());

  console.log("Selected Year",selectedYear);
  try {


    const response2 = await axios.get(`${baseURL}/Admin/Samplecalculatetotalscore`);
   const data = response2.data.data;
    // const data2 = response2.data.data;
    if (data.length > 0) {
      dispatch(fetchSAMPLELEADERBOARDSuccess(data));
    } else {
      dispatch(fetchSAMPLELEADERBOARDFailure('No data available for the selected month.'));
    }
  } catch (error) {
    dispatch(fetchSAMPLELEADERBOARDFailure(error.message));
  }
};