import React from "react";

function NoticeBoard() {
  return <>
  <center>
  <img src="Images/Soon.png" alt="Logo" width={400} />

  </center>
  </>;
}

export default NoticeBoard;
