import React from "react";
import { Link } from "react-router-dom";

function Support() {

  return (
    <div className="center-body card">
    <div class="button-container">
      <Link to="/Raiserequest">
      <button className="support-button ">Raise Request</button><br/>
      </Link>
      
      <Link to="/Raisecallequest">
      <button className="btn btn-outline-primary support-button-secound">Request Call Back</button>

      </Link>

    </div>

    </div>
  );
}

export default Support;
