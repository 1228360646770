import React, { useState} from "react";
import Swal from "sweetalert2";
import getUserIdFromToken from "../../Utils/JWT/jwt";
import getToken from "../../Utils/JWT/token";
import {baseURL} from "../../Config/config";

function ResetPassword() {
    const [currentPassword, setCurrentPassword] = useState('');

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const isLengthValid = newPassword.length >= 8;

    setPasswordValid(
      hasUpperCase && hasLowerCase && hasNumber && isLengthValid
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setPasswordMatch(false);
      return;
    }

    if (!passwordValid) {
      return;
    }

    const order = {
      currentPassword,
      password,
    };
const id=getUserIdFromToken();
const token=getToken();
    try {
      const response = await fetch(
        `${baseURL}/users/changepassword/FGB/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(order),
        }
      );

      if (response.ok) {
        setPasswordResetSuccess(true);
      } else {
        const responseData = await response.json(); // Parse the response body as JSON
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: responseData.message, // Show the error message from the response data
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="container-fluid">
    <div className="row justify-content-center align-items-center vh-100">
      <div className="col-md-6">
        <div className="card">
          <div className="card-header bg-primary text-white text-center">
            <h3 className="mb-0">Set Password</h3>
          </div>
          <center>
            <img src="https://fgb.signalz.ai/Images/mainlogo.png" height={60} className="mt-5" alt="logo" />
          </center>
          <hr />

          {passwordResetSuccess ? (
            <div className="alert alert-success">
              Password Set successful! You can now log in with your new password.
            </div>
          ) : (
            <form className="card-body" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="currentPassword">Current Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="currentPassword"
                  name="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">New Password:</label>
                <input
                  type="password"
                  className={`form-control ${passwordMatch ? '' : 'is-invalid'}`}
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                {!passwordValid && (
                  <p className="text-danger">
                    Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, and one number.
                  </p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password:</label>
                <input
                  type="password"
                  className={`form-control ${passwordMatch ? '' : 'is-invalid'}`}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                {!passwordMatch && (
                  <p className="text-danger">Passwords do not match.</p>
                )}
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  </div>
);
}

export default ResetPassword;
