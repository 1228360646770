import axios from "axios";
import {baseURL} from "../../Config/config"

export const FETCH_TOTALVIDEOS_REQUEST = "FETCH_TOTALVIDEOS_REQUEST";
export const FETCH_TOTALVIDEOS_SUCCESS = "FETCH_TOTALVIDEOS_SUCCESS";
export const FETCH_TOTALVIDEOS_FAILURE = "FETCH_TOTALVIDEOS_FAILURE";

export const fetchtotalvideosRequest = () => ({
  type: FETCH_TOTALVIDEOS_REQUEST,
});

export const fetchtotalvideosSuccess = (data) => ({
  type: FETCH_TOTALVIDEOS_SUCCESS,
  payload: data,
});

export const fetchtotalvideosFailure = (error) => ({
  type: FETCH_TOTALVIDEOS_FAILURE,
  payload: error,
});

export const fetchtotalvideos = () => async (dispatch) => {
  dispatch(fetchtotalvideosRequest());
  try {
    const response = await axios.get(
      `${baseURL}/Admin/getquizes/Total/Videos`
    );
    dispatch(fetchtotalvideosSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchtotalvideosFailure(error.message));
  }
};
