import {
    FETCH_COMPLETEDDATA_REQUEST,
    FETCH_COMPLETEDDATA_SUCCESS,
    FETCH_COMPLETEDDATA_FAILURE,
    UPDATE_QUIZ_ANSWER_SUCCESS,
  } from '../Action/EditRanking.action';
  

  const initialState = {
    data: [],
    isLoading: false,
    error: null,
  };
  
  const EditAnecdotesTags = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COMPLETEDDATA_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case FETCH_COMPLETEDDATA_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case FETCH_COMPLETEDDATA_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
        case UPDATE_QUIZ_ANSWER_SUCCESS:
            const updatedData = state.data.map((quiz) => {
              if (quiz.id === action.payload.id) {
                return action.payload;
              }
              return quiz;
            });
    
            return {
              ...state,
              data: updatedData,
            };
          default:
            return state;
    }
  };


  
  export default EditAnecdotesTags;
  