import React, { useEffect, useState } from "react";
import "../../Assets/Css/Dashboard.css";
import {baseURL} from "../../Config/config"

import ApexCharts from "react-apexcharts";
import { Bars } from "react-loader-spinner";

const Dashboard = () => {
  const accessTokenWithQuotes = localStorage.getItem("user");
  const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

  const [header, payload] = accessTokenWithoutQuotes.split(".");
  const decodedPayload = JSON.parse(atob(payload));
  const role = decodedPayload.role;
  const [userDetails, setUserDetails] = useState([]);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Count",
        data: [],
      },
    ],
    options: {
      chart: {
        id: "line-chart",
      },
      xaxis: {
        categories: [],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = `${baseURL}/Admin/data/graph/createdat`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        if (responseData.success && responseData.data.length > 0) {
          const dataItems = responseData.data;

          const chartDataValues = dataItems.map((item) => item.userCount);
          const chartLabels = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          setChartData((prevChartData) => ({
            ...prevChartData,
            series: [
              {
                name: "Count",
                data: chartDataValues,
              },
            ],
            options: {
              ...prevChartData.options,
              xaxis: {
                categories: chartLabels,
              },
            },
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  //An Issue For Fetch The Data
  // useEffect(() => {
  //   fetch(`${baseURL}/Admin/dashboard/data/get/Remove`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         throw new Error("Failed to fetch user details");
  //       }
  //     })
  //     .then((data) => {
  //       setUserDetails(data.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);
  if (role === "ADMIN") {
    return (
      <div className="container">
        <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold ">
        <h3>Admin Dashboard</h3>
      </div>
          <center>
        <div className="graph-div card mt-5 text-center shadow-5-strong">
        

          <ApexCharts
            options={chartData.options}
            series={chartData.series}
            type="line"
            height="350"
          />
     
        </div>
        </center>
        {/* {userDetails.map((user) => (
          <div class="main-section ">
            <div className="shadow-5-strong card mt-5">
          <div className="m-5 ">

            <div class="dashbord me-5 shadow-5-strong">
              <div class="icon-section">
                <i class="fa fa-users" aria-hidden="true"></i>
                <br />
                <h4>Parent</h4>
                <p>{user.Parent}</p>
              </div>
              <div class="detail-section">
                <a href="#">Total Parent's</a>
              </div>
            </div>
            <div class="dashbord dashbord-green me-5">
              <div class="icon-section">
                <i class="fa fa-users" aria-hidden="true"></i>
                <br />
                <h4>Child</h4>
                <p>{user.Child}</p>
              </div>
              <div class="detail-section">
                <a href="#">Total Child's</a>
              </div>
            </div>
            <div class="dashbord dashbord-orange me-5">
              <div class="icon-section">
                <i class="fa fa-users" aria-hidden="true"></i>
                <br />
                <h4>Admin</h4>
                <p>{user.Admin}</p>
              </div>
              <div class="detail-section">
                <a href="#">Total Admin's </a>
              </div>
              </div>
 
            <div class="dashbord dashbord-skyblue me-5">
              <div class="icon-section">
                <i class="fa fa-users" aria-hidden="true"></i>
                <br />
                <h4>FGB</h4>
                <p>{user.FGB}</p>
              </div>
              <div class="detail-section">
                <a href="#">Total FGB </a>
              </div>
            </div>
            </div>
            </div>
            <div className="shadow-5-strong card mt-5">
           <div className="m-5 my-5">

            <div class="dashbord dashbord-blue me-5 shadow-5-strong">
              <div class="icon-section">
                <i class="fa fa-users" aria-hidden="true"></i>
                <br />
                <h4>Learning</h4>
                <p>{user.TotalLearningCount}</p>
              </div>
              <div class="detail-section">
                <a href="#">Total Learning</a>
              </div>
            </div>
            <div class="dashbord dashbord-red me-5">
              <div class="icon-section">
                <i class="fa fa-users" aria-hidden="true"></i>
                <br />
                <h4>Shadow Practice</h4>
                <p> {user.TotalShadowpracticeCount}</p>
              </div>
              <div class="detail-section">
                <a href="#">Total Shadow Practice</a>
              </div>
            </div>
            <div class="dashbord dashbord-skyblue me-5">
              <div class="icon-section">
                <i class="fa fa-users" aria-hidden="true"></i>
                <br />
                <h4>better Me</h4>
                <p>{user.TotalbettermeCount}</p>
              </div>
              <div class="detail-section">
                <a href="#">Total Better Me</a>
              </div>
            </div>
            <div class="dashbord dashbord-red me-5">
              <div class="icon-section">
                <i class="fa fa-users" aria-hidden="true"></i>
                <br />
                <h4>Interest</h4>
                <p> {user.TotalinterestCount}</p>
              </div>
              <div class="detail-section">
                <a href="#">Total Shadow Practice</a>
              </div>
            </div>
          </div>
          </div>
          </div>
        ))} */}
      </div>
    );
  } else {
  }
};

export default Dashboard;
