import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
} from "../Action/action";

import{
  FETCH_REVIEWDATA_REQUEST,
  FETCH_REVIEWDATA_SUCCESS,
  FETCH_REVIEWDATA_FAILURE
} from "../Action/DatatableQuiz.action"

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_USERS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case FETCH_USERS_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return state;
  }
};

const getReviewData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REVIEWDATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_REVIEWDATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case FETCH_REVIEWDATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export {usersReducer,getReviewData};
