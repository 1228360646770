import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams, Link, json } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { baseURL } from "../../../Config/config"

function CardDetailsPage() {
  const { userId } = useParams();
  const [selectedQuiz, setSelectedQuiz] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [userData, setUserData] = useState({});
  const [userIdRanking, setuserIdRanking] = useState({});
  const [learnId, setlearnId] = useState({});

  const accessTokenforuserid = localStorage.getItem("user");

  const accessTokenforuseridtwo = accessTokenforuserid.replace(/"/g, "");

  const [header, payload] = accessTokenforuseridtwo.split(".");
  const decodedPayload = JSON.parse(atob(payload));
  const [totalscore, setTotalscore] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${baseURL}/Admin/getquizes/all/fgbusers/count/${userId}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();

        setUserData(jsonData.data);
        setuserIdRanking(jsonData.data.userId)

      } catch (error) { }
    };

    fetchData();
  }, [userId]);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseURL}/Admin/get/Annedotes/${userId}`, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      console.log("******************",jsonData);

      const newTotalscore = parseFloat(jsonData.data[0].Totalscore || 0);
      console.log("?>?>?>?>", newTotalscore)

      setTotalscore(newTotalscore);
      setData(jsonData.data);
      setlearnId(jsonData.data[0].AnecdotesId);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }

    // fetch(`${baseURL}/Admin/get/Annedotes/${userId}`)
    //   .then((response) => response.json())
    //   .then((jsonData) => {


    //     setData(jsonData.data);
    //     setlearnId(jsonData.data.id)
    //     console.log(jsonData.data[0].id);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const uniqueData = Array.from(
    new Set(
      data.map((item) => JSON.stringify({ id: item.id, Topic: item.Topic }))
    )
  ).map((stringified) => JSON.parse(stringified));

  const uniqueAgesMap = new Map();
  const uniquelink = new Map();
  const uniquetopic = new Map();

  data.forEach((entry) => {
    const key = `${entry.id}-${entry.Topic}`;
    if (!uniquetopic.has(key)) {
      uniquetopic.set(key, entry.Title);
    }
  });

  data.forEach((entry) => {
    const key = `${entry.id}-${entry.Topic}`;
    if (!uniquelink.has(key)) {
      uniquelink.set(key, entry.link);
    }
  });

  data.forEach((entry) => {
    const key = `${entry.id}-${entry.Topic}`;
    if (!uniqueAgesMap.has(key)) {
      uniqueAgesMap.set(key, entry.Age);
    }
  });

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredData = searchQuery
    ? uniqueData.filter((item) =>
      item.Topic.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : uniqueData;

  const currentData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(0);
  };
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const extractVideoId = (link) => {
    const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };
  const openRankingPopup = (id) => {
    setSelectedQuiz(id);
  };

  const closeRankingPopup = () => {
    setSelectedQuiz(null);
  };
  const filteredDatas = data.filter((item) => item.id === selectedQuiz);


  const [entry, setEntry] = useState([]);

  const [buttonStates, setButtonStates] = useState({
    Anedotes1: false,
    Anedotes2: false,
    Anedotes3: false,
    Anedotes4: false,
    Anedotestime1: false,
    Anedotestime2: false,
    Anedotestime3: false,
    Anedotestime4: false,
    tags1: false,
    tags2: false,
    tags3: false,
    tags4: false,
    tags5: false,
    tags6: false,
    tags7: false,
    tags8: false,
    tags9: false,
    tags10: false,
  });


  const handleChange = (name, value) => {
    setEntry({
      ...entry,
      [name]: value,
    });
  };

  const handleClick = (name) => {
    if (!buttonStates[name]) {
      const label = "Accepted";
      console.log(name, ":", label);
      setEntry({
        ...entry,
        [name]: label,
      });
      setButtonStates({ ...buttonStates, [name]: true });
    }
  };


  const handleShowAllData = (id, AnecdotesId) => {
    console.log("All Data:", AnecdotesId);
    let anedotesScore = 0;
    let anedotestimeScore = 0;
    let tagsScore = 0;

    for (const field in entry) {
      if (entry.hasOwnProperty(field)) {
        const label = buttonStates[field] ? "Accepted" : "Rejected";
        let score = 0;

        if (field.startsWith("Anedotes")) {
          score = buttonStates[field] ? 1.875 : 0;
          anedotesScore += score;
        } else if (field.startsWith("Anedotestime")) {
          score = buttonStates[field] ? 1.875 : 0;
          anedotestimeScore += score;
        } else if (field.startsWith("tags")) {
          score = buttonStates[field] ? 1.5 : 0;
          tagsScore += score;
        }

        console.log(`${field}: ${entry[field]}, Status: ${label}, Score: ${score}`);
      }
    }

    const score = anedotesScore + anedotestimeScore + tagsScore;
    const anecdotestagsId = AnecdotesId;
    console.log("+______________________________________________________",anecdotestagsId);
    const userId = userIdRanking;
    const learningId = id;
    console.log("User id For Anecdotes Ranking", learningId);


    const dataToStore = { ...entry, score, anecdotestagsId, userId, learningId };

    console.log("Data to Store:", dataToStore);


    fetch(`${baseURL}/raisecallrequest/acceptrejectannecdotes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessTokenforuseridtwo}`,

      },
      body: JSON.stringify(dataToStore),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Data stored successfully:', result);
        alert("Data Saved");
        closeRankingPopup();
        setButtonStates({
          Anedotes1: false,
          Anedotes2: false,
          Anedotes3: false,
          Anedotes4: false,
          Anedotestime1: false,
          Anedotestime2: false,
          Anedotestime3: false,
          Anedotestime4: false,
          tags1: false,
          tags2: false,
          tags3: false,
          tags4: false,
          tags5: false,
          tags6: false,
          tags7: false,
          tags8: false,
          tags9: false,
          tags10: false,
        });
      })
      .catch((error) => {
        console.error('Error storing data:', error);
      });
  };

  return (
    <>
      {isLoading && (
        <div className="Loader">
          <Bars
            height={80}
            width={80}
            color="#4fa94d"
            ariaLabel="bars-loading"
          />
        </div>
      )}

      <div>
        <br />
        &nbsp;
        <Link to={`/Fgbstatus/${userId}`}>
          <img
            src="/Images/left-arrow.png"
            height={50}
            width={50}
            alt="leftarrow"
          />
        </Link>
        <div className="container mt-5">
          <div className="row">
            <div className="col">
              <strong>User ID:</strong> {userData.userId}
            </div>
            <div className="col">
              <strong>Full Name:</strong> {userData.fullName}
            </div>
            <div className="col">
              <strong>Completed:</strong> {userData.Count}
            </div>
            <div className="col">
              <strong>Total Remaining:</strong> {userData.TotalRemaining}
            </div>
            <div className="col">
              <strong>Total Video:</strong> {userData.TotalVideo}
            </div>
          </div>
        </div>
        <div className="table-responsive table-container">
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr className="table-primary">
                {/* <th>ID</th> */}
                <th>Video Link</th>
                <th>Title</th>
                <th>Topic</th>
                <th>Age</th>
                <th> View Anecdotes & Tags</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, parentIndex) => (
                <React.Fragment
                  key={`parent-${item.id}-${item.Topic}-${parentIndex}`}
                >
                  {data
                    .filter(
                      (entry) =>
                        entry.id === item.id && entry.Topic === item.Topic
                    )
                    .map((entry, childIndex) => (
                      <tr
                        key={`child-${entry.id}-${entry.Topic}-${entry.Quiz}-${childIndex}`}
                      >
                        {/* {childIndex === 0 ? (
                          <td
                            className="center-cell"
                            rowSpan={
                              data.filter(
                                (e) =>
                                  e.id === item.id && e.Topic === item.Topic
                              ).length
                            }
                          >
                            {item.id}
                          </td>
                        ) : null} */}
                        {childIndex === 0 ? (
                          <td
                            rowSpan={
                              data.filter(
                                (e) =>
                                  e.id === item.id && e.Topic === item.Topic
                              ).length
                            }
                          >
                            <iframe
                              width="560"
                              height="315"
                              src={`https://www.youtube.com/embed/${extractVideoId(
                                uniquelink.get(`${item.id}-${item.Topic}`)
                              )}?modestbranding=1`}
                              frameborder="0"
                              allowfullscreen
                            ></iframe>
                          </td>
                        ) : null}

                        {childIndex === 0 ? (
                          <td
                            rowSpan={
                              data.filter(
                                (e) =>
                                  e.id === item.id && e.Topic === item.Topic
                              ).length
                            }
                          >
                            {uniquetopic.get(`${item.id}-${item.Topic}`)}
                          </td>
                        ) : null}
                        {childIndex === 0 ? (
                          <td
                            rowSpan={
                              data.filter(
                                (e) =>
                                  e.id === item.id && e.Topic === item.Topic
                              ).length
                            }
                          >
                            {item.Topic}
                          </td>
                        ) : null}
                        {childIndex === 0 ? (
                          <td
                            rowSpan={
                              data.filter(
                                (e) =>
                                  e.id === item.id && e.Topic === item.Topic
                              ).length
                            }
                          >
                            {uniqueAgesMap.get(`${item.id}-${item.Topic}`)}
                          </td>
                        ) : null}

                        <div className="d-inline-block">
                          <td>
                            <div style={{ width: "180px" }}>
                              <button
                                className="btn btn-secondary"
                                onClick={() => openRankingPopup(entry.id)}
                              >
                                View Anecdotes
                              </button>
                            </div>
                          </td>
                          {selectedQuiz && (
                            <div className="popup">
                              <div className="popup-content">
                                {filteredDatas.map((entry) => (
                                  <div key={entry.id}>
                                    <div className="popup-header d-flex justify-content-between align-items-center">
                                      <p className="mb-0 fs-5">Total Score: <span className="badge bg-primary">{entry.Totalscore}</span></p>
                                      <button
                                        className="btn btn-close text-white"
                                        onClick={closeRankingPopup}
                                      ></button>
                                    </div>
                                    <br /><br />

                                    <div class="popup-content d-flex flex-row mb-3 bg-secondary">
                                      <div class="p-2 w-50">
                                        <div>
                                          <h5
                                            className="modal-title text-center"
                                            id="exampleModalLabel"
                                          >
                                            Anecdotes
                                          </h5>

                                          <form>
                                            <div className="form-outline mb-4">
                                              <span style={{ fontSize: "15px" }}>Anecdotes 1</span>
                                              <input
                                                type="text"
                                                id="form1Example1"
                                                className="form-control bg-light"
                                                name="Anedotes1"
                                                value={entry.Anedotes1}
                                                required
                                                rows="2"
                                              />
                                              <div>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleClick("Anedotes1", entry.id);
                                                  }}
                                                  disabled={buttonStates["Anedotes1"]}
                                                >
                                                  {buttonStates["Anedotes1"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                                </button>
                                              </div>
                                            </div>

                                            <div className="form-outline mb-4">
                                              <span style={{ fontSize: "15px" }}>Anecdotes 2</span>
                                              <input
                                                type="text"
                                                id="form1Example2"
                                                className="form-control bg-light"
                                                name="Anedotes2"
                                                value={entry.Anedotes2}

                                                required
                                                rows="2"
                                              />
                                              <div>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleClick("Anedotes2", entry.id);
                                                  }}
                                                  disabled={buttonStates["Anedotes2"]}
                                                >
                                                  {buttonStates["Anedotes2"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                                </button>
                                              </div>
                                            </div>

                                            <div className="form-outline mb-4">
                                              <span style={{ fontSize: "15px" }}>Anecdotes 3</span>
                                              <input

                                                type="text"
                                                id="form1Example3"
                                                className="form-control bg-light"
                                                name="Anedotes3"
                                                value={entry.Anedotes3}

                                                required
                                                rows="2"
                                              />
                                              <div>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleClick("Anedotes3", entry.id);
                                                  }}
                                                  disabled={buttonStates["Anedotes3"]}
                                                >
                                                  {buttonStates["Anedotes3"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                                </button>
                                              </div>
                                            </div>

                                            <div className="form-outline mb-4">
                                              <span style={{ fontSize: "15px" }}>Anecdotes 4</span>
                                              <input
                                                type="text"
                                                id="form1Example4"
                                                className="form-control bg-light"
                                                name="Anedotes4"
                                                required
                                                value={entry.Anedotes4}

                                                rows="2"
                                              />
                                              <div>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleClick("Anedotes4", entry.id);
                                                  }}
                                                  disabled={buttonStates["Anedotes4"]}
                                                >
                                                  {buttonStates["Anedotes4"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                                </button>
                                              </div>
                                            </div>
                                            {/* <button
  type="button"
  onClick={() => handleShowAllData(entry.id,entry.AnecdotesId)} 
>
  Save
</button> */}
                                            <button
                                              type="button"
                                              class="btn btn-primary btn-lg"
                                              disabled={parseFloat(entry.Totalscore) > 0}
                                              onClick={() => handleShowAllData(entry.id, entry.AnecdotesId)}
                                            >
                                              Save
                                            </button>
                                          </form>
                                        </div>
                                      </div>
                                      &nbsp;&nbsp;&nbsp;
                                      <div class="vl"> </div>
                                      &nbsp;&nbsp;&nbsp;

                                      <div class="p-2 w-50">
                                        <div>
                                          <h5 className="modal-title text-center" id="exampleModalLabel">
                                            TimeStamp
                                          </h5>
                                          <div class="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Anecdotes Time 1</span>
                                            <input
                                              type="text"
                                              id="form1Example1"
                                              class="form-control bg-light"
                                              name="Anedotestime1"
                                              value={entry.Anedotestime1}
                                              disabled
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("Anedotestime1", entry.id);
                                                }}
                                                disabled={buttonStates["Anedotestime1"]}
                                              >
                                                {buttonStates["Anedotestime1"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>
                                          <div class="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Anecdotes Time 2</span>
                                            <input
                                              type="text"
                                              id="form1Example1"
                                              class="form-control bg-light"
                                              name="Anedotestime2"
                                              value={entry.Anedotestime2}
                                              disabled
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("Anedotestime2", entry.id);
                                                }}
                                                disabled={buttonStates["Anedotestime2"]}
                                              >
                                                {buttonStates["Anedotestime2"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>
                                          <div class="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Anecdotes Time 3</span>
                                            <input
                                              type="text"
                                              id="form1Example1"
                                              class="form-control bg-light"
                                              name="Anedotestime3"
                                              value={entry.Anedotestime3}
                                              disabled
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("Anedotestime3", entry.id);
                                                }}
                                                disabled={buttonStates["Anedotestime3"]}
                                              >
                                                {buttonStates["Anedotestime3"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>
                                          <div class="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Anecdotes Time 4</span>
                                            <input
                                              type="text"
                                              id="form1Example1"
                                              class="form-control bg-light"
                                              name="Anedotestime4"
                                              value={entry.Anedotestime4}
                                              disabled
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("Anedotestime4", entry.id);
                                                }}
                                                disabled={buttonStates["Anedotestime4"]}
                                              >
                                                {buttonStates["Anedotestime4"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      &nbsp;&nbsp;&nbsp;
                                      <div class="vl"> </div>
                                      &nbsp;&nbsp;&nbsp;
                                      <div className="p-2 w-50">
                                        <div>
                                          <h5 className="modal-title text-center" id="exampleModalLabel">
                                            Tags
                                          </h5>
                                          <div className="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Tag 1</span>
                                            <input
                                              type="text"
                                              id="form1Example1"
                                              className="form-control bg-light"
                                              name="tags1"
                                              value={entry.tags1}
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("tags1");
                                                }}
                                                disabled={buttonStates["tags1"]}
                                              >
                                                {buttonStates["tags1"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>

                                          {/* Add more tags here */}
                                          <div className="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Tag 2</span>
                                            <input
                                              type="text"
                                              id="form1Example2"
                                              className="form-control bg-light"
                                              name="tags2"
                                              value={entry.tags2}
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("tags2");
                                                }}
                                                disabled={buttonStates["tags2"]}
                                              >
                                                {buttonStates["tags2"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>

                                          <div className="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Tag 3</span>
                                            <input
                                              type="text"
                                              id="form1Example3"
                                              className="form-control bg-light"
                                              name="tags3"
                                              value={entry.tags3}
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("tags3");
                                                }}
                                                disabled={buttonStates["tags3"]}
                                              >
                                                {buttonStates["tags3"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>

                                          <div className="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Tag 4</span>
                                            <input
                                              type="text"
                                              id="form1Example4"
                                              className="form-control bg-light"
                                              name="tags4"
                                              value={entry.tags4}
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("tags4");
                                                }}
                                                disabled={buttonStates["tags4"]}
                                              >
                                                {buttonStates["tags4"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>

                                          <div className="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Tag 5</span>
                                            <input
                                              type="text"
                                              id="form1Example5"
                                              className="form-control bg-light"
                                              name="tags5"
                                              value={entry.tags5}
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("tags5");
                                                }}
                                                disabled={buttonStates["tags5"]}
                                              >
                                                {buttonStates["tags5"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      &nbsp;&nbsp;&nbsp;
                                      <div class="vl"> </div>
                                      &nbsp;&nbsp;&nbsp;

                                      <div className="p-2 w-50">
                                        <div>
                                          <h5 className="modal-title text-center" id="exampleModalLabel">
                                            Tags
                                          </h5>
                                          <div className="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Tag 6</span>
                                            <input
                                              type="text"
                                              id="form1Example6"
                                              className="form-control bg-light"
                                              name="tags6"
                                              value={entry.tags6}
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("tags6");
                                                }}
                                                disabled={buttonStates["tags6"]}
                                              >
                                                {buttonStates["tags6"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>

                                          <div className="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Tag 7</span>
                                            <input
                                              type="text"
                                              id="form1Example7"
                                              className="form-control bg-light"
                                              name="tags7"
                                              value={entry.tags7}
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("tags7");
                                                }}
                                                disabled={buttonStates["tags7"]}
                                              >
                                                {buttonStates["tags7"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>

                                          <div className="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Tag 8</span>
                                            <input
                                              type="text"
                                              id="form1Example8"
                                              className="form-control bg-light"
                                              name="tags8"
                                              value={entry.tags8}
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("tags8");
                                                }}
                                                disabled={buttonStates["tags8"]}
                                              >
                                                {buttonStates["tags8"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>

                                          <div className="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Tag 9</span>
                                            <input
                                              type="text"
                                              id="form1Example9"
                                              className="form-control bg-light"
                                              name="tags9"
                                              value={entry.tags9}
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("tags9");
                                                }}
                                                disabled={buttonStates["tags9"]}
                                              >
                                                {buttonStates["tags9"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>

                                          <div className="form-outline mb-4">
                                            <span style={{ fontSize: "15px" }}>Tag 10</span>
                                            <input
                                              type="text"
                                              id="form1Example10"
                                              className="form-control bg-light"
                                              name="tags10"
                                              value={entry.tags10}
                                              required
                                              rows="2"
                                            />
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleClick("tags10");
                                                }}
                                                disabled={buttonStates["tags10"]}
                                              >
                                                {buttonStates["tags10"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                    {/* <div className="d-flex flex-row mb-3">
      <div className="p-2">
        <span style={{ fontSize: "15px" }}>
          <a data-mdb-toggle="tooltip" title="Suggestion of 4 anecdotes with appropriate time stamps: Should be relevant, informative and useful as well as precise">Anecdotes <i className="fa fa-info-circle" aria-hidden="true"></i></a>
        </span>
        <input
          type="text"
          id="anecdotesInput"
          className="form-control bg-light"
          name="anecdotes"
          value={anecdotes}
          onChange={(e) => setAnecdotes(e.target.value)}
          required
          rows="2"
          maxLength={2}
        />
      </div>
      <div className="p-2">
        <span style={{ fontSize: "15px" }}>
          <a data-mdb-toggle="tooltip" title="Appropriate tags (10): Has to have all relevant and relatable tags that can connect to multi disciplines ">Tags <i className="fa fa-info-circle" aria-hidden="true"></i></a>
        </span>
        <input
          type="text"
          id="tagsInput"
          className="form-control bg-light"
          name="tags"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          required
          rows="2"
          maxLength={2}
        />
      </div>

      <div className="p-2">
        <span style={{ fontSize: "15px" }}>
          <a data-mdb-toggle="tooltip" title="Anecdotes + Tags = Total">Total Score For Anecdotes & Tags  <i className="fa fa-info-circle" aria-hidden="true"></i></a>
        </span>
        <div className="bg-light" style={{ padding: "0.375rem 0.75rem" }}>
          =&nbsp;&nbsp;&nbsp; {totalScore}
        </div>
      </div>

      <div className="p-2">
      <button
  type="button"
  className="btn btn-primary"
  onClick={() => handleSubmit(entry.AnecdotesId)}
>
          Submit
        </button>
      </div>
    </div> */}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="pagination-container align-items-center">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CardDetailsPage;
