import React, { useState, useEffect } from "react";
import "../../../Assets/Css/RegisterForm.css";
import { Bars } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings, updateappsettings } from "../../../Redux/Action/appsettings";

function AppSettings() {
  const dispatch = useDispatch();
//   const settingsData = useSelector((state) => state.appsettings.data);
const settingsData = useSelector((state) => state.updatesettings);

console.log("??????????????????????????",settingsData)

// Assuming settingsData is the object you provided
const version = settingsData.data && settingsData.data && settingsData.data.length > 0
  ? settingsData.data[0].version
  : null;

console.log("Version:", version);

   const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    version: "",
  });

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (settingsData) {
      setFormData({
        version: settingsData.version || "", 
      });
      setIsLoading(false);
    }
  }, [settingsData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!formData.version.trim()) {
      validationErrors.version = "Version is required";
    }

    if (Object.keys(validationErrors).length === 0) {
      dispatch(updateappsettings(formData));
    } else {
      setErrors(validationErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "version") {
      setFormData({ ...formData, [name]: value.trim() });
    }

    setErrors({ ...errors, [name]: "" });
  }; 


  return (
    <div className="p-3 mt-5">
      {isLoading && (
        <div className="Loader">
          <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
        </div>
      )}
      <div className="registration-container mb-5">
        <form className="registration-form">
          <div className="form-group">
            <label htmlFor="version">Version:</label>
            <input
              type="text"
              className="input-Role"
              id="version"
              name="version"
              value={formData.version}
              onChange={handleChange}
            />
            {errors.version && <span className="text-danger">{errors.version}</span>}
          </div>
          <button
            type="button"
            className="btn btn-primary btn-rounded w-25"
            onClick={handleSubmit}
          >
            Update
          </button>
          {/* <p>Current Version: {formData.version}</p> */}

        </form>
      </div>
    </div>
  );
}

export default AppSettings;
