
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { Rate } from "antd";
import { fetchQuizes, updateRankindData } from "../../Redux/Action/EditRanking.action";
import { fetchrankingdata, fetchanecdotesdata } from "../../Redux/Action/RankingData.action";
import { useDispatch, useSelector } from "react-redux";
import { updateanecdotesnew } from '../../Redux/Action/EditAnecdotesTags.action';
import { fetchquizuserwise, updateQuizAnswer } from "../../Redux/Action/quizActions.action";
import getUserIdFromToken from "../../Utils/JWT/jwt";
import { baseURL } from "../../Config/config"
import getToken from "../../Utils/JWT/token";
import { NotificationManager } from 'react-notifications';

function ViewQuestion() {
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  const [showPopup, setShowPopup] = useState(false);
  const [rankingData, setRankingData] = useState(null);
  const [anecdotesData, setAnecdotesData] = useState(null);
  const [showAnecdotesPopup, setShowAnecdotesPopup] = useState(false);
  const [showAnecdotesPopupnew, setShowAnecdotesPopupnew] = useState(false);

  const [showQuizPopup, setShowQuizPopup] = useState(false);
  const userId = getUserIdFromToken();
  const accessTokenWithoutQuotes = getToken();



  const [quizData, setquizData] = useState(null);

  const [editedFeedback, setEditedFeedback] = useState("");
  const [neweditedFeedback, setNewEditedFeedback] = useState("");

  const [editState, setEditState] = useState({});

  const [learningId, setLearningId] = useState("");



  const resetAnecdotesValues = () => {
    setEditedValuesanecdotes({
      Anedotes1: '',
      Anedotes2: '',
      Anedotes3: '',
      Anedotes4: '',
      Anedotestime1: '',
      Anedotestime2: '',
      Anedotestime3: '',
      Anedotestime4: '',
      tags1: '',
      tags2: '',
      tags3: '',
      tags4: '',
      tags5: '',
      tags6: '',
      tags7: '',
      tags8: '',
      tags9: '',
      tags10: '',
      suggestedLinks: '',
      Annecdotesfeedback: '',
    });
    initialEditedValuesanecdotes()
  };
  const handleFeedbackChange = (id, value) => {
    console.log(">>>>>>>>>>>>>>>>", value);
    setEditedFeedback((prev) => ({ ...prev, [id]: value }));
    const questionFeedback = editedFeedback[Object.keys(editedFeedback)];
    console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&7", value);
    setNewEditedFeedback(questionFeedback)
    setNewEditedFeedback((prev) => ({ ...prev, [id]: value }));
  };


  const handleEdit = (id) => {
    setEditState((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleSave = (id, answer, QuestionFeedback) => {
    dispatch(updateQuizAnswer(id, answer, QuestionFeedback));

    setEditState((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const initialEditedValues = {
    id: '',
    Delivery: '',
    ContentQuality: '',
    EngagementLevel: '',
    Duration: '',
    rankingfeedback: '',
    rankdelivery: 0,
    rankContentQuality: 0,
    rankDuration: 0,
    rankEngagementLevel: 0,
    totalRankaverage: 0

  };

  const initialEditedValuesanecdotes = {
    AnecdotesId: "",
    Anedotes1: "",
    Anedotes2: "",
    Anedotes3: "",
    Anedotes4: "",
    Anedotestime1: "",
    Anedotestime2: "",
    Anedotestime3: "",
    Anedotestime4: "",
    tags1: "",
    tags2: "",
    tags3: "",
    tags4: "",
    tags5: "",
    tags6: "",
    tags7: "",
    tags8: "",
    tags9: "",
    tags10: "",
    suggestedLinks: "",
    Annecdotesfeedback: ""

  };

  const initialEditedValuesQuiz = {
    answer: "",
    quizId: "",
    QuestionFeedback: ""

  };

  const [editedValues, setEditedValues] = useState(initialEditedValues);
  const [editedValuesanecdotes, setEditedValuesanecdotes] = useState(initialEditedValues);

  const [editedValuesquiz, setEditedValuesquiz] = useState(initialEditedValues);

  useEffect(() => {
    if (quizData && quizData.length > 0) {
      setEditedValuesquiz({
        answer: quizData[0]?.answer || '',
        quizId: quizData[0]?.quizId || '',
        QuestionFeedback: quizData[0]?.QuestionFeedback || ''

      });
    } else {
      setEditedValuesquiz(initialEditedValuesQuiz);
    }
  }, [quizData]);


  useEffect(() => {
    if (anecdotesData && anecdotesData.length > 0) {
      setEditedValuesanecdotes({
        AnecdotesId: anecdotesData[0]?.AnecdotesId || '',
        Anedotes1: anecdotesData[0]?.Anedotes1 || '',
        Anedotes2: anecdotesData[0]?.Anedotes2 || '',
        Anedotes3: anecdotesData[0]?.Anedotes3 || '',
        Anedotes4: anecdotesData[0]?.Anedotes4 || '',
        Anedotestime1: anecdotesData[0]?.Anedotestime1 || '',
        Anedotestime2: anecdotesData[0]?.Anedotestime2 || '',
        Anedotestime3: anecdotesData[0]?.Anedotestime3 || '',
        Anedotestime4: anecdotesData[0]?.Anedotestime4 || '',
        tags1: anecdotesData[0]?.tags1 || '',
        tags2: anecdotesData[0]?.tags2 || '',
        tags3: anecdotesData[0]?.tags3 || '',
        tags4: anecdotesData[0]?.tags4 || '',
        tags5: anecdotesData[0]?.tags5 || '',
        tags6: anecdotesData[0]?.tags6 || '',
        tags7: anecdotesData[0]?.tags7 || '',
        tags8: anecdotesData[0]?.tags8 || '',
        tags9: anecdotesData[0]?.tags9 || '',
        tags10: anecdotesData[0]?.tags10 || '',
        suggestedLinks: anecdotesData[0]?.suggestedLinks || '',
        Annecdotesfeedback: anecdotesData[0]?.Annecdotesfeedback || '',

      });
    } else {
      setEditedValuesanecdotes(initialEditedValuesanecdotes);
    }
  }, [anecdotesData]);

  useEffect(() => {
    if (rankingData && rankingData.length > 0) {
      setEditedValues({
        id: rankingData[0]?.id || '',
        Delivery: rankingData[0]?.Delivery || '',
        ContentQuality: rankingData[0]?.ContentQuality || '',
        EngagementLevel: rankingData[0]?.EngagementLevel || '',
        Duration: rankingData[0]?.Duration || '',
        rankingfeedback: rankingData[0]?.rankingfeedback || '',
        rankdelivery: rankingData[0]?.rankdelivery || 0,
        rankContentQuality: rankingData[0]?.rankContentQuality || 0,
        rankDuration: rankingData[0]?.rankDuration || 0,
        rankEngagementLevel: rankingData[0]?.rankEngagementLevel || 0,
        totalRankaverage: rankingData[0]?.totalRankaverage || 0
      });
    } else {
      setEditedValues(initialEditedValues);
    }
  }, [rankingData]);

  const closePopup = () => {
    setShowPopup(false);
  };
  const closeAnecdotesPopup = () => {
    setShowAnecdotesPopup(false);
    setShowAnecdotesPopupnew(false)

  };

  const closeQuizPopup = () => {
    setShowQuizPopup(false);
  };


  const dispatch = useDispatch();




  const { error, data, message } = useSelector((state) => state.rankingedit);



  const pageCount = data.data
    ? Math.ceil(data.data.length / itemsPerPage)
    : 1;





  const offset = currentPage * itemsPerPage;
  const paginatedData = postData.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const extractVideoId = (link) => {
    const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };


  const saverow = (learningId) => {
    dispatch(fetchrankingdata(learningId, setRankingData, setShowPopup));
  };

  useEffect(() => {
    dispatch(fetchQuizes());
  }, [dispatch]);



  const updateanecdotes = (learningId) => {
    setLearningId(learningId)
    dispatch(fetchanecdotesdata(learningId, setAnecdotesData, setShowAnecdotesPopup, setShowAnecdotesPopupnew, resetAnecdotesValues));
    console.log("++++++++++++++++++QQQQQQQQQQQQQQQQQQQQQQQQQqq");

  };
  const saveVideoRanking = (id, editedValues) => {
    dispatch(updateRankindData(id, editedValues));
    console.log("++++++++++++++++++QQQQQQQQQQQQQQQQQQQQQQQQQqq", id);

  };
  const calculateAverageRating = () => {
    const ratings = [
      parseInt(editedValues.rankdelivery),
      parseInt(editedValues.rankContentQuality),
      parseInt(editedValues.rankEngagementLevel),
      parseInt(editedValues.rankDuration)
    ];

    const totalRating = ratings.reduce((sum, rating) => sum + rating, 0);
    const averageRating = totalRating / ratings.length;

    setEditedValues((prevValues) => ({
      ...prevValues,
      totalRankaverage: averageRating.toString(),
    }));
  };

  useEffect(() => {
    calculateAverageRating();
  }, [
    editedValues.rankdelivery,
    editedValues.rankContentQuality,
    editedValues.rankEngagementLevel,
    editedValues.rankDuration
  ]);

  const saveannecdotes = (id, editedValues) => {
    dispatch(updateanecdotesnew(id, editedValues));
    console.log("++++++++++++++++++QQQQQQQQQQQQQQQQQQQQQQQQQqq");

  };
  const getquizes = (learningId) => {
    dispatch(fetchquizuserwise(learningId, setquizData, setShowQuizPopup));
  };

  const handleAnswerChange = (id, newAnswer) => {
    setquizData((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, answer: newAnswer } : item))
    );
  };
  const handleAnswerNewChange = (id, newAnswer) => {
    setquizData((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, QuestionFeedback: newAnswer } : item))
    );
  };
  const [inputValuesAnedotes, setInputValuesAnedotes] = useState({
    Anedotes1: "",
    Anedotes2: "",
    Anedotes3: "",
    Anedotes4: "",
    Anedotestime1: "",
    Anedotestime2: "",
    Anedotestime3: "",
    Anedotestime4: "",
    tags1: "",
    tags2: "",
    tags3: "",
    tags4: "",
    tags5: "",
    tags6: "",
    tags7: "",
    tags8: "",
    tags9: "",
    tags10: "",
    suggestedLinks: "",
    Annecdotesfeedback: ""
  });

  const handleInputChangeAnedotes = (event) => {
    const { name, value } = event.target;
    setInputValuesAnedotes({
      ...inputValuesAnedotes,
      [name]: value,
    });
  };

  const saveanedotes = (id) => {
    setIsLoading(true);
    const finaldata = {
      Anedotes1: inputValuesAnedotes.Anedotes1,
      Anedotes2: inputValuesAnedotes.Anedotes2,
      Anedotes3: inputValuesAnedotes.Anedotes3,
      Anedotes4: inputValuesAnedotes.Anedotes4,
      Anedotestime1: inputValuesAnedotes.Anedotestime1,
      Anedotestime2: inputValuesAnedotes.Anedotestime2,
      Anedotestime3: inputValuesAnedotes.Anedotestime3,
      Anedotestime4: inputValuesAnedotes.Anedotestime4,
      tags1: inputValuesAnedotes.tags1,
      tags2: inputValuesAnedotes.tags2,
      tags3: inputValuesAnedotes.tags3,
      tags4: inputValuesAnedotes.tags4,
      tags5: inputValuesAnedotes.tags5,
      tags6: inputValuesAnedotes.tags6,
      tags7: inputValuesAnedotes.tags7,
      tags8: inputValuesAnedotes.tags8,
      tags9: inputValuesAnedotes.tags9,
      tags10: inputValuesAnedotes.tags10,
      suggestedLinks: inputValuesAnedotes.suggestedLinks,
      learningId: learningId,
      userId: userId,
      Annecdotesfeedback: inputValuesAnedotes.Annecdotesfeedback
    };

    fetch(`${baseURL}/Admin/post/anedotestags`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(finaldata),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "An error occurred");
          });
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.success) {
          // Swal.fire("Data saved successfully!", "", "success");
          // alert("Data saved successfully!");
          NotificationManager.success('Data saved successfully!', 'Success');

          setInputValuesAnedotes({
            Anedotes1: "",
            Anedotes2: "",
            Anedotes3: "",
            Anedotes4: "",
            Anedotestime1: "",
            Anedotestime2: "",
            Anedotestime3: "",
            Anedotestime4: "",
            tags1: "",
            tags2: "",
            tags3: "",
            tags4: "",
            tags5: "",
            tags6: "",
            tags7: "",
            tags8: "",
            tags9: "",
            tags10: "",
            suggestedLinks: "",
          });
        } else {
          alert("DATA_ALREADY_EXISTS", responseData.data.message);
          NotificationManager.error(responseData.data.message, 'Error');
          // Swal.fire({
          //   icon: "error",
          //   title: "Oops...",
          //   text: responseData.data.message,
          // });
        }
      })
      .catch((error) => {
        // const errorMessage = "Error saving data: " + error.message;

        // alert(errorMessage);
        const errorMessage = 'Error saving data: ' + error.message;
        NotificationManager.error(errorMessage, 'Error');
        // Swal.fire({
        //   icon: "error",
        //   title: "Oops...",
        //   text: "Error saving data: " + error.message,
        // });
      })
      .finally(() => {
        setIsLoading(false);
        closeAnecdotesPopup();

      });
  };

  const renderData = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const currentData = data.slice(startIndex, endIndex);

    return (
      <>
        <div className="containera mt-2">
          <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
            <div className="row align-items-center">
              <div className="col-auto">
                <Link to="/MyReviews">
                  <img src="/Images/left-arrow-new.png" height={50} width={50} />
                </Link>
              </div>
              <div className="col">
                <h3>Edit My Reviews</h3>
              </div>
            </div>
          </div>
          {isLoading && (
            <div className="Loader">
              <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
            </div>
          )}



          <div className="row mt-5">
            <div className="col-md-12">
              <div className="table-container" style={{ overflowY: 'auto' }}>
                <table className="table table-primary">
                  <thead>
                    <tr className="table-primary">
                      <th>Video</th>
                      <th>Ranking</th>
                      <th>Anecdotes</th>
                      <th>Quizzes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((dataItem) => (
                      <tr key={dataItem.id}>
                        <td>
                          <div>
                            <iframe
                              width="560"
                              height="315"
                              src={`https://www.youtube.com/embed/${extractVideoId(
                                `${dataItem.id}-${dataItem.link}`
                              )}?modestbranding=1`}
                              frameBorder="0"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </td>

                        <td>
                          <button class="btn btn-primary" onClick={() => saverow(dataItem.learningId)}>Ranking</button>

                        </td>
                        <td>
                          <button class="btn btn-primary" onClick={() => updateanecdotes(dataItem.learningId)}> Anecdotes</button>

                        </td>
                        <td>
                          <button class="btn btn-primary" onClick={() => getquizes(dataItem.learningId)}> QUIZZES</button>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {showPopup && rankingData && rankingData.length > 0 && rankingData[0] && (
              <div className="popup">
                <div className="popup-content">
                  <button
                    className="btn-close text-white"
                    onClick={closePopup}
                  ></button>
                  <br />
                  <br />

                  <div class="d-flex flex-row mb-3 bg-secondary">
                    <div class="p-2">
                      <div>
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Ranking
                        </h5>
                        <div>
                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Delivery</span>
                            <br />
                            <Rate
                              allowHalf
                              count={5}
                              value={
                                editedValues.rankdelivery !== undefined
                                  ? parseInt(editedValues.rankdelivery)
                                  : 0
                              }
                              onChange={(value) =>
                                setEditedValues({
                                  ...editedValues,
                                  rankdelivery: value.toString(),
                                })
                              }
                              character={<i className="fas fa-star" />}
                              style={{ fontSize: 24, color: "yellow" }}
                            />
                          </div>

                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>
                              Content Quality
                            </span>
                            <br />
                            <Rate
                              allowHalf
                              count={5}
                              value={
                                editedValues.rankContentQuality !== undefined
                                  ? parseInt(editedValues.rankContentQuality)
                                  : 0
                              }
                              onChange={(value) =>
                                setEditedValues({
                                  ...editedValues,
                                  rankContentQuality: value.toString(),
                                })
                              }
                              character={<i className="fas fa-star" />}
                              style={{ fontSize: 24, color: "yellow" }}
                            />
                          </div>

                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>
                              Engagement Level
                            </span>
                            <br />
                            <Rate
                              allowHalf
                              count={5}
                              value={
                                editedValues.rankEngagementLevel !== undefined
                                  ? parseInt(editedValues.rankEngagementLevel)
                                  : 0
                              }
                              onChange={(value) =>
                                setEditedValues({
                                  ...editedValues,
                                  rankEngagementLevel: value.toString(),
                                })
                              }
                              character={<i className="fas fa-star" />}
                              style={{ fontSize: 24, color: "yellow" }}
                            />
                          </div>

                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Duration</span>
                            <br />
                            <Rate
                              allowHalf
                              count={5}
                              value={
                                editedValues.rankDuration !== undefined
                                  ? parseInt(editedValues.rankDuration)
                                  : 0
                              }
                              onChange={(value) =>
                                setEditedValues({
                                  ...editedValues,
                                  rankDuration: value.toString(),
                                })
                              }
                              character={<i className="fas fa-star" />}
                              style={{ fontSize: 24, color: "yellow" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="vl"> </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="p-2 w-50">
                      <div>
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Justification
                        </h5>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Delivery</span>
                          <textarea
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Delivery"
                            value={
                              editedValues.Delivery !== undefined
                                ? editedValues.Delivery
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValues({
                                ...editedValues,
                                Delivery: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Content Quality</span>
                          <textarea
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Content"
                            value={
                              editedValues.ContentQuality !== undefined
                                ? editedValues.ContentQuality
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValues({
                                ...editedValues,
                                ContentQuality: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Engagement Level</span>
                          <textarea
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="level"
                            value={
                              editedValues.EngagementLevel !== undefined
                                ? editedValues.EngagementLevel
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValues({
                                ...editedValues,
                                EngagementLevel: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Duration</span>
                          <textarea
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Duration"
                            value={
                              editedValues.Duration !== undefined
                                ? editedValues.Duration
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValues({
                                ...editedValues,
                                Duration: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="average-ranking bg-primary text-white">
                    <h6>Average Ranking: </h6>
                    <Rate
                      allowHalf
                      count={5}
                      value={
                        editedValues.totalRankaverage !== undefined
                          ? parseFloat(editedValues.totalRankaverage)
                          : 0
                      }
                      character={<i className="fas fa-star" />}
                      style={{ fontSize: 24, color: "yellow" }}
                    />
                  </div>
                  <center>
                    <br />
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Ranking Feedback</span>
                      <textarea
                        type="text"
                        id="form1Example1"
                        class="form-control bg-secondary"
                        name="rankingfeedback"
                        value={editedValues.rankingfeedback}
                        onChange={(e) =>
                          setEditedValues({
                            ...editedValues,
                            rankingfeedback: e.target.value,
                          })
                        }
                        required
                        placeholder="Video Ranking Feedback (Optional)"
                        rows="2"
                      />
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => saveVideoRanking(editedValues.id, editedValues)}
                    >
                      Save
                    </button>
                  </center>
                </div>
              </div>
            )}


            {showAnecdotesPopup && anecdotesData && anecdotesData.length > 0 && anecdotesData[0] && (
              <div className="popup">
                <div className="popup-content">
                  <button
                    className="btn-close text-white"
                    onClick={closeAnecdotesPopup}
                  ></button>
                  <br />
                  <br />
                  <div class="d-flex flex-row mb-3 bg-secondary">
                    <div class="p-2 w-50">
                      <div>
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Add Anecdotes
                        </h5>
                        <form>
                          <div>
                            <div>
                              <div class="form-outline mb-4">
                                <span style={{ fontSize: "15px" }}>
                                  Anecdotes 1
                                </span>
                                <input
                                  type="text"
                                  id="form1Example1"
                                  class="form-control bg-light"
                                  name="Anedotes1"
                                  value={
                                    editedValuesanecdotes.Anedotes1 !== undefined
                                      ? editedValuesanecdotes.Anedotes1
                                      : "NULL"
                                  }
                                  onChange={(e) =>
                                    setEditedValuesanecdotes({
                                      ...editedValuesanecdotes,
                                      Anedotes1: e.target.value,
                                    })
                                  }
                                  required
                                  rows="2"
                                />
                              </div>
                              <div class="form-outline mb-4">
                                <span style={{ fontSize: "15px" }}>
                                  Anecdotes 2
                                </span>
                                <input
                                  type="text"
                                  id="form1Example1"
                                  class="form-control bg-light"
                                  name="Anedotes2"
                                  value={
                                    editedValuesanecdotes.Anedotes2 !== undefined
                                      ? editedValuesanecdotes.Anedotes2
                                      : "NULL"
                                  }
                                  onChange={(e) =>
                                    setEditedValuesanecdotes({
                                      ...editedValuesanecdotes,
                                      Anedotes2: e.target.value,
                                    })
                                  }
                                  required
                                  rows="2"
                                />
                              </div>
                              <div class="form-outline mb-4">
                                <span style={{ fontSize: "15px" }}>
                                  Anecdotes 3
                                </span>
                                <input
                                  type="text"
                                  id="form1Example1"
                                  class="form-control bg-light"
                                  name="Anedotes3"
                                  value={
                                    editedValuesanecdotes.Anedotes3 !== undefined
                                      ? editedValuesanecdotes.Anedotes3
                                      : "NULL"
                                  }
                                  onChange={(e) =>
                                    setEditedValuesanecdotes({
                                      ...editedValuesanecdotes,
                                      Anedotes3: e.target.value,
                                    })
                                  }
                                  required
                                  rows="2"
                                />
                              </div>
                              <div class="form-outline mb-4">
                                <span style={{ fontSize: "15px" }}>
                                  Anecdotes 4
                                </span>
                                <input
                                  type="text"
                                  id="form1Example1"
                                  class="form-control bg-light"
                                  name="Anedotes4"
                                  value={
                                    editedValuesanecdotes.Anedotes4 !== undefined
                                      ? editedValuesanecdotes.Anedotes4
                                      : "NULL"
                                  }
                                  onChange={(e) =>
                                    setEditedValuesanecdotes({
                                      ...editedValuesanecdotes,
                                      Anedotes4: e.target.value,
                                    })
                                  }
                                  required
                                  rows="2"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="vl"> </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="p-2 w-50">
                      <div>
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Add TimeStamp
                        </h5>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Anecdotes Time 1</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotestime1"
                            value={
                              editedValuesanecdotes.Anedotestime1 !== undefined
                                ? editedValuesanecdotes.Anedotestime1
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                Anedotestime1: e.target.value,
                              })
                            }
                            placeholder="MM:SS"
                            required
                            rows="2"
                            maxLength={5}
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Anecdotes Time 2</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotestime2"
                            value={
                              editedValuesanecdotes.Anedotestime2 !== undefined
                                ? editedValuesanecdotes.Anedotestime2
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                Anedotestime2: e.target.value,
                              })
                            }
                            placeholder="MM:SS"
                            required
                            rows="2"
                            maxLength={5}
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Anedotes Time 3</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotestime3"
                            value={
                              editedValuesanecdotes.Anedotestime3 !== undefined
                                ? editedValuesanecdotes.Anedotestime3
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                Anedotestime3: e.target.value,
                              })
                            }
                            placeholder="MM:SS"
                            required
                            rows="2"
                            maxLength={5}
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Anedotes Time 4</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotestime4"
                            value={
                              editedValuesanecdotes.Anedotestime4 !== undefined
                                ? editedValuesanecdotes.Anedotestime4
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                Anedotestime4: e.target.value,
                              })
                            }
                            placeholder="MM:SS"
                            required
                            rows="2"
                            maxLength={5}
                          />
                        </div>
                      </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="vl"> </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="p-2 w-50">
                      <div>
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Add Tags
                        </h5>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 1</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags1"
                            value={
                              editedValuesanecdotes.tags1 !== undefined
                                ? editedValuesanecdotes.tags1
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                tags1: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 2</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags2"
                            value={
                              editedValuesanecdotes.tags2 !== undefined
                                ? editedValuesanecdotes.tags2
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                tags2: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 3</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags3"
                            value={
                              editedValuesanecdotes.tags3 !== undefined
                                ? editedValuesanecdotes.tags3
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                tags3: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 4</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags4"
                            value={
                              editedValuesanecdotes.tags4 !== undefined
                                ? editedValuesanecdotes.tags4
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                tags4: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 5</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags5"
                            value={
                              editedValuesanecdotes.tags5 !== undefined
                                ? editedValuesanecdotes.tags5
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                tags5: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                      </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="vl"> </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="p-2 w-50">
                      <div>
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Add Tags
                        </h5>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 6</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags6"
                            value={
                              editedValuesanecdotes.tags6 !== undefined
                                ? editedValuesanecdotes.tags6
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                tags6: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 7</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags7"
                            value={
                              editedValuesanecdotes.tags7 !== undefined
                                ? editedValuesanecdotes.tags7
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                tags7: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 8</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags8"
                            value={
                              editedValuesanecdotes.tags8 !== undefined
                                ? editedValuesanecdotes.tags8
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                tags8: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 9</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags9"
                            value={
                              editedValuesanecdotes.tags9 !== undefined
                                ? editedValuesanecdotes.tags9
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                tags9: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 10</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags10"
                            value={
                              editedValuesanecdotes.tags10 !== undefined
                                ? editedValuesanecdotes.tags10
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                tags10: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <center>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>suggestedLinks</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-secondary"
                        name="suggestedLinks"
                        value={
                          editedValuesanecdotes.suggestedLinks !== undefined
                            ? editedValuesanecdotes.suggestedLinks
                            : "NULL"
                        }
                        onChange={(e) =>
                          setEditedValuesanecdotes({
                            ...editedValuesanecdotes,
                            suggestedLinks: e.target.value,
                          })
                        }
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Anecdotes Feedback</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-secondary"
                        name="Annecdotesfeedback"
                        value={
                          editedValuesanecdotes.Annecdotesfeedback !== undefined
                            ? editedValuesanecdotes.Annecdotesfeedback
                            : "NULL"
                        }
                        onChange={(e) =>
                          setEditedValuesanecdotes({
                            ...editedValuesanecdotes,
                            Annecdotesfeedback: e.target.value,
                          })
                        }
                        required
                        rows="2"
                      />
                    </div>
                  </center>
                  <center>
                    <button
                      className="btn btn-primary"
                      onClick={() => saveannecdotes(editedValuesanecdotes.AnecdotesId, editedValuesanecdotes)}

                    >
                      Save
                    </button>
                  </center>
                </div>
              </div>

            )}


            {showQuizPopup && quizData && quizData.length > 0 && quizData[0] && (
              <div className="popup">
                <div className="popup-content">
                  <button className="btn-close text-white" onClick={closeQuizPopup}></button>
                  <table className="table table-bordered mt-5">
                    <thead>
                      <tr class="table-primary">
                        <th style={{ display: "none" }}>Quiz ID</th>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Feedback</th>
                        <th>Answer</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quizData.map((dataItem) => (
                        <tr key={dataItem.id}>
                          <td style={{ display: "none" }}>{dataItem.quizId}</td>
                          <td>{dataItem.quizName}</td>
                          <td>
                            {dataItem.options ? (
                              <ul className="list-group">
                                {Object.entries(dataItem.options).map(
                                  ([key, value]) => (
                                    <li
                                      className="list-group-item responsive-text-options"
                                      key={key}
                                    >
                                      <strong>{key}:</strong> {value}
                                    </li>
                                  )
                                )}
                              </ul>
                            ) : (
                              <div
                                className="alert alert-warning"
                                role="alert"
                              >
                                No options available
                              </div>
                            )}
                          </td>
                          {/* <td>{dataItem.QuestionFeedback}</td> */}
                          <td>
                            {/* {editState[dataItem.id] ? (
                        <textarea
                          className="form-control"
                          value={neweditedFeedback[dataItem.id] || dataItem.QuestionFeedback}
                          onChange={(e) => handleFeedbackChange(dataItem.id, e.target.value)}
                        />
                      ) : (
                        neweditedFeedback[dataItem.id] || dataItem.QuestionFeedback
                        )} */}
                            {editState[dataItem.id] ? (
                              <div>
                                <input
                                  type="text"
                                  className="form-input"
                                  value={dataItem.QuestionFeedback}
                                  onChange={(e) => handleAnswerNewChange(dataItem.id, e.target.value)}
                                />
                              </div>
                            ) : (
                              dataItem.QuestionFeedback
                            )}


                          </td>
                          <td>
                            {editState[dataItem.id] ? (
                              <div>
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    value="Correct"
                                    checked={dataItem.answer === "Correct"}
                                    onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
                                  />
                                  Correct
                                </label>
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    value="In-Correct"
                                    checked={dataItem.answer === "In-Correct"}
                                    onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
                                  />
                                  In-Correct
                                </label>
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    value="Not Sure"
                                    checked={dataItem.answer === "Not Sure"}
                                    onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
                                  />
                                  Not-Sure
                                </label>
                              </div>
                            ) : (
                              dataItem.answer
                            )}
                          </td>

                          <td>
                            {editState[dataItem.id] ? (
                              <button
                                // className="btn btn-secondary"
                                onClick={() => handleSave(dataItem.id, dataItem.answer, dataItem.QuestionFeedback)}
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                // className="btn btn-secondary"
                                // disabled={parseFloat(dataItem.rankingscore) > 0}
                                onClick={() => handleEdit(dataItem.id)}
                              >
                                Edit
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}


            {showAnecdotesPopupnew && (

              <div className="popup">
                <div className="popup-content">
                  <button
                    className="btn-close text-white"
                    onClick={closeAnecdotesPopup}
                  ></button>
                  {/* <h6>Quiz name: {selectedvideo}</h6> */}

                  <br />
                  <br />
                  <div class="d-flex flex-row mb-3 bg-secondary">
                    <div class="p-2 w-50">
                      <div>
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Add Anecdotes
                        </h5>
                        <form>
                          <div>
                            <div>
                              <div class="form-outline mb-4">
                                <span style={{ fontSize: "15px" }}>
                                  Anecdote 1
                                </span>
                                <input
                                  type="text"
                                  id="form1Example1"
                                  class="form-control bg-light"
                                  name="Anedotes1"
                                  value={inputValuesAnedotes.Anedotes1}
                                  onChange={handleInputChangeAnedotes}
                                  required
                                  rows="2"
                                />
                              </div>
                              <div class="form-outline mb-4">
                                <span style={{ fontSize: "15px" }}>
                                  Anecdote 2
                                </span>
                                <input
                                  type="text"
                                  id="form1Example1"
                                  class="form-control bg-light"
                                  name="Anedotes2"
                                  value={inputValuesAnedotes.Anedotes2}
                                  onChange={handleInputChangeAnedotes}
                                  required
                                  rows="2"
                                />
                              </div>
                              <div class="form-outline mb-4">
                                <span style={{ fontSize: "15px" }}>
                                  Anecdote 3
                                </span>
                                <input
                                  type="text"
                                  id="form1Example1"
                                  class="form-control bg-light"
                                  name="Anedotes3"
                                  value={inputValuesAnedotes.Anedotes3}
                                  onChange={handleInputChangeAnedotes}
                                  required
                                  rows="2"
                                />
                              </div>
                              <div class="form-outline mb-4">
                                <span style={{ fontSize: "15px" }}>
                                  Anecdote 4
                                </span>
                                <input
                                  type="text"
                                  id="form1Example1"
                                  class="form-control bg-light"
                                  name="Anedotes4"
                                  value={inputValuesAnedotes.Anedotes4}
                                  onChange={handleInputChangeAnedotes}
                                  required
                                  rows="2"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="vl"> </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="p-2 w-50">
                      <div>
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Add TimeStamp
                        </h5>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Timestamp 1</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotestime1"
                            value={inputValuesAnedotes.Anedotestime1}
                            onChange={handleInputChangeAnedotes}
                            onKeyPress={(e) => {
                              const isValidChar = /^[0-9:]+$/.test(e.key);
                              if (!isValidChar) {
                                e.preventDefault();
                              }
                            }}
                            placeholder="MM:SS"
                            required
                            rows="2"
                            maxLength={5}
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Timestamp 2</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotestime2"
                            value={inputValuesAnedotes.Anedotestime2}
                            onChange={handleInputChangeAnedotes}
                            onKeyPress={(e) => {
                              const isValidChar = /^[0-9:]+$/.test(e.key);
                              if (!isValidChar) {
                                e.preventDefault();
                              }
                            }}
                            placeholder="MM:SS"
                            required
                            rows="2"
                            maxLength={5}
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Timestamp 3</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotestime3"
                            value={inputValuesAnedotes.Anedotestime3}
                            onChange={handleInputChangeAnedotes}
                            onKeyPress={(e) => {
                              const isValidChar = /^[0-9:]+$/.test(e.key);
                              if (!isValidChar) {
                                e.preventDefault();
                              }
                            }}
                            placeholder="MM:SS"
                            required
                            rows="2"
                            maxLength={5}
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Timestamp 4</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotestime4"
                            value={inputValuesAnedotes.Anedotestime4}
                            onChange={handleInputChangeAnedotes}
                            onKeyPress={(e) => {
                              const isValidChar = /^[0-9:]+$/.test(e.key);
                              if (!isValidChar) {
                                e.preventDefault();
                              }
                            }}
                            placeholder="MM:SS"
                            required
                            rows="2"
                            maxLength={5}
                          />
                        </div>
                      </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="vl"> </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="p-2 w-50">
                      <div>
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Add Tags
                        </h5>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 1</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags1"
                            value={inputValuesAnedotes.tags1}
                            onChange={handleInputChangeAnedotes}
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 2</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags2"
                            value={inputValuesAnedotes.tags2}
                            onChange={handleInputChangeAnedotes}
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 3</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags3"
                            value={inputValuesAnedotes.tags3}
                            onChange={handleInputChangeAnedotes}
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 4</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags4"
                            value={inputValuesAnedotes.tags4}
                            onChange={handleInputChangeAnedotes}
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 5</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags5"
                            value={inputValuesAnedotes.tags5}
                            onChange={handleInputChangeAnedotes}
                            required
                            rows="2"
                          />
                        </div>
                      </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="vl"> </div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="p-2 w-50">
                      <div>
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Add Tags
                        </h5>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 6</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags6"
                            value={inputValuesAnedotes.tags6}
                            onChange={handleInputChangeAnedotes}
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 7</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags7"
                            value={inputValuesAnedotes.tags7}
                            onChange={handleInputChangeAnedotes}
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 8</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags8"
                            value={inputValuesAnedotes.tags8}
                            onChange={handleInputChangeAnedotes}
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 9</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags9"
                            value={inputValuesAnedotes.tags9}
                            onChange={handleInputChangeAnedotes}
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Tag 10</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="tags10"
                            value={inputValuesAnedotes.tags10}
                            onChange={handleInputChangeAnedotes}
                            required
                            rows="2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <center>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>suggestedLinks</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-secondary"
                        name="suggestedLinks"
                        value={inputValuesAnedotes.suggestedLinks}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Anecdotes Feedback (Optional)</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-secondary"
                        name="Annecdotesfeedback"
                        value={inputValuesAnedotes.Annecdotesfeedback}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                  </center>
                  <center>
                    <button
                      className="btn btn-primary"
                      onClick={() => saveanedotes()}
                    >
                      Save
                    </button>
                  </center>
                </div>
              </div>
            )}


          </div>
          <div className="pagination-container">
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageCount={Math.ceil(data.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {data.loading && <p>Loading...</p>}
      {data.error && <p>Error: {data.error}</p>}
      {data ? renderData() : null}
    </div>
  );

  // return (
  // <div className="container mt-5">
  // <Link to={`/MyReviews`}>
  //   <img src="/Images/left-arrow.png" height={50} width={50} />
  // </Link>
  // <br /><br />
  // <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
  //   <h3>Edit My Reviews</h3>
  // </div>
  // {/* <Link to="/AnedotesTagsEdit">
  //   <button>Edit Anecdotes & Tags</button>
  // </Link> &nbsp;&nbsp;&nbsp;
  // <Link to="/ViewQuestion">
  //   <button>View Quiz</button>
  // </Link> */}
  // {isLoading && (
  //   <div className="Loader">
  //     <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
  //   </div>
  // )}
  //     <div className="row mt-5">
  //       <div className="col-md-12">
  //         <div className="table-container" style={{ overflowY: 'auto' }}>
  //           <table className="table table-dark">
  //             <thead>
  //               <tr className="table-primary">
  //                 <th>Video</th>
  //                 <th>Ranking</th>
  //                 <th>Anecdotes</th>
  //                 <th>Quizzes</th>


  //               </tr>
  //             </thead>
  //             <tbody>
  //               {paginatedData.map((dataItem) => (
  //                 <tr key={dataItem.learningId}>
  //                   <td style={{ display: "none" }}>{dataItem.learningId}</td>
  //                   <td>
  //                     <div>
  //                       <iframe
  //                         width="560"
  //                         height="315"
  //                         src={`https://www.youtube.com/embed/${extractVideoId(
  //                           (`${dataItem.id}-${dataItem.link}`)
  //                         )}?modestbranding=1`}
  //                         frameBorder="0"
  //                         allowFullScreen
  //                       ></iframe>
  //                     </div>
  //                   </td>

  //                   <td>
  //                     <button class="btn btn-primary" onClick={() => saverow(dataItem.learningId)}>Ranking</button>

  //                   </td>
  //                   <td>
  //                     <button class="btn btn-primary" onClick={() => updateanecdotes(dataItem.learningId)}> Anecdotes</button>

  //                   </td>
  //                   <td>
  //                     <button class="btn btn-primary" onClick={() => getquizes(dataItem.learningId)}> QUIZZES</button>

  //                   </td>
  //                 </tr>
  //               ))}
  //             </tbody>
  //           </table>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="pagination-container">
  //       <ReactPaginate
  //         previousLabel="Previous"
  //         nextLabel="Next"
  //         pageCount={Math.ceil(postData.length / itemsPerPage)}
  //         marginPagesDisplayed={2}
  //         pageRangeDisplayed={5}
  //         onPageChange={handlePageClick}
  //         containerClassName="pagination"
  //         subContainerClassName="pages pagination"
  //         activeClassName="active"
  //       />
  //     </div>
  //   </div>
  // );
}

export default ViewQuestion;