const getUserIdFromToken = () => {
    const accessTokenforuserid = localStorage.getItem("user");
  
    if (!accessTokenforuserid) {
      return null;
    }

    try {
      const accessTokenforuseridtwo = accessTokenforuserid.replace(/"/g, "");
      const [, payload] = accessTokenforuseridtwo.split('.');
      const decodedPayload = JSON.parse(atob(payload));
      return decodedPayload.id;
    } catch (error) {
      console.error('Error decoding JWT payload:', error);
      return null;
    }
  };
  

  export default getUserIdFromToken;
  



  