import {
    FETCH_QUIZES_REQUEST,
    FETCH_QUIZES_SUCCESS,
    FETCH_QUIZES_FAILURE,
    UPDATE_QUIZ_ANSWER_SUCCESS
  } from '../Action/quizActions.action';
  
  const initialState = {
    data: [],
    isLoading: false,
    error: null,
  };
  
  const quizReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_QUIZES_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case FETCH_QUIZES_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case FETCH_QUIZES_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
        case UPDATE_QUIZ_ANSWER_SUCCESS:
            const updatedData = state.data.map((quiz) => {
              if (quiz.id === action.payload.id) {
                return action.payload;
              }
              return quiz;
            });
      
            return {
              ...state,
              data: updatedData,
            };
          default:
            return state;
    }
  };
  
//   const quizReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case FETCH_QUIZ_REQUEST:
//         return {
//           ...state,
//           isLoading: true,
//           error: null,
//         };
//       case FETCH_QUIZ_SUCCESS:
//         return {
//           ...state,
//           isLoading: false,
//           data: action.payload,
//         };
//       case FETCH_QUIZ_FAILURE:
//         return {
//           ...state,
//           isLoading: false,
//           error: action.payload,
//         };
//       case UPDATE_QUIZ_ANSWER_SUCCESS:
//         const updatedData = state.data.map((quiz) => {
//           if (quiz.id === action.payload.id) {
//             return action.payload;
//           }
//           return quiz;
//         });
  
//         return {
//           ...state,
//           data: updatedData,
//         };
//       default:
//         return state;
//     }
//   };

  
  export default quizReducer;
  