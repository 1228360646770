import getUserIdFromToken from "../../Utils/JWT/jwt";
import getToken from "../../Utils/JWT/token";
import Swal from "sweetalert2";
import {baseURL} from "../../Config/config"

export const POST_RAISECALL_REQUEST = "POST_RAISECALL_REQUEST";
export const POST_RAISECALL_SUCCESS = "POST_RAISECALL_SUCCESS";
export const POST_RAISECALL_FAILURE = "POST_RAISECALL_FAILURE";

const userId = getUserIdFromToken();
const token = getToken();

export const postraisecallreuqestRequest = () => ({
  type: POST_RAISECALL_REQUEST,
});

export const postraisecallreuqestSuccess = (data) => ({
  type: POST_RAISECALL_SUCCESS,
  payload: data,
});

export const postraisecallreuqestFailure = (error) => ({
  type: POST_RAISECALL_FAILURE,
  payload: error,
});

export const postraisecallreq = (formData) => {
  return (dispatch) => {
    dispatch(postraisecallreuqestRequest());

    formData.userId = userId;
    const accessTokenWithQuotes = localStorage.getItem("user");


    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
    fetch(`${baseURL}/raisecallrequest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire("Request Send!", "", "success");
          dispatch(
            postraisecallreuqestSuccess({ message: "Successfully posted" })
          );
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .catch((error) => {
        Swal.fire("Opps!", error.message, "denger");
        dispatch(postraisecallreuqestFailure(error.message));
      });
  };
};
