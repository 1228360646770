import getUserIdFromToken from "../../Utils/JWT/jwt";
import getToken from "../../Utils/JWT/token";
import Swal from "sweetalert2";
import {baseURL} from "../../Config/config"

export const POST_RAISEREQUEST_REQUEST = "POST_RAISEREQUEST_REQUEST";
export const POST_RAISEREQUEST_SUCCESS = "POST_RAISEREQUEST_SUCCESS";
export const POST_RAISEREQUEST_FAILURE = "POST_RAISEREQUEST_FAILURE";

const userId = getUserIdFromToken();
const token = getToken();

export const postraisereuqestRequest = () => ({
  type: POST_RAISEREQUEST_REQUEST,
});

export const postraisereuqestSuccess = (data) => ({
  type: POST_RAISEREQUEST_SUCCESS,
  payload: data,
});

export const postraisereuqestFailure = (error) => ({
  type: POST_RAISEREQUEST_FAILURE,
  payload: error,
});

export const postraisereq = (formData, setLoading) => {
  return (dispatch) => {
    dispatch(postraisereuqestRequest());

    formData.userId = userId;

    setLoading(true);
    const accessTokenWithQuotes = localStorage.getItem("user");


    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

    console.log(">>>>>>>>>>>>>>>>>>>",accessTokenWithoutQuotes);
    fetch(`${baseURL}/raisecallrequest/call`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire("Request Send!", "", "success");

          dispatch(postraisereuqestSuccess({ message: "Successfully posted" }));
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .catch((error) => {
        Swal.fire("Opps!", error.message, "denger");

        dispatch(postraisereuqestFailure(error.message));
      })
      .finally(() => {
        setLoading(false);
      });
  };
};
