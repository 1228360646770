import React, { useState, useEffect } from "react";
import {baseURL} from "../../Config/config"

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailFromUrl = urlParams.get("email");
    const tokenFromUrl = urlParams.get("token");

    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, []);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const isLengthValid = newPassword.length >= 8;

    setPasswordValid(
      hasUpperCase && hasLowerCase && hasNumber && isLengthValid
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setPasswordMatch(false);
      return;
    }

    if (!passwordValid) {
      return;
    }

    const accessTokenWithQuotes = token;
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

    const [header, payload] = accessTokenWithoutQuotes.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const id = decodedPayload.id;

    const order = {
      id,
      password,
      email,
      token,
    };

    try {
      const response = await fetch(
        `${baseURL}/Admin/reset-password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(order),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setPasswordResetSuccess(true);
        setTimeout(() => {
            window.location.href = "/Signin";
          }, 3000); 

      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
<div className="container-fluid">
  <div className="row justify-content-center align-items-center vh-100">
    <div className="col-md-6">
      <div className="card">
        <div className="card-header bg-primary text-white text-center">
          <h3 className="mb-0">Set Password</h3>
        </div>
            <center>
              <img
                src="Images/logo.png"
                height={40}
                className="mt-5"
                alt="logo"
              />
            </center>
            <hr />

            {passwordResetSuccess ? (
              <div className="alert alert-success">
                Password Set successful! You can now log in with your new
                password.
              </div>
            ) : (
              <form className="card-body" onSubmit={handleSubmit}>
                <div className="form-group" style={{ display: "none" }}>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group" style={{ display: "none" }}>
                  <label htmlFor="token">Token:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="token"
                    name="token"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">New Password:</label>
                  <input
                    type="password"
                    className={`form-control ${
                      passwordMatch ? "" : "is-invalid"
                    }`}
                    id="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                  />
                  {!passwordValid && (
                    <p className="text-danger">
                      Password must contain at least 8 characters, including at
                      least one uppercase letter, one lowercase letter, and one
                      number.
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password:</label>
                  <input
                    type="password"
                    className={`form-control ${
                      passwordMatch ? "" : "is-invalid"
                    }`}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  {!passwordMatch && (
                    <p className="text-danger">Passwords do not match.</p>
                  )}
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <style>
        {
            `
            @media only screen and (min-width: 768px) {
                .container-fluid{
                    width:50%;
                }
              }
           body{
            background-color: #e0e0e0; /* Change to your desired background color */

           }
            .container-fluid.w-50 {
                width: 100%;
                background-color: #e0e0e0; /* Change to your desired background color */
              }
            
              @media only screen and (max-width: 600px) {
                .container-fluid.w-50 {
                  background-color: #e0e0e0; /* Change to your desired background color for mobile */
                }
              }
            `
        }
      </style>
    </div>
  );
}

export default ResetPassword;
