import "./App.css";
import Login from "./Auth/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import PrivateComponent from "./Auth/PrivateComponent";
import SharedLayout from "./Common/SharedLayout";
import RegisterRole from "./Components/AdminView/RegisterRole/RegisterRole";
import DataTableEdit from "./Components/Quizs/DataTableEdit";
import DataTableQuiz from "./Components/ReviewBoard/DataTableQuiz";
import AllUsers from "./Components/AdminView/MastersFGB/ViewAllUserCount";
import MyProfile from "./Components/Profile/MyProfile"
import Dashboard from "./Components/Dashboard/Dashboard";
import Restpassword from "./Components/Settings/Restpassword";
import LeadBoard from "./Components/LeadBoard/LeadBoard";
import NoticeBoard from "./Components/Alerts/NoticeBoard";
import Referal from "./Components/Referal/Referal";
import Support from "./Components/Support/Support";
import AnedotesTagsEdit from "./Components/Anecdotes/AnedotesTagsEdit";
import Raiserequest from "./Components/Support/Riserequest";
import Raisecallequest from "./Components/Support/Risecallrequest";
import Viewalluserscount from "./Components/AdminView/MastersFGB/ViewAllUserCount";
import Fgbstatus from "./Components/AdminView/MastersFGB/FgbStatus"
import MyReviews from "./Components/MyReview/MyReviews";
import TotalVideo from "./Components/AdminView/MastersFGB/TotalVideo";
import Adminview from "./Components/AdminView/MastersFGB/Adminview";
import ViewQuestion from "./Components/Quizs/ViewQuestion";
import AcceptAnedotesTags from "./Components/AdminView/MastersFGB/AcceptAnedotesTags";
import AcceptReview from "./Components/AdminView/MastersFGB/AcceptReview";
import Updatepassword from "./Components/Settings/Updatepassword";
import SetForgetpassword from "./Components/Settings/SetForgetpassword";
import SignIn from "./Auth/SignIn";
import { Provider } from 'react-redux';
import store from './Redux/Store/store';
// import UserComponent from "./Components/UserComponent";
import SignalAppSettings from "./Components/AdminView/SignalAPP/SignalAppSettings";
import SignalAppMain from "./Components/AdminView/SignalAPP/SignalAppMain";
import Datamaster from "./Components/AdminView/MastersFGB/Datamaster";
import DataTransferProd from "./Components/AdminView/MastersFGB/DataTransferProd";
import DataCount from "./Components/AdminView/MastersFGB/DataCout";
import DataMasterData from "./Components/AdminView/MastersFGB/DataMasterData";
import SimpleLeadboard from "./Components/LeadBoard/SampleLeadboardMonthwise";
import EVALUATOR from "./Components/AdminView/MastersFGB/EVALUATOR";
import Fgbdetails from "./Components/AdminView/MastersFGB/Fgbdetails"


const App = () => {
  return (
    <div>
          <Provider store={store}>

      <Router>
        <Routes>

          <Route path="/signin" element={<SignIn />} />
          
          <Route path="/reset-password" element={<Restpassword />} />
          <Route path="/SetForgetpassword" element={<SetForgetpassword />} />

          <Route path="/" element={<PrivateComponent />}>
            <Route element={<SharedLayout />}>
            <Route path="/AllUsers" element={<AllUsers/>} />
            <Route path="/DataTableQuiz" element={<DataTableQuiz />} />
            <Route path="/DataTableEdit" element={<DataTableEdit />} />
            <Route path="/RegisterRole" element={<RegisterRole />} />
            <Route path="/Profile" element={<MyProfile />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/LeadBoard" element={<LeadBoard/>}/>
            <Route path="/NoticeBoard" element={<NoticeBoard/>}/>
            <Route path="/Referal" element={<Referal/>}/>
            {/* <Route path="/Score" element={<Score/>}/> */}
            <Route path="/Support" element={<Support/>}/>
            <Route path="/AnedotesTagsEdit" element={<AnedotesTagsEdit/>}/>
            <Route path="/Raiserequest" element={<Raiserequest/>}/>
            <Route path="/Raisecallequest" element={<Raisecallequest/>}/>
            <Route path="/Viewalluserscount" element={<Viewalluserscount/>}/>
            <Route path="/Fgbstatus/:userId" element={<Fgbstatus/>}/>
            <Route path="/MyReviews" element={<MyReviews/>}/>
            <Route path="/TotalVideo" element={<TotalVideo/>}/>
            <Route path="/Adminview" element={<Adminview/>}/>
            <Route path="/ViewQuestion" element={<ViewQuestion/>}/>
            <Route path="/AcceptAnedotesTags/:userId" element={<AcceptAnedotesTags/>}/>
            <Route path="/AcceptReview/:userId" element={<AcceptReview/>}/>
            <Route path="/Updatepassword" element={<Updatepassword/>}/>
            <Route path="/AppSettings" element={<SignalAppSettings/>}/>
            <Route path="/SignalAppMain" element={<SignalAppMain/>}/>
            <Route path="/Datamaster" element={<Datamaster/>}/>
            <Route path="/DataTransferProd" element={<DataTransferProd/>}/>
            <Route path="/DataCount" element={<DataCount/>}/>
            <Route path="/DataMasterData" element={<DataMasterData/>}/>
            <Route path="/SimpleLeadboard" element={<SimpleLeadboard/>}/>
            <Route path="/Evaluator" element={<EVALUATOR/>}/>
            <Route path="/Fgbdetails" element={<Fgbdetails/>}/>

            </Route>
          </Route>
        </Routes>
      </Router>
      </Provider>
    </div>
  );
};

export default App;
