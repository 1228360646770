
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { fetchcompletedvideos } from "../../../Redux/Action/CompletedVideo.action";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import { baseURL } from '../../../Config/config';
import { Rate } from "antd";

function CardDetailsPage({ userId }) {
  const dispatch = useDispatch();
  const totalCompletedvideodata = useSelector((state) => state.completedvideo);
  const completeduserid = userId;

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPopupRanking, setShowPopupRanking] = useState(false);
  const [showLearningId, setLearningId] = useState(false);
  const [rankingData, setRankingData] = useState(null);

  const [showAnecdotesPopup, setShowAnecdotesPopup] = useState(false);
  const [anecdotesData, setAnecdotesData] = useState(null);
  const [quizData, setquizData] = useState(null);
  const [showQuizPopup, setShowQuizPopup] = useState(false);

  const closeRankingPopup = () => {
    setShowPopupRanking(false);
  };

  const closeAnecdotesPopup = () => {
    setShowAnecdotesPopup(false);
  };

  const itemsPerPage = 5;


  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const pageCount = totalCompletedvideodata.data
    ? Math.ceil(totalCompletedvideodata.data.length / itemsPerPage)
    : 1;

  const extractVideoId = (link) => {
    const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  const openranking = (learningId) => {
    setLearningId(learningId)
    console.log("::::::::::::::::::::", learningId);

    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

    axios
      .get(`${baseURL}/Admin/getranking/Score/Completed/learningID/${learningId}/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          console.log(">>>>>>>>>>>>>>>", data)
          // setPostData(data);
          setRankingData(data);
          setShowPopupRanking(true);

        } else {
          console.error('Data received is not an array:', responseData);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });

  }

  const openanecdotes = (learningId) => {
    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

    axios
      .get(`${baseURL}/Admin/get/Annedotes/learningID/${learningId}/${userId}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<Id", data[0].AnecdotesId);
          setAnecdotesData(data);

        } else {
          console.error("Data received is not an array:", responseData);
        }
        setShowAnecdotesPopup(true);

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // useEffect(() => {
  //   dispatch(fetchcompletedvideos(completeduserid));
  // }, [dispatch]);


  const getquizes = (learningId) => {
    console.log(">>>>>>>>>>>>>>>>><<<<<<<<", learningId);
    const accessTokenWithQuotes = localStorage.getItem('user');
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');
    const [header, payload] = accessTokenWithoutQuotes.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;
    setIsLoading(true);

    axios
      .get(`${baseURL}/Admin/getquiz/Completed/${userId}/${learningId}`, {
        headers: {
          Accept: "application/json"
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<", data);
          setquizData(data);
          setShowQuizPopup(true);

        } else {
          console.error("Data received is not an array:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const closeQuizPopup = () => {
    setShowQuizPopup(false);
  };


  const [selectedMonth, setSelectedMonth] = useState("02");
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  useEffect(() => {
    dispatch(fetchcompletedvideos(completeduserid, selectedMonth));
  }, [dispatch, selectedMonth]);

  const renderData = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;



    const currentData = totalCompletedvideodata.data.slice(startIndex, endIndex);



    return (
      <div>
        <div className="table-responsive table-container">
          <div className="d-flex ms-5">
            {/* 
            <div className="mb-2 me-2">
              <label htmlFor="monthSelector" className="form-label">Select Month:</label>
              <select
                id="monthSelector"
                onChange={handleMonthChange}
                value={selectedMonth}
                className="form-select"
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="d-flex ">
              <label htmlFor="monthSelector" className="mt-2 me-3 alert alert-primary p-2">Select Month:</label>
              <div className="mb-2 me-2 d-flex align-items-center">
                <select
                  id="monthSelector"
                  onChange={handleMonthChange}
                  value={selectedMonth}
                  className="form-select"
                >
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </select>
              </div>


            </div>

          </div>
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr className="table-primary">
                <th>Video Link</th>
                <th>Title</th>
                <th>Topic</th>
                <th>Age</th>
                <th>Ranking</th>
                <th>Anecdotes</th>
                <th>Quiz</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, parentIndex) => (
                <tr
                  key={`child-${item.id}-${item.Topic}-${item.Quiz}-${parentIndex}`}
                >
                  <td>
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${extractVideoId(
                        item.link
                      )}?modestbranding=1`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </td>
                  <td>{item.Title}</td>
                  <td>{item.Topic}</td>
                  <td>{item.Age}</td>

                  <td>
                    <button class="btn btn-primary" onClick={() => openranking(item.id)}>Ranking</button>

                  </td>
                  <td>
                    <button class="btn btn-primary" onClick={() => openanecdotes(item.id)}> Anecdotes</button>

                  </td>
                  <td>
                    <button class="btn btn-primary" onClick={() => getquizes(item.id)}> Quiz</button>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <div className="pagination-container align-items-center">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
        {showPopupRanking && (
          <div className="popup">
            <div className="popup-content">

              <button
                className="btn btn-close text-white"
                onClick={closeRankingPopup}
              ></button>
              {rankingData && rankingData.map((entry) => (
                <>
                  <h1 key={entry.rankdelivery}></h1>
                  <p className="mb-0 fs-4">Total Score: <span className="badge bg-primary">{entry.rankingscore}</span></p>

                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Ranking & Justification
                  </h5>
                  <div
                    className="row bg-secondary mb-4"
                  >

                    <div className="col-md-6 col-sm-12">
                      <div style={{ height: "106px" }}>
                        <span
                          style={{ fontSize: "15px" }}
                        >
                          Delivery
                        </span>
                        <br />
                        <Rate
                          allowHalf
                          count={5}
                          value={parseFloat(
                            entry.rankdelivery
                          )}
                          character={
                            <i className="fas fa-star" />
                          }
                          style={{
                            fontSize: 24,
                            color: "yellow",
                          }}
                        />
                      </div>
                      <div style={{ height: "106px" }}>
                        <span
                          style={{ fontSize: "15px" }}
                        >
                          ContentQuality
                        </span>
                        <br />
                        <Rate
                          allowHalf
                          count={5}
                          value={parseFloat(
                            entry.rankContentQuality
                          )}
                          character={
                            <i className="fas fa-star" />
                          }
                          style={{
                            fontSize: 24,
                            color: "yellow",
                          }}
                        />
                      </div>
                      <div style={{ height: "106px" }}>
                        <span
                          style={{ fontSize: "15px" }}
                        >
                          Engagement Level-
                        </span>
                        <br />
                        <Rate
                          allowHalf
                          count={5}
                          value={parseFloat(
                            entry.rankEngagementLevel
                          )}
                          character={
                            <i className="fas fa-star" />
                          }
                          style={{
                            fontSize: 24,
                            color: "yellow",
                          }}
                        />
                      </div>
                      <div style={{ height: "106px" }}>
                        <span
                          style={{ fontSize: "15px" }}
                        >
                          Duration
                        </span>
                        <br />
                        <Rate
                          allowHalf
                          count={5}
                          value={parseFloat(
                            entry.rankDuration
                          )}
                          character={
                            <i className="fas fa-star" />
                          }
                          style={{
                            fontSize: 24,
                            color: "yellow",
                          }}
                        />


                      </div>

                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-outline mb-4">
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Delivery
                        </span>
                        <textarea
                          type="text"
                          id="form1Example1"
                          class="form-control bg-light"
                          name="Delivery"
                          required
                          value={entry.Delivery}
                          rows="2"
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          ContentQuality
                        </span>
                        <textarea
                          type="text"
                          id="form1Example1"
                          className="form-control bg-light"
                          name="Content"
                          required
                          value={entry.ContentQuality}
                          rows="2"
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Engagement Level-
                        </span>
                        <textarea
                          type="text"
                          id="form1Example1"
                          class="form-control bg-light"
                          name="level"
                          required
                          value={entry.EngagementLevel}
                          rows="2"
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Duration
                        </span>
                        <textarea
                          type="text"
                          id="form1Example1"
                          class="form-control bg-light"
                          name="Duration"
                          required
                          rows="2"
                          value={entry.Duration}
                        />
                      </div>
                    </div>
                    <div className="average-ranking bg-primary text-white p-2">
                      <h6>Total Ranking</h6>
                      <Rate
                        allowHalf
                        count={5}
                        value={parseFloat(
                          entry.totalRankaverage
                        )}
                        character={
                          <i className="fas fa-star" />
                        }
                        style={{
                          fontSize: 24,
                          color: "yellow",
                        }}
                      />
                    </div>
                    <center>

                      <label>Video Ranking Feedback</label>
                      <div class="form-outline mb-4">
                        <span style={{ fontSize: "15px" }}>Ranking Feedback</span>
                        <textarea
                          type="text"
                          id="form1Example1"
                          className="form-control bg-light"
                          name="rankingfeedback"
                          value={entry.rankingfeedback}
                          required
                          placeholder="Video Ranking Feedback (Optional)"
                          rows="2"
                        />
                      </div>
                    </center>

                  </div>
                </>


              ))}
            </div>
          </div>
        )}

        {showAnecdotesPopup && (
          <>
            <div className="popup">
              <div className="popup-content-new">

                <button
                  className="btn btn-close text-white"
                  onClick={closeAnecdotesPopup}
                ></button>

                {anecdotesData && anecdotesData.map((entry) => (
                  <>
                    <p className="mb-0 fs-5">Total Score: <span className="badge bg-primary">{entry.Totalscore}</span></p>

                    <div class="popup-content d-flex flex-row mb-3 bg-secondary">
                      <div class="p-2 w-50 h-20">
                        <div>
                          <h5
                            className="modal-title text-center"
                            id="exampleModalLabel"
                          >
                            Anecdotes
                          </h5>

                          <form>
                            <div className="form-outline mb-4">
                              <span style={{ fontSize: "15px" }}>Anecdote 1</span>
                              <input
                                type="text"
                                id="form1Example1"
                                className="form-control bg-light"
                                name="Anedotes1"
                                value={entry.Anedotes1}
                                required
                                rows="2"
                              />

                            </div>

                            <div className="form-outline mb-4">
                              <span style={{ fontSize: "15px" }}>Anecdote 2</span>
                              <input
                                type="text"
                                id="form1Example2"
                                className="form-control bg-light"
                                name="Anedotes2"
                                value={entry.Anedotes2}

                                required
                                rows="2"
                              />

                            </div>

                            <div className="form-outline mb-4">
                              <span style={{ fontSize: "15px" }}>Anecdote 3</span>
                              <input

                                type="text"
                                id="form1Example3"
                                className="form-control bg-light"
                                name="Anedotes3"
                                value={entry.Anedotes3}

                                required
                                rows="2"
                              />

                            </div>

                            <div className="form-outline mb-4">
                              <span style={{ fontSize: "15px" }}>Anecdote 4</span>
                              <input
                                type="text"
                                id="form1Example4"
                                className="form-control bg-light"
                                name="Anedotes4"
                                required
                                value={entry.Anedotes4}

                                rows="2"
                              />

                            </div>
                            {/* <button
  type="button"
  onClick={() => handleShowAllData(entry.id,entry.AnecdotesId)} 
>
  Save
</button> */}

                          </form>
                        </div>
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      <div class="vl"> </div>
                      &nbsp;&nbsp;&nbsp;

                      <div class="p-2 w-50">
                        <div>
                          <h5 className="modal-title text-center" id="exampleModalLabel">
                            TimeStamp
                          </h5>
                          <div class="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Timestamp 1</span>
                            <input
                              type="text"
                              id="form1Example1"
                              class="form-control bg-light"
                              name="Anedotestime1"
                              value={entry.Anedotestime1}
                              disabled
                              required
                              rows="2"
                            />

                          </div>
                          <div class="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Timestamp 2</span>
                            <input
                              type="text"
                              id="form1Example1"
                              class="form-control bg-light"
                              name="Anedotestime2"
                              value={entry.Anedotestime2}
                              disabled
                              required
                              rows="2"
                            />

                          </div>
                          <div class="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Timestamp 3</span>
                            <input
                              type="text"
                              id="form1Example1"
                              class="form-control bg-light"
                              name="Anedotestime3"
                              value={entry.Anedotestime3}
                              disabled
                              required
                              rows="2"
                            />

                          </div>
                          <div class="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Timestamp 4</span>
                            <input
                              type="text"
                              id="form1Example1"
                              class="form-control bg-light"
                              name="Anedotestime4"
                              value={entry.Anedotestime4}
                              disabled
                              required
                              rows="2"
                            />

                          </div>
                        </div>
                      </div>

                      &nbsp;&nbsp;&nbsp;
                      <div class="vl"> </div>
                      &nbsp;&nbsp;&nbsp;
                      <div className="p-2 w-50">
                        <div>
                          <h5 className="modal-title text-center" id="exampleModalLabel">
                            Tags
                          </h5>
                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Tag 1</span>
                            <input
                              type="text"
                              id="form1Example1"
                              className="form-control bg-light"
                              name="tags1"
                              value={entry.tags1}
                              required
                              rows="2"
                            />

                          </div>

                          {/* Add more tags here */}
                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Tag 2</span>
                            <input
                              type="text"
                              id="form1Example2"
                              className="form-control bg-light"
                              name="tags2"
                              value={entry.tags2}
                              required
                              rows="2"
                            />

                          </div>

                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Tag 3</span>
                            <input
                              type="text"
                              id="form1Example3"
                              className="form-control bg-light"
                              name="tags3"
                              value={entry.tags3}
                              required
                              rows="2"
                            />

                          </div>

                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Tag 4</span>
                            <input
                              type="text"
                              id="form1Example4"
                              className="form-control bg-light"
                              name="tags4"
                              value={entry.tags4}
                              required
                              rows="2"
                            />

                          </div>

                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Tag 5</span>
                            <input
                              type="text"
                              id="form1Example5"
                              className="form-control bg-light"
                              name="tags5"
                              value={entry.tags5}
                              required
                              rows="2"
                            />

                          </div>
                        </div>
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      <div class="vl"> </div>
                      &nbsp;&nbsp;&nbsp;

                      <div className="p-2 w-50">
                        <div>
                          <h5 className="modal-title text-center" id="exampleModalLabel">
                            Tags
                          </h5>
                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Tag 6</span>
                            <input
                              type="text"
                              id="form1Example6"
                              className="form-control bg-light"
                              name="tags6"
                              value={entry.tags6}
                              required
                              rows="2"
                            />

                          </div>

                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Tag 7</span>
                            <input
                              type="text"
                              id="form1Example7"
                              className="form-control bg-light"
                              name="tags7"
                              value={entry.tags7}
                              required
                              rows="2"
                            />

                          </div>

                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Tag 8</span>
                            <input
                              type="text"
                              id="form1Example8"
                              className="form-control bg-light"
                              name="tags8"
                              value={entry.tags8}
                              required
                              rows="2"
                            />

                          </div>

                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Tag 9</span>
                            <input
                              type="text"
                              id="form1Example9"
                              className="form-control bg-light"
                              name="tags9"
                              value={entry.tags9}
                              required
                              rows="2"
                            />

                          </div>

                          <div className="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>Tag 10</span>
                            <input
                              type="text"
                              id="form1Example10"
                              className="form-control bg-light"
                              name="tags10"
                              value={entry.tags10}
                              required
                              rows="2"
                            />

                          </div>
                        </div>
                      </div>

                    </div>
                    <center>
                      <div class="form-outline mb-4">
                        <span style={{ fontSize: "15px" }}>Anecdotes Feedback (Optional)</span>
                        <input
                          type="text"
                          id="form1Example1"
                          class="form-control bg-secondary"
                          name="Annecdotesfeedback"
                          value={entry.Annecdotesfeedback}
                          required
                          rows="2"
                        />
                      </div>
                    </center>
                  </>

                ))}



              </div>

            </div>



          </>

        )
        }
        {showQuizPopup && (
          <div className="popup">
            <div className="popup-content">
              <button className="btn-close text-white" onClick={closeQuizPopup}></button>

              {/* {quizData && quizData.map((entry) => (
                        <>
                        </>
                ))} */}

              <table className="table table-bordered mt-5">
                <thead>
                  <tr>
                    <th style={{ display: "none" }}>Quiz ID</th>
                    <th>Quiz Name</th>
                    <th>Answer</th>
                    <th>Feedback</th>
                    <th>Option</th>
                  </tr>
                </thead>
                <tbody>
                  {quizData.map((dataItem) => (
                    <tr key={dataItem.id}>
                      <td style={{ display: "none" }}>{dataItem.quizId}</td>
                      <td>{dataItem.quizName}</td>
                      <td>
                        {dataItem.options ? (
                          <ul className="list-group">
                            {Object.entries(dataItem.options).map(
                              ([key, value]) => (
                                <li
                                  className="list-group-item responsive-text-options"
                                  key={key}
                                >
                                  <strong>{key}:</strong> {value}
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          <div
                            className="alert alert-warning"
                            role="alert"
                          >
                            No options available
                          </div>
                        )}
                      </td>
                      <td>

                        <div>
                          <textarea
                            type="text"
                            className="form-input"
                            value={dataItem.QuestionFeedback}
                          />
                        </div>



                      </td>
                      <td>
                        <div>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              value="Correct"
                              checked={dataItem.answer === "Correct"}
                            />
                            Correct
                          </label>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              value="In-Correct"
                              checked={dataItem.answer === "In-Correct"}
                            />
                            In-Correct
                          </label>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              value="Not Sure"
                              checked={dataItem.answer === "Not Sure"}
                            />
                            Not-Sure
                          </label>
                        </div>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {totalCompletedvideodata.loading && <p>Loading...</p>}
      {totalCompletedvideodata.error && <p>Error: {totalCompletedvideodata.error}</p>}
      {totalCompletedvideodata.data ? renderData() : null}
    </div>
  );
}

export default CardDetailsPage;

