import axios from "axios";
import {baseURL} from "../../Config/config"

export const FETCH_COMPLETED_REQUEST = "FETCH_COMPLETED_REQUEST";
export const FETCH_COMPLETED_SUCCESS = "FETCH_COMPLETED_SUCCESS";
export const FETCH_COMPLETED_FAILURE = "FETCH_COMPLETED_FAILURE";

export const fetchcompletedvideosRequest = () => ({
  type: FETCH_COMPLETED_REQUEST,
});

export const fetchcompletedvideosSuccess = (data) => ({
  type: FETCH_COMPLETED_SUCCESS,
  payload: data,
});

export const fetchcompletedvideosFailure = (error) => ({
  type: FETCH_COMPLETED_FAILURE,
  payload: error,
});

export const fetchcompletedvideos = (completeduserid,selectedMonth) => async (dispatch) => {
  dispatch(fetchcompletedvideosRequest());
  try {
    const response = await axios.get(
      `${baseURL}/Admin/getquizes/Completed/${completeduserid}/${selectedMonth}`
    );
    dispatch(fetchcompletedvideosSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchcompletedvideosFailure(error.message));
  }
};

