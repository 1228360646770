import getUserIdFromToken from "../../Utils/JWT/jwt";
// import getToken from "../../Utils/JWT/token";
import Swal from "sweetalert2";
// import {baseURL} from "../../Config/config"

export const UPDATE_SETTINGS_REQUEST = "UPDATE_SETTINGS_REQUEST";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAILURE = "UPDATE_SETTINGS_FAILURE";

const userId = getUserIdFromToken();
// const token = getToken();

export const updatesettingsrequest = () => ({
  type: UPDATE_SETTINGS_REQUEST,
});

export const updatesettingssuccess = (data) => ({
  type: UPDATE_SETTINGS_SUCCESS,
  payload: data,
});

export const updatesettingsfailed = (error) => ({
  type: UPDATE_SETTINGS_FAILURE,
  payload: error,
});




export const FETCH_SETTINGS_REQUEST = "FETCH_SETTINGS_REQUEST";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";

export const fetchSettingsRequest = () => ({
  type: FETCH_SETTINGS_REQUEST,
});

export const fetchSettingsSuccess = (data) => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload: data,
});

export const fetchSettingsFailure = (error) => ({
  type: FETCH_SETTINGS_FAILURE,
  payload: error,
});




export const fetchSettings = () => {
    return (dispatch) => {
      dispatch(fetchSettingsRequest());
  
      fetch(`https://mvp-prod.signalz.ai/settings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImQ5NzAzMGM1LTA2Y2YtNDZlNS1iYzEyLWZjOGYwM2VkMTYxZSIsInJvbGUiOiJBRE1JTiIsInV1aWQiOiI3NjE4NzI4YS0yM2FlLTQ1ODMtODdhNC0yODRkYWMyZmFmMDciLCJleHAiOjE3MDMwNDYzODcsInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE3MDE3NTAzODd9.B22I9eYS7Qx_z6hU-kONGYgFTvQV1lTzN9FS7VUYGPk`,
        },
      })
        .then((response) => {
          console.log("????????????????",response);
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        })
        .then((data) => {
          dispatch(fetchSettingsSuccess(data));
        })
        .catch((error) => {
          dispatch(fetchSettingsFailure(error.message));
        });
    };
  };
  
export const updateappsettings = (formData) => {
  return (dispatch) => {
    dispatch(updatesettingsrequest());

    formData.userId = userId;

    fetch(`https://mvp-prod.signalz.ai/settings/cef4d3e6-9182-4f5e-9a13-4955d599c033`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImQ5NzAzMGM1LTA2Y2YtNDZlNS1iYzEyLWZjOGYwM2VkMTYxZSIsInJvbGUiOiJBRE1JTiIsInV1aWQiOiI3NjE4NzI4YS0yM2FlLTQ1ODMtODdhNC0yODRkYWMyZmFmMDciLCJleHAiOjE3MDMwNDYzODcsInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE3MDE3NTAzODd9.B22I9eYS7Qx_z6hU-kONGYgFTvQV1lTzN9FS7VUYGPk`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire("Request Send!", "", "success");
          dispatch(
            updatesettingssuccess({ message: "Successfully posted" })
          );
          console.log("??????????????????????????????",response);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .catch((error) => {
        Swal.fire("Opps!", error.message, "denger");
        dispatch(updatesettingsfailed(error.message));
      });
  };
};
