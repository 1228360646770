import {
  POST_RAISEREQUEST_REQUEST,
  POST_RAISEREQUEST_SUCCESS,
  POST_RAISEREQUEST_FAILURE,
} from "../Action/Raiserequest.action";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const RaiseRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_RAISEREQUEST_REQUEST:
      return { ...state, loading: true, error: null };
    case POST_RAISEREQUEST_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case POST_RAISEREQUEST_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };

    default:
      return state;
  }
};

export default RaiseRequestReducer;
