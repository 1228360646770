import {
    FETCH_DATACOUNT_REQUEST,
    FETCH_DATACOUNT_SUCCESS,
    FETCH_DATACOUNT_FAILURE,
    FETCH_DATACOUNTSIGANLPROD_REQUEST,
    FETCH_DATACOUNTSIGANLPROD_SUCCESS,
    FETCH_DATACOUNTSIGANLPROD_FAILURE
  } from "../Action/DataCount";

  const initialState = {
    loading: false,
    data: null,
    error: null,
  };
const DATACOUNTstaging = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DATACOUNT_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_DATACOUNT_SUCCESS:
        return { ...state, loading: false, data: action.payload, error: null };
      case FETCH_DATACOUNT_FAILURE:
        console.error("Fetch DATACOUNT Failure:", action.payload);
        return { ...state, loading: false, data: null, error: action.payload };
      default:
        return state;
    }
  };

  const DATACOUNTprod = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DATACOUNTSIGANLPROD_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_DATACOUNTSIGANLPROD_SUCCESS:
        return { ...state, loading: false, data: action.payload, error: null };
      case FETCH_DATACOUNTSIGANLPROD_FAILURE:
        console.error("Fetch DATACOUNT Failure:", action.payload);
        return { ...state, loading: false, data: null, error: action.payload };
      default:
        return state;
    }
  };
  export  {DATACOUNTstaging,DATACOUNTprod};