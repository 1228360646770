import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseURL } from "../../../Config/config";
import ReactPaginate from 'react-paginate';
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const YourComponent = () => {
    const [learningData, setLearningData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(3);
    const [pageCount, setPageCount] = useState(0);
    const [ageGroup, setAgeGroup] = useState('');
    const [title, setTitle] = useState('');
    const [ageGroupOptions, setAgeGroupOptions] = useState([]);
    const [titleOptions, setTitleOptions] = useState([]);
    const [selectedLimit, setSelectedLimit] = useState("0");
    const [showQuizPopup, setShowQuizPopup] = useState(false);
    const [quizData, setquizData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        console.log("++++++++++++++++++++++++", title);
        console.log("++++++++++++++++++++++++000000000000000000000", ageGroup);

        try {
            const response = await axios.get(`${baseURL}/Admin/get-transfer-data-prod/${encodeURIComponent(title)}/${encodeURIComponent(ageGroup)}/${selectedLimit}`);
            setLearningData(response.data.data);
            setPageCount(Math.ceil(response.data.data.length / itemsPerPage));
        } catch (error) {
            console.error('Error fetching learning data:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [ageGroup, title, selectedLimit]);


    useEffect(() => {
        fetch(`${baseURL}/Admin/get/All/Age`)
            .then(response => response.json())
            .then(data => {
                setAgeGroupOptions(data.data);
            })
            .catch(error => {
                console.error('Error fetching age groups:', error);
            });

        fetch(`${baseURL}/Admin/get/All/title`)
            .then(response => response.json())
            .then(data => {
                setTitleOptions(data.data);
            })
            .catch(error => {
                console.error('Error fetching titles:', error);
            });
    }, []);
    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = learningData.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };


    const years = [
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },

    ];

    const handleYearChange = (e) => {
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
        setSelectedLimit(e.target.value);
    };
    const extractVideoId = (link) => {
        const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
        return videoIdMatch ? videoIdMatch[1] : null;
    };

    const getquizes = (learningId) => {
        console.log(">>>>>>>>>>>>>>>>><<<<<<<<", learningId);
        const accessTokenWithQuotes = localStorage.getItem('user');
        const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');
        const [header, payload] = accessTokenWithoutQuotes.split(".");
        const decodedPayload = JSON.parse(atob(payload));
        const userid = decodedPayload.id;
        setShowQuizPopup(true)
        axios
            .get(`${baseURL}/Admin/getquiz/Admin/${learningId}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessTokenWithoutQuotes}`,
                },
            })
            .then((response) => {
                const responseData = response.data;
                if (Array.isArray(responseData.data)) {
                    const data = responseData.data;
                    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<", data);
                    setquizData(data);

                } else {
                    console.error("Data received is not an array:", responseData);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const closeQuizPopup = () => {
        setShowQuizPopup(false);
    };
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxClick = (itemId, learningId) => {
        setSelectedItems((prevSelectedItems) => {
            const isSelected = prevSelectedItems.includes(itemId);

            return isSelected
                ? prevSelectedItems.filter((id) => id !== itemId)
                : [...prevSelectedItems, itemId];
        });

        console.log('Learning ID:', learningId);
    };

    // const handleSubmission = () => {
    //     console.log('Selected Item IDs:', selectedItems);
    //     alert("Selected Ids: " + selectedItems.join(', '));
    //     setSelectedItems([]);

    // };

    const handleSubmission = async () => {
        try {
            const response = await axios.post(
                `${baseURL}/learning/sendlearningdata`,
                {
                    learningIds: selectedItems,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log('Server Response:', response.data);
            Swal.fire("Done!", "Data Send To Production", "success");
            setSelectedItems([]);
            fetchData();

        } catch (error) {
            console.error('Error submitting data:', error);
            Swal.fire('Error!', error, 'error');

        }
    };

    useEffect(() => {
        fetchData();
      }, []); 
    

    return (
        <div className="card m-2 shadow-5">
            {/* <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
                <h3>Data Center</h3>
            </div> */}
             <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
                <div className="row align-items-center">
                    {/* <div className="col-auto">
                        <Link to="/DataMasterData">
                            <img src="/Images/left-arrow-new.png" height={50} width={50} />
                        </Link>
                    </div> */}
                    <div className="col">
                        <h3>Data Migration</h3>
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-md-3">
                    <label>Select Age Group:</label>
                    <select
                        className="form-select"
                        value={ageGroup}
                        onChange={(e) => setAgeGroup(e.target.value)}
                    >
                        <option value="">--Select--</option>
                        {ageGroupOptions.map((option) => (
                            <option key={option.ageGroup} value={option.ageGroup}>
                                {option.ageGroup}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3">
                    <label>Select Attribute:</label>
                    <select
                        className="form-select"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    >
                        <option value="">--Select--</option>
                        {titleOptions.map((option) => (
                            <option key={option.attribute} value={option.attribute}>
                                {option.attribute}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3">
                    <label>Select Batch:</label>
                    <select
                        id="monthSelector"
                        onChange={handleYearChange}
                        value={selectedLimit}
                        className="form-select"
                    >
                        {years.map((year) => (
                            <option key={year.value} value={year.value}>
                                {year.label}
                            </option>
                        ))}
                    </select>

                </div>

                <div className="col-md-3">
                    <label>Select Batch:</label><br />

                    <button className="btn btn-success" onClick={handleSubmission}>
                        Submit
                    </button>
                </div>

            </div> */}

            <div className="row">
                <div className="col-md-3">
                    <label>Select Age Group:</label>
                    <select
                        className="form-select"
                        value={ageGroup}
                        onChange={(e) => setAgeGroup(e.target.value)}
                    >
                        <option value="">--Select--</option>
                        {ageGroupOptions.map((option) => (
                            <option key={option.ageGroup} value={option.ageGroup}>
                                {option.ageGroup}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3">
                    <label>Select Attribute:</label>
                    <select
                        className="form-select"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    >
                        <option value="">--Select--</option>
                        {titleOptions.map((option) => (
                            <option key={option.attribute} value={option.attribute}>
                                {option.attribute}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3">
                    <label>Select Batch:</label>
                    <select
                        id="monthSelector"
                        onChange={handleYearChange}
                        value={selectedLimit}
                        className="form-select"
                    >
                        {years.map((year) => (
                            <option key={year.value} value={year.value}>
                                {year.label}
                            </option>
                        ))}
                    </select>
                </div>

                {/* <div className="col-md-3 d-flex align-items-end">
                    <label>Send To Production:</label>
                    <button className="btn btn-success ms-2" onClick={handleSubmission}>
                        Submit
                    </button>
                </div> */}
                <div className="col-md-3">
                    <label>&nbsp;</label><br />
                    <button className="btn btn-primary" onClick={handleSubmission}>
                        Send To Production
                    </button>
                </div>
            </div>

            <ul className="list-group mt-3">
                {currentItems.length === 0 ? (
                    <li className="list-group-item" style={{ color: "red" }}>Please select the age and attribute to see videos</li>
                ) : (
                    currentItems.map((item) => (
                        <li className="list-group-item" key={item.id} style={{ display: "none" }}>
                            {item.Title} - Age: {item.Age} - Topic: {item.Topic}
                        </li>
                    ))
                )}
            </ul>

            <table className="table mt-3">
                <thead>
                    <tr className="table-primary">
                        <th>Video</th>
                        <th>Age</th>
                        <th>Title</th>
                        <th>Topic</th>
                        <th>Total Ranking Score</th>
                        <th>Quiz</th>
                        <th>Select</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item) => (
                        <tr key={item.id}>
                            <td>
                                <iframe
                                    width="560"
                                    height="315"
                                    src={`https://www.youtube.com/embed/${extractVideoId(
                                        item.link
                                    )}?modestbranding=1`}
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            </td>
                            <td>{item.Age}</td>
                            <td>{item.Title}</td>
                            <td>{item.Topic}</td>
                            <td>{item.totalRankaverage}</td>
                            <td>
                                <button class="btn btn-primary" onClick={() => getquizes(item.id)}> Quizes</button>
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    className="btn btn-primary"
                                    checked={selectedItems.includes(item.id)}
                                    onChange={() => handleCheckboxClick(item.id, item.id)}
                                />
                            </td>
                        </tr>
                    ))}

                </tbody>
                {showQuizPopup && quizData && quizData.length > 0 && quizData[0] && (
                    <div className="popup">
                        <div className="popup-content">
                            <button className="btn-close text-white" onClick={closeQuizPopup}></button>
                            <table className="table table-bordered mt-5">
                                <thead>
                                    <tr>
                                        <th style={{ display: "none" }}>Quiz ID</th>
                                        <th>Quiz Name</th>
                                        <th>Options</th>
                                        <th>Answer</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {quizData.map((dataItem) => (
                                        <tr key={dataItem.id}>
                                            <td style={{ display: "none" }}>{dataItem.quizId}</td>
                                            <td>{dataItem.quizName}</td>
                                            <td>
                                                {dataItem.options ? (
                                                    <ul className="list-group">
                                                        {Object.entries(dataItem.options).map(
                                                            ([key, value]) => (
                                                                <li
                                                                    className="list-group-item responsive-text-options"
                                                                    key={key}
                                                                >
                                                                    <strong>{key}:</strong> {value}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                ) : (
                                                    <div
                                                        className="alert alert-warning"
                                                        role="alert"
                                                    >
                                                        No options available
                                                    </div>
                                                )}
                                            </td>
                                            <td>{dataItem.answer}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </table>

            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
            />
        </div>
    );
};

export default YourComponent;
