import React from 'react';
import { Link } from 'react-router-dom';

const YourComponent = () => {
  return (
    <>
      <div className="row mt-5 m-5 ">
        {/* <div className="col-md-3 mt-5">
            <Link to="/">
            <div className="card text-center shadow-5-strong">
            <center>
            <img
              src="Images/Fgb.png"
              alt="FGB Dashboard"
              className="mt-5"
            />
            </center>
           
            <div className="card-body">
              <h5 className="card-title">FGB Dashboard</h5>
            </div>
          </div>
            </Link>
        </div> */}
        
        <div className="col-md-3 mt-5">
            <Link to="/Viewalluserscount">
          <div className="card text-center shadow-5-strong">
            <center>
            <img
              src="Images/video-settings.png"
              alt="Video Management"
              className="mt-5"
              height={80}
              width={80}
            />
            </center>
            <div className="card-body">
              <h5 className="card-title">Masters FGB</h5>
            </div>
          </div>
          </Link>
        </div>
        
        <div className="col-md-3 mt-5">
        <Link to="/LeadBoard">

        <div className="card text-center shadow-5-strong">
            <center>
            <img
              src="Images/leaderboard1.png"
              alt="Lead Board"
              className="mt-5"
              height={80}
              width={80}
            />
            </center>
   
            <div className="card-body">
              <h5 className="card-title">Lead Board</h5>
            </div>
          </div>
          </Link>
        </div>

        <div className="col-md-3 mt-5">
        <Link to="/SimpleLeadboard">

        <div className="card text-center shadow-5-strong">
            <center>
            <img
              src="Images/leaderboard1.png"
              alt="Lead Board"
              className="mt-5"
              height={80}
              width={80}
            />
            </center>
   
            <div className="card-body">
              <h5 className="card-title">April Leadboard (Demo)</h5>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-md-3 mt-5">
            <Link to="/RegisterRole">

        <div className="card text-center shadow-5-strong">
            <center>

            <img
              src="Images/AddnewSuer.png"
              alt="Add New User"
              height={80}
              width={80}
              className="mt-5"

            />
          </center>
            <div className="card-body">
              <h5 className="card-title">Add New User</h5>
            </div>
          </div>
          </Link>

        </div>
        <div className="col-md-3 mt-5">
        <Link to="/Fgbdetails">

        <div className="card text-center shadow-5-strong">
            <center>
            <img
              src="Images/task-list.png"
              alt="Task Status"
              className="mt-5"
              height={80}
              width={80}
            />
            </center>
           
            <div className="card-body">
              <h5 className="card-title">FGB Details</h5>
            </div>
          </div>
          </Link>
        </div>
      </div>

      {/* <div className="row mt-4 mt-5 m-5">
        <div className="col-md-3">
            <Link to="/RegisterRole">

        <div className="card text-center shadow-5-strong">
            <center>

            <img
              src="Images/AddnewSuer.png"
              alt="Add New User"
              height={80}
              width={80}
              className="mt-5"

            />
          </center>
            <div className="card-body">
              <h5 className="card-title">Add New User</h5>
            </div>
          </div>
          </Link>

        </div>
      </div> */}
            {/* <div className="row mt-4 mt-5 m-5">
        <div className="col-md-3">
            <Link to="/AcceptAnedotesTags">

        <div className="card text-center shadow-5-strong">
            <center>

            <img
              src="Images/AddnewSuer.png"
              alt="Add New User"
              height={80}
              width={80}
              className="mt-5"

            />
          </center>
            <div className="card-body">
              <h5 className="card-title">Accept & Reject Annedotes</h5>
            </div>
          </div>
          </Link>

        </div>
      </div> */}
    </>
  );
};

export default YourComponent;
