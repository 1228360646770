import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Videostatus from "../MastersFGB/TotalVideo";
import PendingVideo from "../MastersFGB/PendingVideo";
import CompletedVideo from "../MastersFGB/CompletedVideo";
import { Bars } from "react-loader-spinner";
import { baseURL } from "../../../Config/config"

const UserDetails = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('pending');


  console.log("+QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ", userId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${baseURL}/Admin/getquizes/all/fgbusers/count/${userId}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();
        setUserData(jsonData.data || []);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  if (loading) {
    return <div className="Loader">
      <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
    </div>;
  }


  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    // <div className="containera mt-2 p-2">
    //   <Link to="/Viewalluserscount">
    //   <img src="/Images/left-arrow.png"
    //   height={50}
    //   width={50}
    //   />
    //   </Link>

    //   <br/>
    //   <br/>
    //   <div className="row">
    //     <div className="col">
    //       <strong>User ID:</strong> {userData.userId}
    //     </div>
    //     <div className="col">
    //       <strong>Full Name:</strong> {userData.fullName}
    //     </div>
    //     <div className="col">
    //       <strong>Completed:</strong> {userData.Count || "There no any video completed"}
    //     </div>
    //     <div className="col">
    //       <strong>Total Remaining:</strong> {userData.TotalRemaining}
    //     </div>
    //     <div className="col">
    //       <strong>Total Video:</strong> {userData.TotalVideo}
    //     </div>

    //   </div>
    //   <div>
    //   <ul className="nav nav-tabs">
    //     <li className="nav-item">
    //       <button
    //         className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`}
    //         onClick={() => setActiveTab('pending')}
    //       >
    //         Pending Videos
    //       </button>
    //     </li>
    //     <li className="nav-item">
    //       <button
    //         className={`nav-link ${activeTab === 'completed' ? 'active' : ''}`}
    //         onClick={() => setActiveTab('completed')}
    //       >
    //         Completed Videos
    //       </button>
    //     </li>
    //   </ul>
    //   <div className="tab-content mt-3">
    //     {activeTab === 'pending' && (
    //       <div className="tab-pane fade show active">
    //         <PendingVideo userId={userId} />
    //       </div>
    //     )}
    //     {activeTab === 'completed' && (
    //       <div className="tab-pane fade show active">
    //         <CompletedVideo userId={userId} />
    //       </div>
    //     )}
    //   </div>
    // </div>
    // </div>
    <div className="containera mt-3">
      {/* <div className="card m-2  text-white" style={{ background: 'linear-gradient(to right, #4e54c8, #8f94fb)', borderRadius: '10px' }}>
        <div className="row p-3 align-items-center">
          <div className="col">
            <Link to="/Viewalluserscount">
              <img src="/Images/backbuttonew.png" height={50} width={50} alt="Back" className="p-2" />
            </Link>
          </div>
          <div className="col">
            <strong>Full Name:</strong> {userData.fullName}
          </div>
          <div className="col">
            <strong>Total Video:</strong> {userData.TotalVideo}
          </div>
          <div className="col">
            <strong>Total Remaining:</strong> {userData.TotalRemaining}
          </div>
          <div className="col">
            <strong>Completed:</strong> {userData.Count || "There are no completed videos"}
          </div>
          <div className="col">
            <strong>Batch:</strong> {userData.MaxBatch}
          </div>
        </div>
      </div>
 */}

      <div className="card m-2 text-white" style={{ background: 'linear-gradient(to right, #4e54c8, #8f94fb)', borderRadius: '10px' }}>
        <div className="row p-3 align-items-center">
          <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-2 mb-sm-0">
            <Link to="/Viewalluserscount" className="d-block text-white">
              <img src="/Images/left-arrow-new.png" height={60} width={60} alt="Back" className="p-2" />
            </Link>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-2 mb-sm-0">
            <strong>Full Name:</strong> {userData.fullName}
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-2 mb-sm-0">
            <strong>Total Video:</strong> {userData.TotalVideo}
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-2 mb-sm-0">
            <strong>Total Remaining:</strong> {userData.TotalRemaining}
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-2 mb-sm-0">
            <strong>Completed:</strong> {userData.Count || "There are no completed videos"}
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-2 mb-sm-0">
            <strong>Batch:</strong> {userData.MaxBatch}
          </div>
        </div>
      </div>


      <ul className="nav nav-tabs mt-3">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`}
            onClick={() => setActiveTab('pending')}
          >
            Pending Videos
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'completed' ? 'active' : ''}`}
            onClick={() => setActiveTab('completed')}
          >
            Completed Videos
          </button>
        </li>
      </ul>

      <div className="tab-content mt-3">
        {activeTab === 'pending' && (
          <div className="tab-pane fade show active">
            <PendingVideo userId={userId} />
          </div>
        )}
        {activeTab === 'completed' && (
          <div className="tab-pane fade show active">
            <CompletedVideo userId={userId} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
