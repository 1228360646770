// import {
//     UPDATE_SETTINGS_REQUEST,
//     UPDATE_SETTINGS_SUCCESS,
//     UPDATE_SETTINGS_FAILURE,
//   } from "../Action/RaiseCallRequest.action";
  
//   const initialState = {
//     loading: false,
//     data: null,
//     error: null,
//   };
  
//   const UpdateAppSettings = (state = initialState, action) => {
//     switch (action.type) {
//       case UPDATE_SETTINGS_REQUEST:
//         return { ...state, loading: true, error: null };
//       case UPDATE_SETTINGS_SUCCESS:
//         return { ...state, loading: false, data: action.payload, error: null };
//       case UPDATE_SETTINGS_FAILURE:
//         return { ...state, loading: false, data: null, error: action.payload };
  
//       default:
//         return state;
//     }
//   };
  
//   export default UpdateAppSettings;
import {
    UPDATE_SETTINGS_REQUEST,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAILURE,
    FETCH_SETTINGS_REQUEST,
    FETCH_SETTINGS_SUCCESS,
    FETCH_SETTINGS_FAILURE,
  } from "../Action/appsettings";
  
  const initialState = {
    loading: false,
    data: null,
    error: null,
  };
  
  const UpdateAppSettings = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_SETTINGS_REQUEST:
      case FETCH_SETTINGS_REQUEST:
        return { ...state, loading: true, error: null };
  
      case UPDATE_SETTINGS_SUCCESS:
      case FETCH_SETTINGS_SUCCESS:
        return { ...state, loading: false, data: action.payload, error: null };
  
      case UPDATE_SETTINGS_FAILURE:
      case FETCH_SETTINGS_FAILURE:
        return { ...state, loading: false, data: null, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default UpdateAppSettings;
  