import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { fetchpendingvideos } from "../../../Redux/Action/PendingVideos.action";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import { baseURL } from '../../../Config/config';
import { Rate } from "antd";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
function CardDetailsPage({ userId }) {
  const dispatch = useDispatch();
  const totalPendingvideodata = useSelector((state) => state.pendingvideo);
  const [rankingscore, setRankingscore] = useState(0);
  const [tagsValue, setTagsValue] = useState('0');
  const [userIdRanking, setuserIdRanking] = useState(userId);
  const [quizfeedbackscore, setquizfeedbackscore] = useState(0);
  const [error, setError] = useState('');

  const [userlearingId, setuserlearningId] = useState();


  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPopupRanking, setShowPopupRanking] = useState(false);
  const [showLearningId, setLearningId] = useState(false);
  const [rankingData, setRankingData] = useState(null);
  const [showAnecdotesPopup, setShowAnecdotesPopup] = useState(false);
  const [anecdotesData, setAnecdotesData] = useState(null);
  const [entry, setEntry] = useState([]);

  const [quizData, setquizData] = useState(null);
  const [showQuizPopup, setShowQuizPopup] = useState(false);
  const [scores, setScores] = useState({
    Delivery: 0,
    ContentQuality: 0,
    EngagementLevel: 0,
    Duration: 0
  });

  const [manualScores, setManualScores] = useState({
    Delivery: '',
    ContentQuality: '',
    EngagementLevel: '',
    Duration: ''
  });
  const itemsPerPage = 5;
  const handleChangeranking = (e) => {
    // setRankingscore(e.target.value)

    const inputScore = e.target.value;
    if (inputScore > 30) {
      setError('Ranking score cannot exceed 30');
    } else {
      setError('');
      setRankingscore(inputScore);
    }
  };

  const handleChange = (e) => {
    // setTagsValue(event.target.value);
    const inputScore = e.target.value;
    if (inputScore > 20) {
      setError('Ranking Justification score cannot exceed 20');
    } else {
      setError('');
      setTagsValue(inputScore);
    }
  };


  // useEffect(() => {
  //   dispatch(fetchpendingvideos(userId,selectedMonth));
  // }, [dispatch]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const closeRankingPopup = () => {
    setShowPopupRanking(false);
    setError('');

  };

  const closeAnecdotesPopup = () => {
    setShowAnecdotesPopup(false);
    setEntry([]);
    setButtonStates({});
  };


  const pageCount = totalPendingvideodata.data
    ? Math.ceil(totalPendingvideodata.data.length / itemsPerPage)
    : 1;

  const extractVideoId = (link) => {
    const videoIdMatch = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };


  const openranking = (learningId) => {
    setLearningId(learningId)
    console.log("::::::::::::::::::::", learningId);
    setIsLoading(true);
    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
    const [header, payload] = accessTokenWithoutQuotes.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;
    axios
      .get(`${baseURL}/Admin/getranking/Completed/learningID/${learningId}/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          console.log(">>>>>>>>>>>>>>>", data)
          // setPostData(data);
          setShowPopupRanking(true);
          setRankingData(data);

          // handleAcceptance("response",5)

          const properties = [
            "Delivery", "Duration", "ContentQuality", "EngagementLevel"
          ];
          // handleAcceptance("Delivery","accept");

          for (let property of properties) {
            if (data[0][property] === "") {
              console.log(`There is no ${property}`);
            } else {
              console.log(`${property} Available: ${data[0][property]}`);
              handleAcceptance(property, "accept");
            }
          }


        } else {
          console.error('Data received is not an array:', responseData);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });

  }

  const openanecdotesdata = (learningId) => {
    console.log("_______________ID:", learningId);
    setIsLoading(true);

    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
    const [header, payload] = accessTokenWithoutQuotes.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;
    axios
      .get(`${baseURL}/Admin/get/Annedotes/learningID/${learningId}/${userId}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data) && responseData.data.length > 0) {
          const data = responseData.data;
          console.log("+++++++++++++++++++++++++++++++++++++++LLLLLLLLLLLLLLLLAAAAAAAAAAAAAAAAAAAAAAAAAANNNNNNNNNNNNNNNNNNNNNNEEEEEEEEEEEEEEEE", data);
          setAnecdotesData(data);
          setShowAnecdotesPopup(true);
          const properties = [
            "Anedotes1", "Anedotes2", "Anedotes3", "Anedotes4",
            "Anedotestime1", "Anedotestime2", "Anedotestime3", "Anedotestime4",
            "tags1", "tags2", "tags3", "tags4", "tags5", "tags6", "tags7", "tags8", "tags9", "tags10"
          ];

          for (let property of properties) {
            if (data[0][property] === "") {
              console.log(`There is no ${property}`);
            } else {
              console.log(`${property} Available: ${data[0][property]}`);
              handleClick(property);
            }
          }

        } else {
          console.error("Data received is not an array:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });

  }

  const savescore = (tagsValue, rankingscoree, videorankingId) => {
    const accessTokenforuserid = localStorage.getItem("user");

    const accessTokenforuseridtwo = accessTokenforuserid.replace(/"/g, "");

    const rankingscore = parseInt(totalScore) + rankingscoree;
    let data = JSON.stringify({
      "rankingscore": rankingscore
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseURL}/Admin/update/rankingscore/${videorankingId}`,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${accessTokenforuseridtwo}`,
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setTagsValue("0");
        // alert("Data Saved")
        NotificationManager.success('Data saved successfully!', 'Success');

        closeRankingPopup();
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = 'Error saving data: ' + error.response.data.message;        ;
        NotificationManager.error(errorMessage, 'Error');
      });
  };


  const [buttonStates, setButtonStates] = useState([]);

  function handleClick(property) {
    const isAccepted = buttonStates[property];
    // const label = isAccepted ? "Accepted" : "Rejected";
    const label = isAccepted ? "Rejected" : "Accepted";

    console.log(`Anecdote ${property} is ${label}`);

    if (!buttonStates[property]) {
      setEntry((prevEntry) => ({
        ...prevEntry,
        [property]: "Accepted",
      }));
      setButtonStates((prevButtonStates) => ({
        ...prevButtonStates,
        [property]: true,
      }));
    } else {
      setEntry((prevEntry) => ({
        ...prevEntry,
        [property]: "Rejected",
      }));
      setButtonStates((prevButtonStates) => ({
        ...prevButtonStates,
        [property]: false,
      }));
    }
  }

  const fetchDataEffect = () => {
    dispatch(fetchpendingvideos(userId, selectedMonth));
  };

  const handleShowAllData = async (id, AnecdotesId) => {

    setEntry([]);
    setButtonStates({});

    console.log("All Data:", AnecdotesId);
    let anedotesScore = 0;
    let anedotestimeScore = 0;
    let tagsScore = 0;

    for (const field in entry) {
      if (entry.hasOwnProperty(field)) {
        const label = buttonStates[field] ? "Accepted" : "Rejected";
        let score = 0;

        if (field.startsWith("Anedotes")) {
          score = buttonStates[field] ? 1.875 : 0;
          anedotesScore += score;
        } else if (field.startsWith("Anedotestime")) {
          score = buttonStates[field] ? 1.875 : 0;
          anedotestimeScore += score;
        } else if (field.startsWith("tags")) {
          score = buttonStates[field] ? 1.5 : 0;
          tagsScore += score;
        }

        console.log(`${field}: ${entry[field]}, Status: ${label}, Score: ${score}`);
      }
    }

    const score = anedotesScore + anedotestimeScore + tagsScore;
    const anecdotestagsId = AnecdotesId;
    console.log("+___________+__++", anecdotestagsId);
    const userId = userIdRanking;
    const learningId = id;
    console.log("User id For Anecdotes Ranking", userId);


    const dataToStore = { ...entry, score, anecdotestagsId, userId, learningId };

    console.log("Data to Store:", dataToStore);

    const accessTokenforuserid = localStorage.getItem("user");

    const accessTokenforuseridtwo = accessTokenforuserid.replace(/"/g, "");




    fetch(`${baseURL}/raisecallrequest/acceptrejectannecdotes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessTokenforuseridtwo}`,

      },
      body: JSON.stringify(dataToStore),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Data stored successfully:', result);
        NotificationManager.success('Data saved successfully!', 'Success');
        fetchDataEffect();
        closeAnecdotesPopup();


      })

      .catch((error) => {
        console.error('Error storing data:', error);
        const errorMessage = 'Error saving data: ' + error.response.data.message;        ;
        NotificationManager.error(errorMessage, 'Error');
      });
  };




  const getquizes = (learningId) => {
    setuserlearningId(learningId);

    console.log(">>>>>>>>>>>>>>>>><<<<<<<<", learningId);
    const accessTokenWithQuotes = localStorage.getItem('user');
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');
    const [header, payload] = accessTokenWithoutQuotes.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;
    setIsLoading(true);

    axios
      .get(`${baseURL}/Admin/getquiz/Completed/Admin/${userId}/${learningId}`, {
        headers: {
          Accept: "application/json"
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data) && responseData.data.length > 0) {
          const data = responseData.data;
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<", data);
          setquizData(data);
          setShowQuizPopup(true);

        } else {
          console.error("Data received is not an array:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const closeQuizPopup = () => {
    setShowQuizPopup(false);
    setError('');

  };

  const handleChangequizfeedback = (e) => {
    // setquizfeedbackscore(e.target.value)
    const inputScore = e.target.value;

    // Check if inputScore is greater than 20
    if (inputScore > 20) {
      setError('Quiz score cannot exceed 20');
    } else {
      setError('');
      setquizfeedbackscore(inputScore);
    }
  };
  const savequizfeedbackdata = () => {
    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++", quizfeedbackscore);
    const accessTokenforuserid = localStorage.getItem("user");
    closeQuizPopup();
    const accessTokenforuseridtwo = accessTokenforuserid.replace(/"/g, "");

    const quizscore = quizfeedbackscore;
    let data = JSON.stringify({
      "quizscore": quizscore
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseURL}/Admin/update/rankingquizfeedback/${userlearingId}/${userIdRanking}`,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${accessTokenforuseridtwo}`,
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setquizfeedbackscore("0");
        // alert("Data Saved")
        NotificationManager.success('Data saved successfully!', 'Success');

        closeRankingPopup();
      })
      .catch((error) => {
        // console.log(error);
        const errorMessage = 'Error saving data: ' + error.response.data.message;        ;
        NotificationManager.error(errorMessage, 'Error');
      });

  }


  const [selectedMonth, setSelectedMonth] = useState("02");
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  useEffect(() => {
    dispatch(fetchpendingvideos(userId, selectedMonth));
  }, [dispatch, userId, selectedMonth]);

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };



  const handleAcceptance = (fieldName, value) => {
    setScores(prevScores => ({
      ...prevScores,
      [fieldName]: value === 'accept' ? 5 : 0
    }));
  };
  const handleManualScoreChange = (fieldName, event) => {
    const value = event.target.value.trim();
    if (!value || !isNaN(parseFloat(value))) {
      setManualScores(prevManualScores => ({
        ...prevManualScores,
        [fieldName]: value
      }));
    }
  };


  const handleSetManualScore = (fieldName) => {
    if (!isNaN(manualScores[fieldName]) && manualScores[fieldName] <= 5) {
      setScores(prevScores => ({
        ...prevScores,
        [fieldName]: parseFloat(manualScores[fieldName])
      }));
    }
  };

  const totalScore = scores.ContentQuality + scores.EngagementLevel + scores.Duration + scores.Delivery;

  const renderData = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = totalPendingvideodata.data.slice(startIndex, endIndex);

    return (
      <div>
        {isLoading && (
          <div className="Loader">
            <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
          </div>
        )}
        <div className="table-responsive table-container">
          <div className="d-flex ms-5">
            <label htmlFor="monthSelector" className="mt-2 me-3 alert alert-primary p-2">Select Month:</label>
            <div className="mb-2 me-2 d-flex align-items-center">
              <select
                id="monthSelector"
                onChange={handleMonthChange}
                value={selectedMonth}
                className="form-select"
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
            </div>


          </div>

          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr className="table-primary">
                <th>Video Link</th>
                <th>Title</th>
                <th>Topic</th>
                <th>Age</th>
                <th>Ranking</th>
                <th>Anecdotes</th>
                <th>Quiz</th>

              </tr>
            </thead>
            <tbody>
              {currentData.map((item, parentIndex) => (
                <tr
                  key={`child-${item.id}-${item.Topic}-${item.Quiz}-${parentIndex}`}
                >
                  <td>
                    {/* <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${extractVideoId(
                        item.link
                      )}?modestbranding=1`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe> */}
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${extractVideoId(item.link)}?modestbranding=1`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>


                  </td>
                  <td>{item.Title}</td>
                  <td>{item.Topic}</td>
                  <td>{item.Age}</td>
                  <td>
                    <button class="btn btn-primary" onClick={() => getquizes(item.id)}> Quiz</button>

                  </td>
                  <td>
                    <button class="btn btn-primary" onClick={() => openranking(item.id)}>Ranking</button>

                  </td>
                  <td>
                    <button class="btn btn-primary" onClick={() => openanecdotesdata(item.id)}> Anecdotes</button>

                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex align-items-center justify-content-center" >
          <div className="pagination-container align-items-center">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>






        {showPopupRanking && (
          <div className="popup">
            <div className="popup-content">

              <button
                className="btn btn-close text-white"
                onClick={closeRankingPopup}
              ></button>
              {rankingData && rankingData.map((entry) => (
                <>
                  <h1 key={entry.rankdelivery}></h1>
                  <p className="mb-0 fs-4">Total Score: <span className="badge bg-primary">{entry.rankingscore || rankingscore}</span></p>
                  {error && <div className="alert alert-danger mt-3">{error}</div>}

                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Ranking & Justification
                  </h5>
                  <div
                    className="row bg-secondary mb-4"
                  >
                    <div className="col-md-6 col-sm-12">

                      <div className="delivery-section mb-4 mt-2">
                        <label htmlFor="deliveryTextarea"><b>Delivery</b> </label>
                        <div>
                          <Rate
                            allowHalf
                            count={5}
                            value={parseFloat(
                              entry.rankdelivery
                            )}
                            character={
                              <i className="fas fa-star" />
                            }
                            style={{
                              fontSize: 24,
                              color: "#FFBF00",
                            }}
                          />
                        </div>
                        <textarea
                          id="deliveryTextarea"
                          className="form-control bg-light"
                          name="Delivery"
                          required
                          value={entry.Delivery}
                          rows="2"
                        />

                        <div className="delivery-options mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="deliveryAcceptRadio"
                              name="deliveryAcceptance"
                              value="accept"
                              checked={scores.Delivery === 5}
                              onChange={(event) => handleAcceptance('Delivery', event.target.value)}
                              className="form-check-input"
                            />
                            <label htmlFor="deliveryAcceptRadio" className="form-check-label">Accept</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="deliveryRejectRadio"
                              name="deliveryAcceptance"
                              value="reject"
                              checked={scores.Delivery === 0}
                              onChange={(event) => handleAcceptance('Delivery', event.target.value)}
                              className="form-check-input"
                            />
                            <label htmlFor="deliveryRejectRadio" className="form-check-label">Reject</label>
                          </div>

                          {scores.Delivery === 0 && (
                            <div className="manual-score">
                              <input
                                type="number"
                                id="deliveryManualScoreInput"
                                value={manualScores.Delivery}
                                onChange={(event) => handleManualScoreChange('Delivery', event)}
                                className="form-control mt-1"
                                style={{ width: "100px" }}
                              />
                            </div>
                          )}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p className="alert alert-success p-2  mt-3 text-dark"> <b>Delivery Score: {scores.Delivery}</b> </p>
                          {scores.Delivery === 0 && (
                            <button onClick={() => handleSetManualScore('Delivery')} className="btn btn-primary mt-1">Set Manual Score</button>
                          )}
                        </div>
                      </div>

                      <div className="delivery-section">
                        <label htmlFor="deliveryTextarea"><b>Content Quality</b></label>
                        <div>
                          <Rate
                            allowHalf
                            count={5}
                            value={parseFloat(
                              entry.rankContentQuality
                            )}
                            character={
                              <i className="fas fa-star" />
                            }
                            style={{
                              fontSize: 24,
                              color: "#FFBF00",
                            }}
                          />
                        </div>
                        <textarea
                          type="text"
                          id="form1Example1"
                          className="form-control bg-light"
                          name="Content"
                          required
                          value={entry.ContentQuality}
                          rows="2"
                        />

                        <div className="delivery-options mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="contentQualityAcceptRadio"
                              name="contentQualityAcceptance"
                              value="accept"
                              checked={scores.ContentQuality === 5}
                              className="form-check-input"
                              onChange={(event) => handleAcceptance('ContentQuality', event.target.value)}
                            />
                            <label htmlFor="contentQualityAcceptRadio" className="form-check-label">Accept</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="contentQualityRejectRadio"
                              name="contentQualityAcceptance"
                              value="reject"
                              checked={scores.ContentQuality === 0}
                              onChange={(event) => handleAcceptance('ContentQuality', event.target.value)}
                              className="form-check-input"
                            />
                            <label htmlFor="contentQualityRejectRadio" className="form-check-label">Reject</label>
                          </div>
                          {scores.ContentQuality === 0 && (
                            <div className="manual-score">
                              <input
                                type="number"
                                id="contentQualityManualScoreInput"
                                value={manualScores.ContentQuality}
                                className="form-control"
                                style={{ width: "100px" }}
                                onChange={(event) => handleManualScoreChange('ContentQuality', event)}
                              />
                            </div>
                          )}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p className="alert alert-success p-2  mt-3 text-dark"><b> Content Quality Score: {scores.ContentQuality} </b></p>
                          {scores.ContentQuality === 0 && (

                            <button onClick={() => handleSetManualScore('ContentQuality')} className="btn btn-primary mt-2">Set Manual Score</button>
                          )}
                        </div>
                      </div>
                      <center>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Ranking Feedback</span>
                          <textarea
                            type="text"
                            id="form1Example1"
                            className="form-control bg-light"
                            name="rankingfeedback"
                            value={entry.rankingfeedback}
                            required
                            placeholder="Video Ranking Feedback (Optional)"
                            rows="2"
                          />
                        </div>
                      </center>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="delivery-section mb-4 mt-2">
                        <label htmlFor="deliveryTextarea"><b> Engagement Level</b> </label>
                        <div>
                          <Rate
                            allowHalf
                            count={5}
                            value={parseFloat(
                              entry.rankEngagementLevel
                            )}
                            character={
                              <i className="fas fa-star" />
                            }
                            style={{
                              fontSize: 24,
                              color: "#FFBF00",
                            }}
                          />
                        </div>
                        <textarea
                          type="text"
                          id="form1Example1"
                          className="form-control bg-light"
                          name="level"
                          required
                          value={entry.EngagementLevel}
                          rows="2"
                        />

                        <div className="delivery-options mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="engagementLevelAcceptRadio"
                              name="engagementLevelAcceptance"
                              value="accept"
                              className="form-check-input"
                              checked={scores.EngagementLevel === 5}
                              onChange={(event) => handleAcceptance('EngagementLevel', event.target.value)}
                            />
                            <label htmlFor="engagementLevelAcceptRadio" className="form-check-label">Accept</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="engagementLevelRejectRadio"
                              name="engagementLevelAcceptance"
                              value="reject"
                              className="form-check-input"
                              checked={scores.EngagementLevel === 0}
                              onChange={(event) => handleAcceptance('EngagementLevel', event.target.value)}
                            />
                            <label htmlFor="engagementLevelRejectRadio" className="form-check-label">Reject</label>
                          </div>
                          {scores.EngagementLevel === 0 && (
                            <div className="manual-score">
                              <input
                                type="number"
                                id="engagementLevelManualScoreInput"
                                value={manualScores.EngagementLevel}
                                className="form-control"
                                style={{ width: "100px" }}
                                onChange={(event) => handleManualScoreChange('EngagementLevel', event)}
                              />
                            </div>
                          )}
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p className="alert alert-success p-2  mt-3 text-dark"> <b> Engagement Level Score: {scores.EngagementLevel}</b></p>
                          {scores.EngagementLevel === 0 && (

                            <button onClick={() => handleSetManualScore('EngagementLevel')} className="btn btn-primary mt-2">Set Manual Score</button>
                          )}

                        </div>

                      </div>

                      <div className="delivery-section">
                        <label htmlFor="deliveryTextarea"><b>Duration</b> </label>
                        <div>
                          <Rate
                            allowHalf
                            count={5}
                            value={parseFloat(
                              entry.rankDuration
                            )}
                            character={
                              <i className="fas fa-star" />
                            }
                            style={{
                              fontSize: 24,
                              color: "#FFBF00",
                            }}
                          />
                        </div>
                        <textarea
                          type="text"
                          id="form1Example1"
                          className="form-control bg-light"
                          name="level"
                          required
                          value={entry.Duration}
                          rows="2"
                        />

                        <div className="delivery-options mt-3">
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="durationAcceptRadio"
                              name="durationAcceptance"
                              value="accept"
                              checked={scores.Duration === 5}
                              className="form-check-input"
                              onChange={(event) => handleAcceptance('Duration', event.target.value)}
                            />
                            <label htmlFor="durationAcceptRadio" className="form-check-label">Accept</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="durationRejectRadio"
                              name="durationAcceptance"
                              value="reject"
                              checked={scores.Duration === 0}
                              className="form-check-input"
                              onChange={(event) => handleAcceptance('Duration', event.target.value)}
                            />
                            <label htmlFor="durationRejectRadio" className="form-check-label">Reject</label>
                          </div>
                          {scores.Duration === 0 && (
                            <div className="manual-score">
                              <input
                                type="number"
                                id="durationManualScoreInput"
                                value={manualScores.Duration}
                                className="form-control"
                                style={{ width: "100px" }}
                                onChange={(event) => handleManualScoreChange('Duration', event)}
                              />
                            </div>
                          )}
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p className="alert alert-success p-2  mt-3 text-dark"><b>Duration Score:  {scores.Duration}</b> </p>
                          {scores.Duration === 0 && (

                            <button onClick={() => handleSetManualScore('Duration')} className="btn btn-primary mt-2">Set Manual Score</button>
                          )}

                        </div>
                      </div>

                      <div className="d-flex flex-row mt-2">
                        <div className="p-2 text-center" style={{display:"none"}}>
                          <span
                            style={{ fontSize: "15px" }}
                          >
                            <a
                              data-mdb-toggle="tooltip"
                              title="Rating* (Weighted average of below parameters) Content Quality (1-5) as per legend - 30% Quality of Delivery(1-5) 30% Engagement Level (1-5) 30% Duration (justified or not)(1-5) 10%"
                            >
                              Ranking{" "}
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span>
                          <input
                            type="number"
                            id="anecdotesInput"
                            className="form-control bg-light"
                            name="anecdotes"
                            value={rankingscore}
                            onChange={handleChangeranking}

                            required
                            style={{ width: "80px" }}
                          />
                        </div>

                        <div className="p-2 text-center">
                          <span
                            style={{ fontSize: "15px" }}
                          >
                            <a
                              data-mdb-toggle="tooltip"
                              title="Justification for each of the Ratings Score 25"
                            >
                              Justification{" "}
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <input
                              type="number"
                              id="tagsInput"
                              className="form-control bg-light"
                              name="tags"
                              value={totalScore}
                              onChange={handleChange}
                              required
                              style={{ width: "80px" }}
                            />                                                </span>

                        </div>
                        <div className="p-2 text-center mt-2">
                          <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            onClick={() => savescore(tagsValue, rankingscore, entry.id)}
                          >
                            Save
                          </button>

                        </div>

                      </div>
                    </div>

                    <div className="average-ranking bg-primary text-white p-2">
                      <h6>Total Ranking</h6>
                      <Rate
                        allowHalf
                        count={5}
                        value={parseFloat(
                          entry.totalRankaverage
                        )}
                        character={
                          <i className="fas fa-star" />
                        }
                        style={{
                          fontSize: 24,
                          color: "#FFBF00",
                        }}
                      />
                    </div>

                  </div>
                </>


              ))}

            </div>

          </div>
        )}


        {showAnecdotesPopup && (
          <>

            <div className="popup">
              <div className="popup-content">
                <button
                  className="btn btn-close text-white"
                  onClick={closeAnecdotesPopup}
                ></button>
                {anecdotesData && anecdotesData.map((entry) => {
                  return (
                    <div key={entry.id}>
                      <div className="popup-header d-flex justify-content-between align-items-center">
                        <p className="mb-0 fs-5">Total Score: <span className="badge bg-primary">{entry.Totalscore}</span></p>
                      </div>
                      <div class="popup-content d-flex flex-row mb-3 bg-secondary">
                        <div class="p-2 w-50">
                          <div>
                            <h5
                              className="modal-title text-center"
                              id="exampleModalLabel"
                            >
                              Anecdotes
                            </h5>

                            <>

                              <div className="form-outline mb-4">
                                <span style={{ fontSize: "15px" }}>Anecdote 1</span>
                                <textarea
                                  type="text"
                                  id="form1Example1"
                                  className="form-control bg-light"
                                  name="Anedotes1"
                                  value={entry.Anedotes1}
                                  required
                                  rows="1" />
                              </div>

                              {entry.Anedotes1 && (
                                <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                  <input
                                    type="radio"
                                    id="acceptRadio"
                                    name="Anedotes1Radio"
                                    className="btn-check"
                                    autoComplete="off"
                                    onClick={() => {
                                      handleClick("Anedotes1");
                                      handleClick("Anedotestime1");
                                    }}
                                    checked={buttonStates["Anedotes1"]}
                                    disabled={buttonStates["Anedotes1"]} />
                                  <input
                                    type="radio"
                                    id="rejectRadio"
                                    name="Anedotes1Radio"
                                    className="btn-check"
                                    autoComplete="off"
                                    onClick={() => {
                                      handleClick("Anedotes1");
                                      handleClick("Anedotestime1");
                                    }}
                                    checked={!buttonStates["Anedotes1"]}
                                    disabled={!buttonStates["Anedotes1"]} />
                                  <div>
                                    <label className={`btn ${buttonStates["Anedotes1"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="acceptRadio">{String.fromCharCode(10003)}</label>
                                    <label className={`btn ${!buttonStates["Anedotes1"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejectRadio">{String.fromCharCode(10006)}</label>
                                  </div>
                                </div>
                              )}
                              <div className="form-outline mb-4 mt-3">
                                <span style={{ fontSize: "15px" }}>Anecdote 2</span>
                                <textarea
                                  type="text"
                                  id="form1Example2"
                                  className="form-control bg-light"
                                  name="Anedotes2"
                                  value={entry.Anedotes2}
                                  required
                                  rows="1" />
                              </div>

                              {entry.Anedotes2 && (

                                <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                  <input
                                    type="radio"
                                    id="acceptRadio2"
                                    name="Anedotes2Radio"
                                    className="btn-check"
                                    autoComplete="off"
                                    // onClick={() => handleClick("Anedotes2")}
                                    onClick={() => {
                                      handleClick("Anedotes2");
                                      handleClick("Anedotestime2");
                                    }}
                                    checked={buttonStates["Anedotes2"]}
                                    disabled={buttonStates["Anedotes2"]} />
                                  <input
                                    type="radio"
                                    id="rejectRadio2"
                                    name="Anedotes2Radio"
                                    className="btn-check"
                                    autoComplete="off"
                                    // onClick={() => handleClick("Anedotes2")}
                                    onClick={() => {
                                      handleClick("Anedotes2");
                                      handleClick("Anedotestime2");
                                    }}
                                    checked={!buttonStates["Anedotes2"]}
                                    disabled={!buttonStates["Anedotes2"]} />
                                  <div>
                                    <label className={`btn ${buttonStates["Anedotes2"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="acceptRadio2">{String.fromCharCode(10003)}</label>
                                    <label className={`btn ${!buttonStates["Anedotes2"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejectRadio2">{String.fromCharCode(10006)}</label>
                                  </div>
                                </div>
                              )}

                              <div className="form-outline mb-4 mt-3">
                                <span style={{ fontSize: "15px" }}>Anecdote 3</span>
                                <textarea
                                  type="text"
                                  id="form1Example3"
                                  className="form-control bg-light"
                                  name="Anedotes3"
                                  value={entry.Anedotes3}
                                  required
                                  rows="1" />
                              </div>
                              {entry.Anedotes3 && (


                                <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                  <input
                                    type="radio"
                                    id="acceptRadio3"
                                    name="Anedotes3Radio"
                                    className="btn-check"
                                    autoComplete="off"
                                    // onClick={() => handleClick("Anedotes3")}
                                    onClick={() => {
                                      handleClick("Anedotes3");
                                      handleClick("Anedotestime3");
                                    }}
                                    checked={buttonStates["Anedotes3"]}
                                    disabled={buttonStates["Anedotes3"]} />
                                  <input
                                    type="radio"
                                    id="rejectRadio3"
                                    name="Anedotes3Radio"
                                    className="btn-check"
                                    autoComplete="off"
                                    // onClick={() => handleClick("Anedotes3")}
                                    onClick={() => {
                                      handleClick("Anedotes3");
                                      handleClick("Anedotestime3");
                                    }}
                                    checked={!buttonStates["Anedotes3"]}
                                    disabled={!buttonStates["Anedotes3"]} />
                                  <div>
                                    <label className={`btn ${buttonStates["Anedotes3"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="acceptRadio3">{String.fromCharCode(10003)}</label>
                                    <label className={`btn ${!buttonStates["Anedotes3"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejectRadio3">{String.fromCharCode(10006)}</label>
                                  </div>
                                </div>
                              )}

                              <div className="form-outline mb-4 mt-3">
                                <span style={{ fontSize: "15px" }}>Anecdote 4</span>
                                <textarea
                                  type="text"
                                  id="form1Example4"
                                  className="form-control bg-light"
                                  name="Anedotes4"
                                  value={entry.Anedotes4}
                                  required
                                  rows="1" />
                              </div>
                              {entry.Anedotes4 && (

                                <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                  <input
                                    type="radio"
                                    id="acceptRadio4"
                                    name="Anedotes4Radio"
                                    className="btn-check"
                                    autoComplete="off"
                                    // onClick={() => handleClick("Anedotes4")}
                                    onClick={() => {
                                      handleClick("Anedotes4");
                                      handleClick("Anedotestime4");
                                    }}
                                    checked={buttonStates["Anedotes4"]}
                                    disabled={buttonStates["Anedotes4"]} />
                                  <input
                                    type="radio"
                                    id="rejectRadio4"
                                    name="Anedotes4Radio"
                                    className="btn-check"
                                    autoComplete="off"
                                    // onClick={() => handleClick("Anedotes4")}
                                    onClick={() => {
                                      handleClick("Anedotes4");
                                      handleClick("Anedotestime4");
                                    }}
                                    checked={!buttonStates["Anedotes4"]}
                                    disabled={!buttonStates["Anedotes4"]} />
                                  <div>
                                    <label className={`btn ${buttonStates["Anedotes4"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="acceptRadio4">{String.fromCharCode(10003)}</label>
                                    <label className={`btn ${!buttonStates["Anedotes4"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejectRadio4">{String.fromCharCode(10006)}</label>
                                  </div>
                                </div>
                              )}
                              <br></br>                              <br></br>


                              <button
                                type="button"
                                class="btn btn-primary btn-lg"
                                disabled={parseFloat(entry.Totalscore) > 0}
                                onClick={() => handleShowAllData(entry.id, entry.AnecdotesId)}
                              >
                                Save
                              </button>
                            </>
                          </div>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div class="vl"> </div>
                        &nbsp;&nbsp;&nbsp;

                        <div class="p-2 w-50">
                          <div>
                            <h5 className="modal-title text-center" id="exampleModalLabel">
                              TimeStamp
                            </h5>
                            <div class="form-outline mb-4">
                              <span style={{ fontSize: "15px" }}>Timestamp 1</span>
                              <input
                                type="text"
                                id="form1Example1"
                                class="form-control bg-light"
                                name="Anedotestime1"
                                value={entry.Anedotestime1}
                                disabled
                                required
                                rows="2" />
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleClick("Anedotestime1", entry.id);
                                  }}
                                  style={{ display: "none" }}
                                  disabled={buttonStates["Anedotestime1"]}
                                >
                                  {buttonStates["Anedotestime1"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                </button>
                              </div>
                            </div>
                            <div class="form-outline mb-4">
                              <span style={{ fontSize: "15px" }}>Timestamp 2</span>
                              <input
                                type="text"
                                id="form1Example1"
                                class="form-control bg-light"
                                name="Anedotestime2"
                                value={entry.Anedotestime2}
                                disabled
                                required
                                rows="2" />
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleClick("Anedotestime2", entry.id);
                                  }}
                                  style={{ display: "none" }}
                                  disabled={buttonStates["Anedotestime2"]}
                                >
                                  {buttonStates["Anedotestime2"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                </button>
                              </div>
                            </div>
                            <div class="form-outline mb-4">
                              <span style={{ fontSize: "15px" }}>Timestamp 3</span>
                              <input
                                type="text"
                                id="form1Example1"
                                class="form-control bg-light"
                                name="Anedotestime3"
                                value={entry.Anedotestime3}
                                disabled
                                required
                                rows="2" />
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleClick("Anedotestime3", entry.id);
                                  }}
                                  style={{ display: "none" }}
                                  disabled={buttonStates["Anedotestime3"]}
                                >
                                  {buttonStates["Anedotestime3"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                </button>
                              </div>
                            </div>
                            <div class="form-outline mb-4">
                              <span style={{ fontSize: "15px" }}>Timestamp 4</span>
                              <input
                                type="text"
                                id="form1Example1"
                                class="form-control bg-light"
                                name="Anedotestime4"
                                value={entry.Anedotestime4}
                                disabled
                                required
                                rows="2" />
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleClick("Anedotestime4", entry.id);
                                  }}
                                  style={{ display: "none" }}
                                  disabled={buttonStates["Anedotestime4"]}
                                >
                                  {buttonStates["Anedotestime4"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        &nbsp;&nbsp;&nbsp;
                        <div class="vl"> </div>
                        &nbsp;&nbsp;&nbsp;
                        <div className="p-2 w-50">
                          <div>
                            <h5 className="modal-title text-center" id="exampleModalLabel">
                              Tags
                            </h5>
                            {/* <div className="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 1</span>
              <input
                type="text"
                id="form1Example1"
                className="form-control bg-light"
                name="tags1"
                value={entry.tags1}
                required
                rows="2"
              />
              <div>
                <button
                  type="button"
                  onClick={() => {
                    handleClick("tags1");
                  }}
                  disabled={buttonStates["tags1"]}
                >
                  {buttonStates["tags1"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                </button>
              </div>
            </div> */}

                            <div className="form-outline mb-4">
                              <span style={{ fontSize: "15px" }}>Tag 1</span>
                              <textarea
                                type="text"
                                id="form1Example1"
                                className="form-control bg-light"
                                name="tags1"
                                value={entry.tags1}
                                required
                                rows="1" />
                            </div>
                            {entry.tags1 && (

                              <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                <input
                                  type="radio"
                                  id="accepttags1Radio"
                                  name="tags1Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags1");
                                  }}
                                  checked={buttonStates["tags1"]}
                                  disabled={buttonStates["tags1"]} />
                                <input
                                  type="radio"
                                  id="rejecttags1Radio"
                                  name="tags1Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags1");
                                  }}
                                  checked={!buttonStates["tags1"]}
                                  disabled={!buttonStates["tags1"]} />
                                <div>
                                  <label className={`btn ${buttonStates["tags1"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="accepttags1Radio">{String.fromCharCode(10003)}</label>
                                  <label className={`btn ${!buttonStates["tags1"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejecttags1Radio">{String.fromCharCode(10006)}</label>
                                </div>
                              </div>
                            )}


                            {/* <div className="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 2</span>
              <input
                type="text"
                id="form1Example2"
                className="form-control bg-light"
                name="tags2"
                value={entry.tags2}
                required
                rows="2"
              />
              <div>
                <button
                  type="button"
                  onClick={() => {
                    handleClick("tags2");
                  }}
                  disabled={buttonStates["tags2"]}
                >
                  {buttonStates["tags2"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                </button>
              </div>
            </div> */}

                            <div className="form-outline mb-4 mt-3">
                              <span style={{ fontSize: "15px" }}>Tag 2</span>
                              <textarea
                                type="text"
                                id="form1Example1"
                                className="form-control bg-light"
                                name="tags2"
                                value={entry.tags2}
                                required
                                rows="1" />
                            </div>

                            {entry.tags2 && (

                              <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                <input
                                  type="radio"
                                  id="accepttags2Radio"
                                  name="tags2Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags2");
                                  }}
                                  checked={buttonStates["tags2"]}
                                  disabled={buttonStates["tags2"]} />
                                <input
                                  type="radio"
                                  id="rejectttags2Radio"
                                  name="tags2Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags2");
                                  }}
                                  checked={!buttonStates["tags2"]}
                                  disabled={!buttonStates["tags2"]} />
                                <div>
                                  <label className={`btn ${buttonStates["tags2"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="accepttags2Radio">{String.fromCharCode(10003)}</label>
                                  <label className={`btn ${!buttonStates["tags2"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejectttags2Radio">{String.fromCharCode(10006)}</label>
                                </div>
                              </div>
                            )}
                            {/* <div className="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 3</span>
              <input
                type="text"
                id="form1Example3"
                className="form-control bg-light"
                name="tags3"
                value={entry.tags3}
                required
                rows="2"
              />
              <div>
                <button
                  type="button"
                  onClick={() => {
                    handleClick("tags3");
                  }}
                  disabled={buttonStates["tags3"]}
                >
                  {buttonStates["tags3"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                </button>
              </div>
            </div> */}
                            {/* Tag 3 */}
                            <div className="form-outline mb-4 mt-3">
                              <span style={{ fontSize: "15px" }}>Tag 3</span>
                              <textarea
                                type="text"
                                id="form1Example3"
                                className="form-control bg-light"
                                name="tags3"
                                value={entry.tags3}
                                required
                                rows="1" />
                            </div>
                            {entry.tags3 && (

                              <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                <input
                                  type="radio"
                                  id="accepttags3Radio"
                                  name="tags3Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags3");
                                  }}
                                  checked={buttonStates["tags3"]}
                                  disabled={buttonStates["tags3"]} />
                                <input
                                  type="radio"
                                  id="rejecttags3Radio"
                                  name="tags3Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags3");
                                  }}
                                  checked={!buttonStates["tags3"]}
                                  disabled={!buttonStates["tags3"]} />
                                <div>
                                  <label className={`btn ${buttonStates["tags3"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="accepttags3Radio">{String.fromCharCode(10003)}</label>
                                  <label className={`btn ${!buttonStates["tags3"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejecttags3Radio">{String.fromCharCode(10006)}</label>
                                </div>
                              </div>
                            )}

                            {/* Tag 4 */}
                            <div className="form-outline mb-4 mt-3">
                              <span style={{ fontSize: "15px" }}>Tag 4</span>
                              <textarea
                                type="text"
                                id="form1Example4"
                                className="form-control bg-light"
                                name="tags4"
                                value={entry.tags4}
                                required
                                rows="1" />
                            </div>
                            {entry.tags4 && (

                              <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                <input
                                  type="radio"
                                  id="accepttags4Radio"
                                  name="tags4Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags4");
                                  }}
                                  checked={buttonStates["tags4"]}
                                  disabled={buttonStates["tags4"]} />
                                <input
                                  type="radio"
                                  id="rejecttags4Radio"
                                  name="tags4Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags4");
                                  }}
                                  checked={!buttonStates["tags4"]}
                                  disabled={!buttonStates["tags4"]} />
                                <div>
                                  <label className={`btn ${buttonStates["tags4"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="accepttags4Radio">{String.fromCharCode(10003)}</label>
                                  <label className={`btn ${!buttonStates["tags4"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejecttags4Radio">{String.fromCharCode(10006)}</label>
                                </div>
                              </div>
                            )}
                            {/* Tag 5 */}
                            <div className="form-outline mb-4 mt-3">
                              <span style={{ fontSize: "15px" }}>Tag 5</span>
                              <textarea
                                type="text"
                                id="form1Example5"
                                className="form-control bg-light"
                                name="tags5"
                                value={entry.tags5}
                                required
                                rows="1" />
                            </div>
                            {entry.tags5 && (

                              <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                <input
                                  type="radio"
                                  id="accepttags5Radio"
                                  name="tags5Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags5");
                                  }}
                                  checked={buttonStates["tags5"]}
                                  disabled={buttonStates["tags5"]} />
                                <input
                                  type="radio"
                                  id="rejecttags5Radio"
                                  name="tags5Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags5");
                                  }}
                                  checked={!buttonStates["tags5"]}
                                  disabled={!buttonStates["tags5"]} />
                                <div>
                                  <label className={`btn ${buttonStates["tags5"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="accepttags5Radio">{String.fromCharCode(10003)}</label>
                                  <label className={`btn ${!buttonStates["tags5"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejecttags5Radio">{String.fromCharCode(10006)}</label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>


                        &nbsp;&nbsp;&nbsp;
                        <div class="vl"> </div>
                        &nbsp;&nbsp;&nbsp;

                        <div className="p-2 w-50">
                          <div>
                            <h5 className="modal-title text-center" id="exampleModalLabel">
                              Tags
                            </h5>
                            {/* <div className="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 6</span>
              <input
                type="text"
                id="form1Example6"
                className="form-control bg-light"
                name="tags6"
                value={entry.tags6}
                required
                rows="2"
              />
              <div>
                <button
                  type="button"
                  onClick={() => {
                    handleClick("tags6");
                  }}
                  disabled={buttonStates["tags6"]}
                >
                  {buttonStates["tags6"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                </button>
              </div>
            </div>

            <div className="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 7</span>
              <input
                type="text"
                id="form1Example7"
                className="form-control bg-light"
                name="tags7"
                value={entry.tags7}
                required
                rows="2"
              />
              <div>
                <button
                  type="button"
                  onClick={() => {
                    handleClick("tags7");
                  }}
                  disabled={buttonStates["tags7"]}
                >
                  {buttonStates["tags7"] ? String.fromCharCode(10006) : String.fromCharCode(10003)}
                </button>
              </div>
            </div> */}

                            <div className="form-outline mb-4">
                              <span style={{ fontSize: "15px" }}>Tag 6</span>
                              <textarea
                                type="text"
                                id="form1Example6"
                                className="form-control bg-light"
                                name="tags6"
                                value={entry.tags6}
                                required
                                rows="1" />
                            </div>
                            {entry.tags6 && (

                              <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                <input
                                  type="radio"
                                  id="accepttags6Radio"
                                  name="tags6Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags6");
                                  }}
                                  checked={buttonStates["tags6"]}
                                  disabled={buttonStates["tags6"]} />
                                <input
                                  type="radio"
                                  id="rejecttags6Radio"
                                  name="tags6Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags6");
                                  }}
                                  checked={!buttonStates["tags6"]}
                                  disabled={!buttonStates["tags6"]} />
                                <div>
                                  <label className={`btn ${buttonStates["tags6"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="accepttags6Radio">{String.fromCharCode(10003)}</label>
                                  <label className={`btn ${!buttonStates["tags6"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejecttags6Radio">{String.fromCharCode(10006)}</label>
                                </div>
                              </div>
                            )}

                            {/* Tag 7 */}
                            <div className="form-outline mb-4 mt-3">
                              <span style={{ fontSize: "15px" }}>Tag 7</span>
                              <textarea
                                type="text"
                                id="form1Example7"
                                className="form-control bg-light"
                                name="tags7"
                                value={entry.tags7}
                                required
                                rows="1" />
                            </div>
                            {entry.tags7 && (

                              <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                <input
                                  type="radio"
                                  id="accepttags7Radio"
                                  name="tags7Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags7");
                                  }}
                                  checked={buttonStates["tags7"]}
                                  disabled={buttonStates["tags7"]} />
                                <input
                                  type="radio"
                                  id="rejecttags7Radio"
                                  name="tags7Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags7");
                                  }}
                                  checked={!buttonStates["tags7"]}
                                  disabled={!buttonStates["tags7"]} />
                                <div>
                                  <label className={`btn ${buttonStates["tags7"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="accepttags7Radio">{String.fromCharCode(10003)}</label>
                                  <label className={`btn ${!buttonStates["tags7"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejecttags7Radio">{String.fromCharCode(10006)}</label>
                                </div>
                              </div>
                            )}
                            {/* Tag 8 */}
                            <div className="form-outline mb-4 mt-3">
                              <span style={{ fontSize: "15px" }}>Tag 8</span>
                              <textarea
                                type="text"
                                id="form1Example8"
                                className="form-control bg-light"
                                name="tags8"
                                value={entry.tags8}
                                required
                                rows="1" />
                            </div>
                            {entry.tags8 && (

                              <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                <input
                                  type="radio"
                                  id="accepttags8Radio"
                                  name="tags8Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags8");
                                  }}
                                  checked={buttonStates["tags8"]}
                                  disabled={buttonStates["tags8"]} />
                                <input
                                  type="radio"
                                  id="rejecttags8Radio"
                                  name="tags8Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags8");
                                  }}
                                  checked={!buttonStates["tags8"]}
                                  disabled={!buttonStates["tags8"]} />
                                <div>
                                  <label className={`btn ${buttonStates["tags8"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="accepttags8Radio">{String.fromCharCode(10003)}</label>
                                  <label className={`btn ${!buttonStates["tags8"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejecttags8Radio">{String.fromCharCode(10006)}</label>
                                </div>
                              </div>
                            )}
                            {/* Tag 9 */}
                            <div className="form-outline mb-4 mt-3">
                              <span style={{ fontSize: "15px" }}>Tag 9</span>
                              <textarea
                                type="text"
                                id="form1Example9"
                                className="form-control bg-light"
                                name="tags9"
                                value={entry.tags9}
                                required
                                rows="1" />
                            </div>
                            {entry.tags9 && (

                              <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                <input
                                  type="radio"
                                  id="accepttags9Radio"
                                  name="tags9Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags9");
                                  }}
                                  checked={buttonStates["tags9"]}
                                  disabled={buttonStates["tags9"]} />
                                <input
                                  type="radio"
                                  id="rejecttags9Radio"
                                  name="tags9Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags9");
                                  }}
                                  checked={!buttonStates["tags9"]}
                                  disabled={!buttonStates["tags9"]} />
                                <div>
                                  <label className={`btn ${buttonStates["tags9"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="accepttags9Radio">{String.fromCharCode(10003)}</label>
                                  <label className={`btn ${!buttonStates["tags9"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejecttags9Radio">{String.fromCharCode(10006)}</label>
                                </div>
                              </div>
                            )}

                            {/* Tag 10 */}
                            <div className="form-outline mb-4 mt-3">
                              <span style={{ fontSize: "15px" }}>Tag 10</span>
                              <textarea
                                type="text"
                                id="form1Example10"
                                className="form-control bg-light"
                                name="tags10"
                                value={entry.tags10}
                                required
                                rows="1" />
                            </div>
                            {entry.tags10 && (
                              <div className="btn-group" role="group" aria-label="Acceptance and Rejection">
                                <input
                                  type="radio"
                                  id="accepttags10Radio"
                                  name="tags10Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags10");
                                  }}
                                  checked={buttonStates["tags10"]}
                                  disabled={buttonStates["tags10"]} />
                                <input
                                  type="radio"
                                  id="rejecttags10Radio"
                                  name="tags10Radio"
                                  className="btn-check"
                                  autoComplete="off"
                                  onClick={() => {
                                    handleClick("tags10");
                                  }}
                                  checked={!buttonStates["tags10"]}
                                  disabled={!buttonStates["tags10"]} />
                                <div>
                                  <label className={`btn ${buttonStates["tags10"] ? 'btn-success' : 'btn-outline-success me-3'}`} htmlFor="accepttags10Radio">{String.fromCharCode(10003)}</label>
                                  <label className={`btn ${!buttonStates["tags10"] ? 'btn-danger' : 'btn-outline-danger'}`} htmlFor="rejecttags10Radio">{String.fromCharCode(10006)}</label>
                                </div>
                              </div>
                            )}


                          </div>
                        </div>

                      </div>
                      <center>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>Anecdotes Feedback (Optional)</span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-secondary"
                            name="Annecdotesfeedback"
                            value={entry.Annecdotesfeedback}
                            required
                            rows="2" />
                        </div>
                      </center>
                    </div>
                  );
                })}
              </div>
            </div>
          </>

        )}


        {showQuizPopup && (
          <div className="popup">
            <div className="popup-content">
              <button className="btn-close text-white" onClick={closeQuizPopup}></button>
              <div style={{ textAlign: 'center' }}>
                <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '8px', marginBottom: '20px', marginTop: "20px" }}>
                  <center>
                    <label>Quiz Score(Verification of Quizzes + Verification of Quiz answers)=20</label>

                    <input
                      type="number"
                      id="anecdotesInput"
                      className="form-control"
                      name="anecdotes"
                      value={quizfeedbackscore}
                      onChange={handleChangequizfeedback}
                      required
                      style={{ width: '80px' }}
                    />
                    <br></br>
                    {error && <div className="alert alert-danger">{error}</div>}

                  </center>

                  <button
                    type="button"
                    className="btn btn-primary btn-lg"
                    onClick={() => savequizfeedbackdata()}
                    style={{ marginTop: '10px' }}
                  >
                    Save
                  </button>
                </div>
              </div>

              <table className="table table-bordered mt-5">
                <thead>
                  <tr class="table-primary">
                    <th style={{ display: "none" }}>Quiz ID</th>
                    <th>Quiz Name</th>
                    <th>Answer</th>
                    <th>Quiz Answer</th>
                    <th>Selected Answer</th>
                    <th>Feedback</th>

                  </tr>
                </thead>
                <tbody>
                  {quizData.map((dataItem) => (
                    <tr key={dataItem.id}>
                      <td style={{ display: "none" }}>{dataItem.quizId}</td>
                      <td>{dataItem.quizName}</td>
                      <td>
                        {dataItem.options ? (
                          <ul className="list-group">
                            {Object.entries(dataItem.options).map(
                              ([key, value]) => (
                                <li
                                  className="list-group-item responsive-text-options"
                                  key={key}
                                >
                                  <strong>{key}:</strong> {value}
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          <div
                            className="alert alert-warning"
                            role="alert"
                          >
                            No options available
                          </div>
                        )}
                      </td>

                      <td>{dataItem.quizanswer}</td>

                      <td>
                        <div>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              value="Correct"
                              checked={dataItem.answer === "Correct"}
                            />
                            Correct
                          </label>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              value="In-Correct"
                              checked={dataItem.answer === "In-Correct"}
                            />
                            In-Correct
                          </label>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              value="Not Sure"
                              checked={dataItem.answer === "Not Sure"}
                            />
                            Not-Sure
                          </label>
                        </div>

                      </td>
                      <td>

                        <div>
                          <textarea
                            type="text"
                            className="form-input"
                            value={dataItem.QuestionFeedback}
                          />
                        </div>



                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

      </div>
    );
  };

  return (
    <div>
      {totalPendingvideodata.loading && <p>Loading...</p>}
      {totalPendingvideodata.error && <p>Error: {totalPendingvideodata.error}</p>}
      {totalPendingvideodata.data ? renderData() : null}
    </div>
  );
}

export default CardDetailsPage;

