import React, { useState } from "react";
import axios from "axios";
import "../../Assets/Css/RegisterForm.css";
import Swal from "sweetalert2";
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { postraisereq } from "../../Redux/Action/Raiserequest.action";
import { useDispatch, useSelector } from "react-redux";

function Riserequest() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const accessTokenWithQuotes = localStorage.getItem("user");
  const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

  const [header, payload] = accessTokenWithoutQuotes.split(".");
  const decodedPayload = JSON.parse(atob(payload));
  const [formData, setFormData] = React.useState({
    request: "",
    fullName: "",
    phonenumber: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!formData.fullName.trim()) {
      validationErrors.fullName = "Full Name is required";
    }

    if (!formData.phonenumber.trim()) {
      validationErrors.phonenumber = "Phone Number is required";
    }
    if (!formData.request.trim()) {
      validationErrors.request = "Reason is required";
    }
    if (Object.keys(validationErrors).length === 0) {
      dispatch(postraisereq(formData, setIsLoading));
      setFormData({
        request: "",
        fullName: "",
        phonenumber: "",
      })
    } else {
      setErrors(validationErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "phonenumber") {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      setFormData({ ...formData, [name]: cleanedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };
  return (
    <div className="p-3 mt-5">
      {isLoading && (
        <div className="Loader">
          <Bars
            height={80}
            width={80}
            color="#4fa94d"
            ariaLabel="bars-loading"
          />
        </div>
      )}

      <div className="registration-container mb-5">
        <form className="registration-form">
          <div className="form-group">
            <Link to="/Support">
              <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
              &nbsp;&nbsp;&nbsp;
            </Link>
            <label htmlFor="fullName">Raise Request</label>
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="fullName">Name:</label>
            <input
              className="input-Role"
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
            {errors.fullName && (
              <span className="text-danger">{errors.fullName}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="phonenumber">Phone Number:</label>
            <input
              type="number"
              className="input-Role"
              id="phonenumber"
              name="phonenumber"
              value={formData.phonenumber}
              maxLength={10}
              onChange={handleChange}
            />
            {errors.phonenumber && (
              <span className="text-danger">{errors.phonenumber}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="request">Reason:</label>
            <textarea
              type="text"
              className="input-Role"
              id="request"
              name="request"
              value={formData.request}
              onChange={handleChange}
            />
            {errors.request && (
              <span className="text-danger">{errors.request}</span>
            )}
          </div>
          <div className="mt-5">
            <center>
              <button
                type="button"
                // class="btn btn-primary btn-rounded w-25"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </center>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Riserequest;
