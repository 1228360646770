import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import Swal from "sweetalert2";
import "../Assets/Css/Signin.css";
import { baseURL } from "../Config/config"

function SignIn() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpMessage, setOtpMessage] = useState("");
  const [username, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    window.location.reload();

    setModalOpen(false);
  };
  const validateFormEmail = () => {
    const newErrorsemail = {};

    if (!email) {
      newErrorsemail.email = "*Email is required";
    }

    return newErrorsemail;
  };
  const sendOtp = (e) => {
    e.preventDefault();
    setErrors({});

    const newErrorss = validateFormEmail();
    if (Object.keys(newErrorss).length === 0) {
      const data = JSON.stringify({ email });

      fetch(`${baseURL}/auth/forgetpassword-with-Email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setOtpSent(true);
            setOtpMessage(data.message);
          } else {
            setOtpMessage("An error occurred.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setErrors(newErrorss);
    }
  };

  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    if (!username) {
      newErrors.username = "*Username is required";
    }

    if (!password) {
      newErrors.password = "*Password is required";
    }

    return newErrors;
  };

  const handelogin = async () => {
    setErrors({});

    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);

      fetch(`${baseURL}/auth/login-with-email`, {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            console.log("response",response.ok)
            return response.json();
          } else {
            throw new Error("Username or password is incorrect!");
          }
        })
        .then((result) => {
          Swal.fire("Login Done!", "Welcome To Signal!", "success");
          localStorage.setItem("user", JSON.stringify(result.data.token));
          localStorage.setItem("profile", JSON.stringify(result.data));
          const accessTokenWithQuotes = localStorage.getItem("user");
          const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
          const [header, payload] = accessTokenWithoutQuotes.split(".");
          const decodedPayload = JSON.parse(atob(payload));
          const userRole = decodedPayload.role;
          console.log(">>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<",userRole);
          if(userRole ==="FGB"){
            navigate("/DataTableQuiz");

          }
          else if(userRole ==="EVALUATOR"){
            navigate("/Evaluator");
          }
          else{
            navigate("/");

          }

        })
        .catch((error) => {
          console.error("An error occurred:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setErrors(newErrors);
    }
  };

  // const [passwords, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div class="containers">
      {isLoading && (
        <div className="Loader">
          <Bars
            height={80}
            width={80}
            color="#4fa94d"
            ariaLabel="bars-loading"
          />
        </div>
      )}
      <div class="forms-container">
        <div class="signin-signup">
          <form action="#" class="sign-in-form login-form">
            <img
              src="Images/mainlogo.png"
              alt=""
              className="img-fluid"
              height={200}
              width={200}
            />

            <p class="title-text mt-5" style={{ "fontSize": "15px" }}>Please enter the following details to Login to your account</p>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input
                type="text"
                name="username"
                placeholder="Username"
                onChange={(e) => setemail(e.target.value)}
                value={username}
              />
            </div>
            {errors.username && (
              <div className="error-container">
                <span className="text-danger">{errors.username}</span>
              </div>
            )}
            {/* <div>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={passwords}
              />
              <button onClick={handleTogglePassword}>
                {showPassword ? 'Hide' : 'Show'} Password
              </button>
            </div> */}

            {/* <div class="input-field">
              <i class="fas fa-lock"></i>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="password"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
              />
              <h2 onClick={handleTogglePassword} className="toggle-password">
                {showPassword ? (
                  <i className="fa fa-eye" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-eye-slash" aria-hidden="true"></i>
                )}{' '}
              </h2>

            </div> */}
            {/* <input
                type={password}
                name="password"
                placeholder="Password"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
              />
            <div>
              <i className="fas fa-lock"></i>
              

            </div> */}
            {/* <div className="input-field">
              <i className="fas fa-lock"></i>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
              />

            </div>

            <i
              onClick={handleTogglePassword}
              className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'} m-3`}
              aria-hidden="true"
              style={{ cursor: 'pointer' }}
            ></i> */}
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
              />


             
            </div>
            <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="showPasswordCheckbox"
                  onChange={handleTogglePassword}
                  checked={showPassword}
                />
                <label className="form-check-label" htmlFor="showPasswordCheckbox">
                  Show Password
                </label>
              </div>
              
            {errors.password && (
              <div className="error-container">
                <span className="text-danger">{errors.password}</span>
              </div>
            )}
            <button
              type="button"
              name="button"
              className="signin-button login_signin-button"
              onClick={handelogin}
              class="signin-button solid"
            >
              Login
            </button>
            <p className="social-text text-primary" onClick={openModal}>
              Forgot Password
            </p>
          </form>
        </div>
      </div>

      <div class="panels-container">
        <div class="panel left-panel">
          <div class="content">
            <h3>Welcome To Signalz.ai</h3>
            <a href="https://signalz.ai/" className="text-white">
              We don’t know what professional skills our children would need 10
              years from now, but we do know that the ability to learn new
              skills quickly and apply those skills will stay relevant forever.
              Let us join hands and prepare them to be future-ready.
            </a>
          </div>
          <img src="img/log.svg" class="image" alt="" />
        </div>
        <div class="panel right-panel">
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <div class="d-flex flex-row justify-content-between mb-3">
                  <div>
                    <i
                      class="fa fa-times-circle"
                      aria-hidden="true"
                      style={{ marginRight: "5px" }}
                      onClick={closeModal}
                    ></i>
                  </div>
                </div>
                <div class="text-center">
                  <h2 className="text-primary">Forgot Password</h2>
                </div>

                {/* {otpSent &&(
              <div>
                <p class="message">{otpMessage}</p>
              </div>
            )} */}
                {otpSent ? (
                  <div>
                    <p className="message">{otpMessage}</p>
                    <button
                      className="modal-close"
                      onClick={closeModal}
                      class="signin-button solid"
                    >
                      Close
                    </button>
                  </div>
                ) : (
                  <div>

                    <div></div>

                    <p
                      className="bg-primary text-white p-2 rounded"
                      style={{ fontSize: "14px" }}
                    >
                      Enter your email address to receive a reset password link
                    </p>
                    <center>
                      <div class="input-field">
                        <i class="fas fa-user"></i>
                        <input
                          type="text"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />

                      </div>
                      {errors.email && (
                        <div className="error-container">
                          <span className="text-danger">{errors.email}</span>
                        </div>
                      )}
                    </center>

                    <button onClick={sendOtp} class="signin-button solid">
                      Send Link
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SignIn;
