
import React, { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import { Rate } from "antd";
import axios from "axios";
import { baseURL } from '../../Config/config';
import {NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from "react-redux";
import { fetchQuizes ,updateRankindData,fetchPREVIOUSvideo} from "../../Redux/Action/EditRanking.action";

function ViewQuestion() {
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [showPopup, setShowPopup] = useState(false);
  const [rankingData, setRankingData] = useState(null);
  const [anecdotesData, setAnecdotesData] = useState(null);
  const [showAnecdotesPopup, setShowAnecdotesPopup] = useState(false);
  const [showQuizPopup, setShowQuizPopup] = useState(false);


  const [anecdotesId, setanecdotesId] = useState({});
  const [learningidnew, setlearningidnew] = useState({});


  const [quizData, setquizData] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editedFeedback, setEditedFeedback] = useState("");
  const [neweditedFeedback, setNewEditedFeedback] = useState("");

  const [editState, setEditState] = useState({});
  const dispatch = useDispatch();

  const handleFeedbackChange = (id, value) => {
    console.log(">>>>>>>>>>>>>>>>", value);
    setEditedFeedback((prev) => ({ ...prev, [id]: value }));
    const questionFeedback = editedFeedback[Object.keys(editedFeedback)];
    console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&7", value);
    setNewEditedFeedback(questionFeedback)
    setNewEditedFeedback((prev) => ({ ...prev, [id]: value }));
  };


  const handleEdit = (id) => {
    setEditState((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleSave = (id, answer,QuestionFeedback) => {

    setEditState((prevState) => ({
      ...prevState,
      [id]: false,
    }));
    console.log(id, answer, "?????>>>>")

    const accessTokenWithQuotes = localStorage.getItem('user');
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');


    const finaldata = {
      answer: answer,
      quizId: id,
      QuestionFeedback: QuestionFeedback
    }

    console.log("_++++++++++++++++++", finaldata)
    fetch(`${baseURL}/Admin/update/question/answer/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(finaldata),
    })
      .then((response) => response.json())
      .then((responseData) => {
        // alert("Data Updated");
        NotificationManager.success('Data Updated successfully!', 'Success');


      })
      .catch((error) => {
        // console.error("Error saving data:", error);
        NotificationManager.error(error, 'Error');

      })
      .finally(() => {
        setIsLoading(false);
      });
  };




  const { error, data, message } = useSelector((state) => state.perviousvideo);
  useEffect(() => {
    dispatch(fetchPREVIOUSvideo());
  }, [dispatch]);


  console.log("++++++++++++++++++++++++++++++++++++++++++++SSSSSSSSSSSSSSSSSSSSSSSSsss",data);
  const initialEditedValues = {
    Delivery: '',
    ContentQuality: '',
    EngagementLevel: '',
    Duration: '',
    rankingfeedback: '',
    rankdelivery: 0,
    rankContentQuality: 0,
    rankDuration: 0,
    rankEngagementLevel: 0,
    totalRankaverage: 0

  };

  const initialEditedValuesanecdotes = {
    Anedotes1: "",
    Anedotes2: "",
    Anedotes3: "",
    Anedotes4: "",
    Anedotestime1: "",
    Anedotestime2: "",
    Anedotestime3: "",
    Anedotestime4: "",
    tags1: "",
    tags2: "",
    tags3: "",
    tags4: "",
    tags5: "",
    tags6: "",
    tags7: "",
    tags8: "",
    tags9: "",
    tags10: "",
    suggestedLinks: "",
    Annecdotesfeedback: ""

  };

  const initialEditedValuesQuiz = {
    answer: "",
    quizId: "",
    QuestionFeedback: ""

  };

  const [editedValues, setEditedValues] = useState(initialEditedValues);
  const [editedValuesanecdotes, setEditedValuesanecdotes] = useState(initialEditedValues);

  const [editedValuesquiz, setEditedValuesquiz] = useState(initialEditedValues);

  useEffect(() => {
    if (quizData && quizData.length > 0) {
      setEditedValuesquiz({
        answer: quizData[0]?.answer || '',
        quizId: quizData[0]?.quizId || '',
        QuestionFeedback: quizData[0]?.QuestionFeedback || ''

      });
    } else {
      setEditedValuesquiz(initialEditedValuesQuiz);
    }
  }, [quizData]);


  useEffect(() => {
    if (anecdotesData && anecdotesData.length > 0) {
      setEditedValuesanecdotes({
        Anedotes1: anecdotesData[0]?.Anedotes1 || '',
        Anedotes2: anecdotesData[0]?.Anedotes2 || '',
        Anedotes3: anecdotesData[0]?.Anedotes3 || '',
        Anedotes4: anecdotesData[0]?.Anedotes4 || '',
        Anedotestime1: anecdotesData[0]?.Anedotestime1 || '',
        Anedotestime2: anecdotesData[0]?.Anedotestime2 || '',
        Anedotestime3: anecdotesData[0]?.Anedotestime3 || '',
        Anedotestime4: anecdotesData[0]?.Anedotestime4 || '',
        tags1: anecdotesData[0]?.tags1 || '',
        tags2: anecdotesData[0]?.tags2 || '',
        tags3: anecdotesData[0]?.tags3 || '',
        tags4: anecdotesData[0]?.tags4 || '',
        tags5: anecdotesData[0]?.tags5 || '',
        tags6: anecdotesData[0]?.tags6 || '',
        tags7: anecdotesData[0]?.tags7 || '',
        tags8: anecdotesData[0]?.tags8 || '',
        tags9: anecdotesData[0]?.tags9 || '',
        tags10: anecdotesData[0]?.tags10 || '',
        suggestedLinks: anecdotesData[0]?.suggestedLinks || '',
        Annecdotesfeedback: anecdotesData[0]?.Annecdotesfeedback || '',

      });
    } else {
      setEditedValuesanecdotes(initialEditedValuesanecdotes);
    }
  }, [anecdotesData]);

  useEffect(() => {
    if (rankingData && rankingData.length > 0) {
      setEditedValues({
        Delivery: rankingData[0]?.Delivery || '',
        ContentQuality: rankingData[0]?.ContentQuality || '',
        EngagementLevel: rankingData[0]?.EngagementLevel || '',
        Duration: rankingData[0]?.Duration || '',
        rankingfeedback: rankingData[0]?.rankingfeedback || '',
        rankdelivery: rankingData[0]?.rankdelivery || 0,
        rankContentQuality: rankingData[0]?.rankContentQuality || 0,
        rankDuration: rankingData[0]?.rankDuration || 0,
        rankEngagementLevel: rankingData[0]?.rankEngagementLevel || 0,
        totalRankaverage: rankingData[0]?.totalRankaverage || 0
      });
    } else {
      setEditedValues(initialEditedValues);
    }
  }, [rankingData]);

  const closePopup = () => {
    setShowPopup(false);
  };
  const closeAnecdotesPopup = () => {
    setShowAnecdotesPopup(false);
  };

  const closeQuizPopup = () => {
    setShowQuizPopup(false);
  };
  useEffect(() => {
    setIsLoading(true);

    const accessTokenWithQuotes = localStorage.getItem('user');
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');

    const [header, payload] = accessTokenWithoutQuotes.split('.');
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;

    axios
      .get(`${baseURL}/Admin/get-previousVideo/${userid}`, {
        headers: {
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", data[0].learningId)

          setPostData(data);
        } else {
          console.error('Data received is not an array:', responseData);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const offset = currentPage * itemsPerPage;
  const paginatedData = postData.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const extractVideoId = (link) => {
    const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  const saverow = (learningId) => {
    console.log("This Is ClickAble", learningId);
    setIsLoading(true);
    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
    const [header, payload] = accessTokenWithoutQuotes.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;
    axios
      .get(`${baseURL}/Admin/getranking/Completed/learningID/${learningId}/${userid}`, {
        headers: {
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          console.log(">>>>>>>>>>>>>>>", data)
          // setPostData(data);
          setRankingData(data);
          setShowPopup(true);

        } else {
          console.error('Data received is not an array:', responseData);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const updateanecdotes = (learningId, anedotestagsId) => {
    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
    const [header, payload] = accessTokenWithoutQuotes.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;
    setlearningidnew(learningId);
    console.log(">>>>>>>>>>>>>>>?????????????????????<<<<<<<<<<<<<", anedotestagsId);
    setIsLoading(true);
    setShowAnecdotesPopup(true);

    axios
      .get(`${baseURL}/Admin/get/Annedotes/learningID/${learningId}/${userid}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<Id", data[0].AnecdotesId);
          setanecdotesId(data[0].AnecdotesId);

          setAnecdotesData(data);

        } else {
          console.error("Data received is not an array:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });

  }
  const saveVideoRanking = (editedValues) => {
    const id = rankingData[0]?.id || '';

    const updatedData = {
      Delivery: editedValues.Delivery || 0,
      ContentQuality: editedValues.ContentQuality || 0,
      EngagementLevel: editedValues.EngagementLevel || 0,
      Duration: editedValues.Duration || 0,
      rankingfeedback: editedValues.rankingfeedback || '',
      rankdelivery: editedValues.rankdelivery || 0,
      rankContentQuality: editedValues.rankContentQuality || 0,
      rankDuration: editedValues.rankDuration || 0,
      rankEngagementLevel: editedValues.rankEngagementLevel || 0,
      totalRankaverage: editedValues.totalRankaverage || 0

    };

    const accessTokenWithQuotes = localStorage.getItem('user');
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');

    fetch(`${baseURL}/Admin/data/update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        // alert("Data Updated");
        setShowPopup(false);
        NotificationManager.success('Data Updated successfully!', 'Success');

      })
      .catch((error) => {
        // console.error('Error:', error);
        NotificationManager.error(error, 'Error');

      });
  };



  const calculateAverageRating = () => {
    const ratings = [
      parseInt(editedValues.rankdelivery),
      parseInt(editedValues.rankContentQuality),
      parseInt(editedValues.rankEngagementLevel),
      parseInt(editedValues.rankDuration)
    ];

    const totalRating = ratings.reduce((sum, rating) => sum + rating, 0);
    const averageRating = totalRating / ratings.length;

    setEditedValues((prevValues) => ({
      ...prevValues,
      totalRankaverage: averageRating.toString(),
    }));
  };

  useEffect(() => {
    calculateAverageRating();
  }, [
    editedValues.rankdelivery,
    editedValues.rankContentQuality,
    editedValues.rankEngagementLevel,
    editedValues.rankDuration
  ]);

  const saveannecdotes = (editedValues) => {
    const id = anecdotesId
    // const id = rankingData[0]?.AnecdotesId || '';
    console.log("id", id)
    const updatedData = {
      Anedotes1: editedValues.Anedotes1 || '',
      Anedotes2: editedValues.Anedotes2 || '',
      Anedotes3: editedValues.Anedotes3 || '',
      Anedotes4: editedValues.Anedotes4 || '',
      Anedotestime1: editedValues.Anedotestime1 || '',
      Anedotestime2: editedValues.Anedotestime2 || '',
      Anedotestime3: editedValues.Anedotestime3 || '',
      Anedotestime4: editedValues.Anedotestime4 || '',
      tags1: editedValues.tags1 || '',
      tags2: editedValues.tags2 || '',
      tags3: editedValues.tags3 || '',
      tags4: editedValues.tags4 || '',
      tags5: editedValues.tags5 || '',
      tags6: editedValues.tags6 || '',
      tags7: editedValues.tags7 || '',
      tags8: editedValues.tags8 || '',
      tags9: editedValues.tags9 || '',
      tags10: editedValues.tags10 || '',
      suggestedLinks: editedValues.suggestedLinks || '',
      Annecdotesfeedback: editedValues.Annecdotesfeedback || ''



    };

    const accessTokenWithQuotes = localStorage.getItem('user');
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');

    fetch(`${baseURL}/Admin/update/anedotestags/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        // alert("Data Updated");
        NotificationManager.success('Data Updated successfully!', 'Success');
        setShowAnecdotesPopup(false);

      })
      .catch((error) => {
        console.error('Error:', error);
        NotificationManager.error(error, 'Error');

      });
  }

  const savenewanecdotes = (editedValues) => {
    const id = anecdotesId
    // const id = rankingData[0]?.AnecdotesId || '';
    console.log("id", id)

    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
    const [header, payload] = accessTokenWithoutQuotes.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;

    console.log("++++++++++++++++++++++++", learningidnew);
    const updatedData = {
      Anedotes1: editedValues.Anedotes1 || '',
      Anedotes2: editedValues.Anedotes2 || '',
      Anedotes3: editedValues.Anedotes3 || '',
      Anedotes4: editedValues.Anedotes4 || '',
      Anedotestime1: editedValues.Anedotestime1 || '',
      Anedotestime2: editedValues.Anedotestime2 || '',
      Anedotestime3: editedValues.Anedotestime3 || '',
      Anedotestime4: editedValues.Anedotestime4 || '',
      tags1: editedValues.tags1 || '',
      tags2: editedValues.tags2 || '',
      tags3: editedValues.tags3 || '',
      tags4: editedValues.tags4 || '',
      tags5: editedValues.tags5 || '',
      tags6: editedValues.tags6 || '',
      tags7: editedValues.tags7 || '',
      tags8: editedValues.tags8 || '',
      tags9: editedValues.tags9 || '',
      tags10: editedValues.tags10 || '',
      suggestedLinks: editedValues.suggestedLinks || '',
      Annecdotesfeedback: editedValues.Annecdotesfeedback || '',
      learningId: learningidnew,
      userId: userid



    };

    console.log("!!!!!!!!!!!!@#$%^&*()))))))))))))))))", updatedData);



    fetch(`${baseURL}/Admin/post/anedotestags`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "An error occurred");
          });
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.success) {
          // Swal.fire("Data saved successfully!", "", "success");
          // alert("Data saved successfully!");
          NotificationManager.success('Data Updated successfully!', 'Success');
          setShowAnecdotesPopup(false);
        } else {
          alert("DATA_ALREADY_EXISTS", responseData.data.message);

          // Swal.fire({
          //   icon: "error",
          //   title: "Oops...",
          //   text: responseData.data.message,
          // });
        }
      })
      .catch((error) => {
        const errorMessage = "Error saving data: " + error.message;
        alert(errorMessage)
        ;
        NotificationManager.error(errorMessage, 'Error');

        // Swal.fire({
        //   icon: "error",
        //   title: "Oops...",
        //   text: "Error saving data: " + error.message,
        // });
      })
      .finally(() => {
        setIsLoading(false);

      });

    // fetch(`${baseURL}/Admin/update/anedotestags/${id}`, {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${accessTokenWithoutQuotes}`,
    //   },
    //   body: JSON.stringify(updatedData),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log('Success:', data);
    //     alert("Data Updated");


    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   });
  }


  const getquizes = (learningId) => {
    console.log(">>>>>>>>>>>>>>>>><<<<<<<<", learningId);
    const accessTokenWithQuotes = localStorage.getItem('user');
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');
    const [header, payload] = accessTokenWithoutQuotes.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;
    setIsLoading(true);

    axios
      .get(`${baseURL}/Admin/getquiz/Completed/${userid}/${learningId}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<", data);
          setquizData(data);
          setShowQuizPopup(true);

        } else {
          console.error("Data received is not an array:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }


  const handleAnswerChange = (id, newAnswer) => {
    setquizData((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, answer: newAnswer} : item))
    );
  };
  const handleAnswerNewChange = (id, newAnswer) => {
    setquizData((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item,QuestionFeedback: newAnswer } : item))
    );
  };
  return (
    <div className="container mt-5">
      <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
        <h3>Previous Video</h3>
      </div>
      {/* <Link to="/AnedotesTagsEdit">
        <button>Edit Anecdotes & Tags</button>
      </Link> &nbsp;&nbsp;&nbsp;
      <Link to="/ViewQuestion">
        <button>View Quiz</button>
      </Link> */}
      {isLoading && (
        <div className="Loader">
          <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
        </div>
      )}
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="table-container" style={{ overflowY: 'auto' }}>
            <table className="table">
              <thead>
                <tr className="table-primary">
                  <th>Video</th>
                  <th>Ranking</th>
                  <th>Anecdotes</th>
                  <th>Quizzes</th>


                </tr>
              </thead>
              <tbody>
                {paginatedData.map((dataItem) => (
                  <tr key={dataItem.learningId}>
                    <td style={{ display: "none" }}>{dataItem.learningId}</td>
                    <td>
                      <div>
                        <iframe
                          width="560"
                          height="315"
                          src={`https://www.youtube.com/embed/${extractVideoId(
                            (`${dataItem.id}-${dataItem.link}`)
                          )}?modestbranding=1`}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </td>

                    <td>
                      <button class="btn btn-primary" onClick={() => saverow(dataItem.id)}>Ranking</button>

                    </td>
                    <td>
                      <button class="btn btn-primary" onClick={() => updateanecdotes(dataItem.id)}> Anecdotes</button>

                    </td>
                    <td>
                      <button class="btn btn-primary" onClick={() => getquizes(dataItem.id)}> Quizes</button>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="pagination-container">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageCount={Math.ceil(postData.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      </div> */}

      {showPopup && rankingData && rankingData.length > 0 && rankingData[0] && (
        <div className="popup">
          <div className="popup-content">
            <button
              className="btn-close text-white"
              onClick={closePopup}
            ></button>
            <br />
            <br />

            <div class="d-flex flex-row mb-3 bg-secondary">
              <div class="p-2">
                <div>
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Ranking
                  </h5>
                  <div>
                    <div className="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Delivery</span>
                      <br />
                      <Rate
                        allowHalf
                        count={5}
                        value={
                          editedValues.rankdelivery !== undefined
                            ? parseInt(editedValues.rankdelivery)
                            : 0
                        }
                        onChange={(value) =>
                          setEditedValues({
                            ...editedValues,
                            rankdelivery: value.toString(),
                          })
                        }
                        character={<i className="fas fa-star" />}
                        style={{ fontSize: 24, color: "yellow" }}
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>
                        Content Quality
                      </span>
                      <br />
                      <Rate
                        allowHalf
                        count={5}
                        value={
                          editedValues.rankContentQuality !== undefined
                            ? parseInt(editedValues.rankContentQuality)
                            : 0
                        }
                        onChange={(value) =>
                          setEditedValues({
                            ...editedValues,
                            rankContentQuality: value.toString(),
                          })
                        }
                        character={<i className="fas fa-star" />}
                        style={{ fontSize: 24, color: "yellow" }}
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>
                        Engagement Level
                      </span>
                      <br />
                      <Rate
                        allowHalf
                        count={5}
                        value={
                          editedValues.rankEngagementLevel !== undefined
                            ? parseInt(editedValues.rankEngagementLevel)
                            : 0
                        }
                        onChange={(value) =>
                          setEditedValues({
                            ...editedValues,
                            rankEngagementLevel: value.toString(),
                          })
                        }
                        character={<i className="fas fa-star" />}
                        style={{ fontSize: 24, color: "yellow" }}
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Duration</span>
                      <br />
                      <Rate
                        allowHalf
                        count={5}
                        value={
                          editedValues.rankDuration !== undefined
                            ? parseInt(editedValues.rankDuration)
                            : 0
                        }
                        onChange={(value) =>
                          setEditedValues({
                            ...editedValues,
                            rankDuration: value.toString(),
                          })
                        }
                        character={<i className="fas fa-star" />}
                        style={{ fontSize: 24, color: "yellow" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="vl"> </div>
              &nbsp;&nbsp;&nbsp;
              <div class="p-2 w-50">
                <div>
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Justification
                  </h5>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Delivery</span>
                    <textarea
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Delivery"
                      value={
                        editedValues.Delivery !== undefined
                          ? editedValues.Delivery
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValues({
                          ...editedValues,
                          Delivery: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Content Quality</span>
                    <textarea
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Content"
                      value={
                        editedValues.ContentQuality !== undefined
                          ? editedValues.ContentQuality
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValues({
                          ...editedValues,
                          ContentQuality: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Engagement Level</span>
                    <textarea
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="level"
                      value={
                        editedValues.EngagementLevel !== undefined
                          ? editedValues.EngagementLevel
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValues({
                          ...editedValues,
                          EngagementLevel: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Duration</span>
                    <textarea
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Duration"
                      value={
                        editedValues.Duration !== undefined
                          ? editedValues.Duration
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValues({
                          ...editedValues,
                          Duration: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="average-ranking bg-primary text-white">
              <h6>Average Ranking: </h6>
              <Rate
                allowHalf
                count={5}
                value={
                  editedValues.totalRankaverage !== undefined
                    ? parseFloat(editedValues.totalRankaverage)
                    : 0
                }
                character={<i className="fas fa-star" />}
                style={{ fontSize: 24, color: "yellow" }}
              />
            </div>
            <center>
              <br />
              <div class="form-outline mb-4">
                <span style={{ fontSize: "15px" }}>Ranking Feedback</span>
                <textarea
                  type="text"
                  id="form1Example1"
                  class="form-control bg-secondary"
                  name="rankingfeedback"
                  value={editedValues.rankingfeedback}
                  onChange={(e) =>
                    setEditedValues({
                      ...editedValues,
                      rankingfeedback: e.target.value,
                    })
                  }
                  required
                  placeholder="Video Ranking Feedback (Optional)"
                  rows="2"
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={() => saveVideoRanking(editedValues)}
              >
                Save
              </button>
            </center>
          </div>
        </div>
      )}


{showAnecdotesPopup && (
  <div className="popup">
    <div className="popup-content">
      <button
        className="btn-close text-white"
        onClick={closeAnecdotesPopup}
      ></button>
      {/* <h6>Quiz name: {selectedvideo}</h6> */}
      <br />
      <br />
      <div class="d-flex flex-row mb-3 bg-secondary">
        <div class="p-2 w-50">
          <div>
            <h5
              className="modal-title text-center"
              id="exampleModalLabel"
            >
              Add Anecdotes
            </h5>
            <form>
              <div>
                <div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>
                      Anecdotes 1
                    </span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Anedotes1"
                      value={
                        editedValuesanecdotes.Anedotes1 !== undefined
                          ? editedValuesanecdotes.Anedotes1
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          Anedotes1: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>
                      Anecdotes 2
                    </span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Anedotes2"
                      value={
                        editedValuesanecdotes.Anedotes2 !== undefined
                          ? editedValuesanecdotes.Anedotes2
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          Anedotes2: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>
                      Anecdotes 3
                    </span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Anedotes3"
                      value={
                        editedValuesanecdotes.Anedotes3 !== undefined
                          ? editedValuesanecdotes.Anedotes3
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          Anedotes3: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>
                      Anecdotes 4
                    </span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Anedotes4"
                      value={
                        editedValuesanecdotes.Anedotes4 !== undefined
                          ? editedValuesanecdotes.Anedotes4
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          Anedotes4: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div class="vl"> </div>
        &nbsp;&nbsp;&nbsp;
        <div class="p-2 w-50">
          <div>
            <h5
              className="modal-title text-center"
              id="exampleModalLabel"
            >
              Add TimeStamp
            </h5>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Anecdotes Time 1</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="Anedotestime1"
                value={
                  editedValuesanecdotes.Anedotestime1 !== undefined
                    ? editedValuesanecdotes.Anedotestime1
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    Anedotestime1: e.target.value,
                  })
                }
                placeholder="MM:SS"
                required
                rows="2"
                maxLength={5}
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Anecdotes Time 2</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="Anedotestime2"
                value={
                  editedValuesanecdotes.Anedotestime2 !== undefined
                    ? editedValuesanecdotes.Anedotestime2
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    Anedotestime2: e.target.value,
                  })
                }
                placeholder="MM:SS"
                required
                rows="2"
                maxLength={5}
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Anedotes Time 3</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="Anedotestime3"
                value={
                  editedValuesanecdotes.Anedotestime3 !== undefined
                    ? editedValuesanecdotes.Anedotestime3
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    Anedotestime3: e.target.value,
                  })
                }
                placeholder="MM:SS"
                required
                rows="2"
                maxLength={5}
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Anedotes Time 4</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="Anedotestime4"
                value={
                  editedValuesanecdotes.Anedotestime4 !== undefined
                    ? editedValuesanecdotes.Anedotestime4
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    Anedotestime4: e.target.value,
                  })
                }
                placeholder="MM:SS"
                required
                rows="2"
                maxLength={5}
              />
            </div>
          </div>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div class="vl"> </div>
        &nbsp;&nbsp;&nbsp;
        <div class="p-2 w-50">
          <div>
            <h5
              className="modal-title text-center"
              id="exampleModalLabel"
            >
              Add Tags
            </h5>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 1</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="tags1"
                value={
                  editedValuesanecdotes.tags1 !== undefined
                    ? editedValuesanecdotes.tags1
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    tags1: e.target.value,
                  })
                }
                required
                rows="2"
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 2</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="tags2"
                value={
                  editedValuesanecdotes.tags2 !== undefined
                    ? editedValuesanecdotes.tags2
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    tags2: e.target.value,
                  })
                }
                required
                rows="2"
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 3</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="tags3"
                value={
                  editedValuesanecdotes.tags3 !== undefined
                    ? editedValuesanecdotes.tags3
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    tags3: e.target.value,
                  })
                }
                required
                rows="2"
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 4</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="tags4"
                value={
                  editedValuesanecdotes.tags4 !== undefined
                    ? editedValuesanecdotes.tags4
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    tags4: e.target.value,
                  })
                }
                required
                rows="2"
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 5</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="tags5"
                value={
                  editedValuesanecdotes.tags5 !== undefined
                    ? editedValuesanecdotes.tags5
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    tags5: e.target.value,
                  })
                }
                required
                rows="2"
              />
            </div>
          </div>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div class="vl"> </div>
        &nbsp;&nbsp;&nbsp;
        <div class="p-2 w-50">
          <div>
            <h5
              className="modal-title text-center"
              id="exampleModalLabel"
            >
              Add Tags
            </h5>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 6</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="tags6"
                value={
                  editedValuesanecdotes.tags6 !== undefined
                    ? editedValuesanecdotes.tags6
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    tags6: e.target.value,
                  })
                }
                required
                rows="2"
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 7</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="tags7"
                value={
                  editedValuesanecdotes.tags7 !== undefined
                    ? editedValuesanecdotes.tags7
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    tags7: e.target.value,
                  })
                }
                required
                rows="2"
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 8</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="tags8"
                value={
                  editedValuesanecdotes.tags8 !== undefined
                    ? editedValuesanecdotes.tags8
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    tags8: e.target.value,
                  })
                }
                required
                rows="2"
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 9</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="tags9"
                value={
                  editedValuesanecdotes.tags9 !== undefined
                    ? editedValuesanecdotes.tags9
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    tags9: e.target.value,
                  })
                }
                required
                rows="2"
              />
            </div>
            <div class="form-outline mb-4">
              <span style={{ fontSize: "15px" }}>Tag 10</span>
              <input
                type="text"
                id="form1Example1"
                class="form-control bg-light"
                name="tags10"
                value={
                  editedValuesanecdotes.tags10 !== undefined
                    ? editedValuesanecdotes.tags10
                    : "NULL"
                }
                onChange={(e) =>
                  setEditedValuesanecdotes({
                    ...editedValuesanecdotes,
                    tags10: e.target.value,
                  })
                }
                required
                rows="2"
              />
            </div>
          </div>
        </div>
      </div>
      <center>
        <div class="form-outline mb-4">
          <span style={{ fontSize: "15px" }}>suggestedLinks</span>
          <input
            type="text"
            id="form1Example1"
            class="form-control bg-secondary"
            name="suggestedLinks"
            value={
              editedValuesanecdotes.suggestedLinks !== undefined
                ? editedValuesanecdotes.suggestedLinks
                : "NULL"
            }
            onChange={(e) =>
              setEditedValuesanecdotes({
                ...editedValuesanecdotes,
                suggestedLinks: e.target.value,
              })
            }
            required
            rows="2"
          />
        </div>
        <div class="form-outline mb-4">
          <span style={{ fontSize: "15px" }}>Anecdotes Feedback</span>
          <input
            type="text"
            id="form1Example1"
            class="form-control bg-secondary"
            name="Annecdotesfeedback"
            value={
              editedValuesanecdotes.Annecdotesfeedback !== undefined
                ? editedValuesanecdotes.Annecdotesfeedback
                : "NULL"
            }
            onChange={(e) =>
              setEditedValuesanecdotes({
                ...editedValuesanecdotes,
                Annecdotesfeedback: e.target.value,
              })
            }
            required
            rows="2"
          />
        </div>
      </center>
      {/* <center>
        <button
          className="btn btn-primary"
          onClick={() => saveannecdotes(editedValuesanecdotes)}

        >
          Save
        </button>
      </center> */}
      <center>
        <button
          className="btn btn-primary"
          onClick={() => savenewanecdotes(editedValuesanecdotes)}

        >
          Save
        </button>
      </center>
    </div>
  </div>

)}



      {showAnecdotesPopup && anecdotesData && anecdotesData.length > 0 && anecdotesData[0] && (
        <div className="popup">
          <div className="popup-content">
            <button
              className="btn-close text-white"
              onClick={closeAnecdotesPopup}
            ></button>
            {/* <h6>Quiz name: {selectedvideo}</h6> */}
            <br />
            <br />
            <div class="d-flex flex-row mb-3 bg-secondary">
              <div class="p-2 w-50">
                <div>
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Add Anecdotes
                  </h5>
                  <form>
                    <div>
                      <div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>
                            Anecdotes 1
                          </span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotes1"
                            value={
                              editedValuesanecdotes.Anedotes1 !== undefined
                                ? editedValuesanecdotes.Anedotes1
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                Anedotes1: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>
                            Anecdotes 2
                          </span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotes2"
                            value={
                              editedValuesanecdotes.Anedotes2 !== undefined
                                ? editedValuesanecdotes.Anedotes2
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                Anedotes2: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>
                            Anecdotes 3
                          </span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotes3"
                            value={
                              editedValuesanecdotes.Anedotes3 !== undefined
                                ? editedValuesanecdotes.Anedotes3
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                Anedotes3: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                        <div class="form-outline mb-4">
                          <span style={{ fontSize: "15px" }}>
                            Anecdotes 4
                          </span>
                          <input
                            type="text"
                            id="form1Example1"
                            class="form-control bg-light"
                            name="Anedotes4"
                            value={
                              editedValuesanecdotes.Anedotes4 !== undefined
                                ? editedValuesanecdotes.Anedotes4
                                : "NULL"
                            }
                            onChange={(e) =>
                              setEditedValuesanecdotes({
                                ...editedValuesanecdotes,
                                Anedotes4: e.target.value,
                              })
                            }
                            required
                            rows="2"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="vl"> </div>
              &nbsp;&nbsp;&nbsp;
              <div class="p-2 w-50">
                <div>
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Add TimeStamp
                  </h5>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Anecdotes Time 1</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Anedotestime1"
                      value={
                        editedValuesanecdotes.Anedotestime1 !== undefined
                          ? editedValuesanecdotes.Anedotestime1
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          Anedotestime1: e.target.value,
                        })
                      }
                      placeholder="MM:SS"
                      required
                      rows="2"
                      maxLength={5}
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Anecdotes Time 2</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Anedotestime2"
                      value={
                        editedValuesanecdotes.Anedotestime2 !== undefined
                          ? editedValuesanecdotes.Anedotestime2
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          Anedotestime2: e.target.value,
                        })
                      }
                      placeholder="MM:SS"
                      required
                      rows="2"
                      maxLength={5}
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Anedotes Time 3</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Anedotestime3"
                      value={
                        editedValuesanecdotes.Anedotestime3 !== undefined
                          ? editedValuesanecdotes.Anedotestime3
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          Anedotestime3: e.target.value,
                        })
                      }
                      placeholder="MM:SS"
                      required
                      rows="2"
                      maxLength={5}
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Anedotes Time 4</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="Anedotestime4"
                      value={
                        editedValuesanecdotes.Anedotestime4 !== undefined
                          ? editedValuesanecdotes.Anedotestime4
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          Anedotestime4: e.target.value,
                        })
                      }
                      placeholder="MM:SS"
                      required
                      rows="2"
                      maxLength={5}
                    />
                  </div>
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="vl"> </div>
              &nbsp;&nbsp;&nbsp;
              <div class="p-2 w-50">
                <div>
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Add Tags
                  </h5>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Tag 1</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="tags1"
                      value={
                        editedValuesanecdotes.tags1 !== undefined
                          ? editedValuesanecdotes.tags1
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          tags1: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Tag 2</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="tags2"
                      value={
                        editedValuesanecdotes.tags2 !== undefined
                          ? editedValuesanecdotes.tags2
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          tags2: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Tag 3</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="tags3"
                      value={
                        editedValuesanecdotes.tags3 !== undefined
                          ? editedValuesanecdotes.tags3
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          tags3: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Tag 4</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="tags4"
                      value={
                        editedValuesanecdotes.tags4 !== undefined
                          ? editedValuesanecdotes.tags4
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          tags4: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Tag 5</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="tags5"
                      value={
                        editedValuesanecdotes.tags5 !== undefined
                          ? editedValuesanecdotes.tags5
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          tags5: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="vl"> </div>
              &nbsp;&nbsp;&nbsp;
              <div class="p-2 w-50">
                <div>
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Add Tags
                  </h5>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Tag 6</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="tags6"
                      value={
                        editedValuesanecdotes.tags6 !== undefined
                          ? editedValuesanecdotes.tags6
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          tags6: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Tag 7</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="tags7"
                      value={
                        editedValuesanecdotes.tags7 !== undefined
                          ? editedValuesanecdotes.tags7
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          tags7: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Tag 8</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="tags8"
                      value={
                        editedValuesanecdotes.tags8 !== undefined
                          ? editedValuesanecdotes.tags8
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          tags8: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Tag 9</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="tags9"
                      value={
                        editedValuesanecdotes.tags9 !== undefined
                          ? editedValuesanecdotes.tags9
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          tags9: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                  <div class="form-outline mb-4">
                    <span style={{ fontSize: "15px" }}>Tag 10</span>
                    <input
                      type="text"
                      id="form1Example1"
                      class="form-control bg-light"
                      name="tags10"
                      value={
                        editedValuesanecdotes.tags10 !== undefined
                          ? editedValuesanecdotes.tags10
                          : "NULL"
                      }
                      onChange={(e) =>
                        setEditedValuesanecdotes({
                          ...editedValuesanecdotes,
                          tags10: e.target.value,
                        })
                      }
                      required
                      rows="2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <center>
              <div class="form-outline mb-4">
                <span style={{ fontSize: "15px" }}>suggestedLinks</span>
                <input
                  type="text"
                  id="form1Example1"
                  class="form-control bg-secondary"
                  name="suggestedLinks"
                  value={
                    editedValuesanecdotes.suggestedLinks !== undefined
                      ? editedValuesanecdotes.suggestedLinks
                      : "NULL"
                  }
                  onChange={(e) =>
                    setEditedValuesanecdotes({
                      ...editedValuesanecdotes,
                      suggestedLinks: e.target.value,
                    })
                  }
                  required
                  rows="2"
                />
              </div>
              <div class="form-outline mb-4">
                <span style={{ fontSize: "15px" }}>Anecdotes Feedback</span>
                <input
                  type="text"
                  id="form1Example1"
                  class="form-control bg-secondary"
                  name="Annecdotesfeedback"
                  value={
                    editedValuesanecdotes.Annecdotesfeedback !== undefined
                      ? editedValuesanecdotes.Annecdotesfeedback
                      : "NULL"
                  }
                  onChange={(e) =>
                    setEditedValuesanecdotes({
                      ...editedValuesanecdotes,
                      Annecdotesfeedback: e.target.value,
                    })
                  }
                  required
                  rows="2"
                />
              </div>
            </center>
            <center>
              <button
                className="btn btn-primary"
                onClick={() => saveannecdotes(editedValuesanecdotes)}

              >
                Save
              </button>
            </center>
          </div>
        </div>

      )}




      {showQuizPopup && quizData && quizData.length > 0 && quizData[0] && (
        <div className="popup">
          <div className="popup-content">
            <button className="btn-close text-white" onClick={closeQuizPopup}></button>
            <table className="table table-bordered mt-5">
              <thead>
                <tr class="table-primary">
                  <th style={{ display: "none" }}>Quiz ID</th>
                  <th>Quiz Name</th>
                  <th>Answer</th>
                  <th>Feedback</th>
                  <th>Answer</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {quizData.map((dataItem) => (
                  <tr key={dataItem.id}>
                    <td style={{ display: "none" }}>{dataItem.quizId}</td>
                    <td>{dataItem.quizName}</td>
                    <td>
                      {dataItem.options ? (
                        <ul className="list-group">
                          {Object.entries(dataItem.options).map(
                            ([key, value]) => (
                              <li
                                className="list-group-item responsive-text-options"
                                key={key}
                              >
                                <strong>{key}:</strong> {value}
                              </li>
                            )
                          )}
                        </ul>
                      ) : (
                        <div
                          className="alert alert-warning"
                          role="alert"
                        >
                          No options available
                        </div>
                      )}
                    </td>
                    {/* <td>{dataItem.QuestionFeedback}</td> */}
                    <td>
                      {/* {editState[dataItem.id] ? (
                        <textarea
                          className="form-control"
                          value={neweditedFeedback[dataItem.id] || dataItem.QuestionFeedback}
                          onChange={(e) => handleFeedbackChange(dataItem.id, e.target.value)}
                        />
                      ) : (
                        neweditedFeedback[dataItem.id] || dataItem.QuestionFeedback
                        )} */}
                      {editState[dataItem.id] ? (
                        <div>
                          <input
                            type="text"
                            className="form-input"
                            value={dataItem.QuestionFeedback}
                            onChange={(e) => handleAnswerNewChange(dataItem.id, e.target.value)}
                          />
                        </div>
                      ) : (
                        dataItem.QuestionFeedback
                      )}


                    </td>
                    <td>
                      {editState[dataItem.id] ? (
                        <div>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              value="Correct"
                              checked={dataItem.answer === "Correct"}
                              onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
                            />
                            Correct
                          </label>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              value="In-Correct"
                              checked={dataItem.answer === "In-Correct"}
                              onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
                            />
                            In-Correct
                          </label>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              value="Not Sure"
                              checked={dataItem.answer === "Not Sure"}
                              onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
                            />
                            Not-Sure
                          </label>
                        </div>
                      ) : (
                        dataItem.answer
                      )}
                    </td>

                    <td>
                      {editState[dataItem.id] ? (
                        <button
                          // className="btn btn-secondary"
                          onClick={() => handleSave(dataItem.id, dataItem.answer,dataItem.QuestionFeedback)}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          // className="btn btn-secondary"
                          // disabled={parseFloat(dataItem.rankingscore) > 1}
                          onClick={() => handleEdit(dataItem.id)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}


    </div>
  );
}

export default ViewQuestion;




// import React, { useEffect, useState } from "react";
// import ReactPaginate from "react-paginate";
// import { Link } from "react-router-dom";
// import { Bars } from "react-loader-spinner";
// import { Rate } from "antd";
// import { fetchQuizes, updateRankindData, fetchPREVIOUSvideo } from "../../Redux/Action/EditRanking.action";
// import { fetchrankingdata, fetchanecdotesdata } from "../../Redux/Action/RankingData.action";
// import { useDispatch, useSelector } from "react-redux";
// import { updateanecdotesnew } from '../../Redux/Action/EditAnecdotesTags.action';
// import { fetchquizuserwise, updateQuizAnswer } from "../../Redux/Action/quizActions.action";
// import { postannecdotes} from "../../Redux/Action/DatatableQuiz.action";
// import getUserIdFromToken from "../../Utils/JWT/jwt";

// function ViewQuestion() {
//   const [isLoading, setIsLoading] = useState(false);
//   const [postData, setPostData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(0);
//   const itemsPerPage = 5;
//   const [showPopup, setShowPopup] = useState(false);
//   const [rankingData, setRankingData] = useState(null);
//   const [anecdotesData, setAnecdotesData] = useState(null);
//   const [showAnecdotesPopup, setShowAnecdotesPopup] = useState(false);
//   const [showAnecdotesPopupnew, setShowAnecdotesPopupnew] = useState(false);
//   const [learningId, setewlearningId] = useState(null);

//   const [showQuizPopup, setShowQuizPopup] = useState(false);



//   const [quizData, setquizData] = useState(null);

//   const [editedFeedback, setEditedFeedback] = useState("");
//   const [neweditedFeedback, setNewEditedFeedback] = useState("");

//   const [editState, setEditState] = useState({});




//   const resetAnecdotesValues = () => {
//     setEditedValuesanecdotes({
//       Anedotes1: '',
//       Anedotes2: '',
//       Anedotes3: '',
//       Anedotes4: '',
//       Anedotestime1: '',
//       Anedotestime2: '',
//       Anedotestime3: '',
//       Anedotestime4: '',
//       tags1: '',
//       tags2: '',
//       tags3: '',
//       tags4: '',
//       tags5: '',
//       tags6: '',
//       tags7: '',
//       tags8: '',
//       tags9: '',
//       tags10: '',
//       suggestedLinks: '',
//       Annecdotesfeedback: '',
//     });
//   };
//   const handleFeedbackChange = (id, value) => {
//     console.log(">>>>>>>>>>>>>>>>", value);
//     setEditedFeedback((prev) => ({ ...prev, [id]: value }));
//     const questionFeedback = editedFeedback[Object.keys(editedFeedback)];
//     console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&7", value);
//     setNewEditedFeedback(questionFeedback)
//     setNewEditedFeedback((prev) => ({ ...prev, [id]: value }));
//   };


//   const handleEdit = (id) => {
//     setEditState((prevState) => ({
//       ...prevState,
//       [id]: true,
//     }));
//   };

//   const handleSave = (id, answer, QuestionFeedback) => {
//     dispatch(updateQuizAnswer(id, answer, QuestionFeedback));

//     setEditState((prevState) => ({
//       ...prevState,
//       [id]: false,
//     }));
//   };

//   const initialEditedValues = {
//     id: '',
//     Delivery: '',
//     ContentQuality: '',
//     EngagementLevel: '',
//     Duration: '',
//     rankingfeedback: '',
//     rankdelivery: 0,
//     rankContentQuality: 0,
//     rankDuration: 0,
//     rankEngagementLevel: 0,
//     totalRankaverage: 0

//   };

//   const initialEditedValuesanecdotes = {
//     AnecdotesId: "",
//     Anedotes1: "",
//     Anedotes2: "",
//     Anedotes3: "",
//     Anedotes4: "",
//     Anedotestime1: "",
//     Anedotestime2: "",
//     Anedotestime3: "",
//     Anedotestime4: "",
//     tags1: "",
//     tags2: "",
//     tags3: "",
//     tags4: "",
//     tags5: "",
//     tags6: "",
//     tags7: "",
//     tags8: "",
//     tags9: "",
//     tags10: "",
//     suggestedLinks: "",
//     Annecdotesfeedback: ""

//   };

//   const initialEditedValuesQuiz = {
//     answer: "",
//     quizId: "",
//     QuestionFeedback: ""

//   };

//   const [editedValues, setEditedValues] = useState(initialEditedValues);
//   const [editedValuesanecdotes, setEditedValuesanecdotes] = useState(initialEditedValues);

//   const [editedValuesquiz, setEditedValuesquiz] = useState(initialEditedValues);

//   useEffect(() => {
//     if (quizData && quizData.length > 0) {
//       setEditedValuesquiz({
//         answer: quizData[0]?.answer || '',
//         quizId: quizData[0]?.quizId || '',
//         QuestionFeedback: quizData[0]?.QuestionFeedback || ''

//       });
//     } else {
//       setEditedValuesquiz(initialEditedValuesQuiz);
//     }
//   }, [quizData]);


//   useEffect(() => {
//     if (anecdotesData && anecdotesData.length > 0) {
//       setEditedValuesanecdotes({
//         AnecdotesId: anecdotesData[0]?.AnecdotesId || '',
//         Anedotes1: anecdotesData[0]?.Anedotes1 || '',
//         Anedotes2: anecdotesData[0]?.Anedotes2 || '',
//         Anedotes3: anecdotesData[0]?.Anedotes3 || '',
//         Anedotes4: anecdotesData[0]?.Anedotes4 || '',
//         Anedotestime1: anecdotesData[0]?.Anedotestime1 || '',
//         Anedotestime2: anecdotesData[0]?.Anedotestime2 || '',
//         Anedotestime3: anecdotesData[0]?.Anedotestime3 || '',
//         Anedotestime4: anecdotesData[0]?.Anedotestime4 || '',
//         tags1: anecdotesData[0]?.tags1 || '',
//         tags2: anecdotesData[0]?.tags2 || '',
//         tags3: anecdotesData[0]?.tags3 || '',
//         tags4: anecdotesData[0]?.tags4 || '',
//         tags5: anecdotesData[0]?.tags5 || '',
//         tags6: anecdotesData[0]?.tags6 || '',
//         tags7: anecdotesData[0]?.tags7 || '',
//         tags8: anecdotesData[0]?.tags8 || '',
//         tags9: anecdotesData[0]?.tags9 || '',
//         tags10: anecdotesData[0]?.tags10 || '',
//         suggestedLinks: anecdotesData[0]?.suggestedLinks || '',
//         Annecdotesfeedback: anecdotesData[0]?.Annecdotesfeedback || '',

//       });
//     } else {
//       setEditedValuesanecdotes(initialEditedValuesanecdotes);
//     }
//   }, [anecdotesData]);

//   useEffect(() => {
//     if (rankingData && rankingData.length > 0) {
//       setEditedValues({
//         id: rankingData[0]?.id || '',
//         Delivery: rankingData[0]?.Delivery || '',
//         ContentQuality: rankingData[0]?.ContentQuality || '',
//         EngagementLevel: rankingData[0]?.EngagementLevel || '',
//         Duration: rankingData[0]?.Duration || '',
//         rankingfeedback: rankingData[0]?.rankingfeedback || '',
//         rankdelivery: rankingData[0]?.rankdelivery || 0,
//         rankContentQuality: rankingData[0]?.rankContentQuality || 0,
//         rankDuration: rankingData[0]?.rankDuration || 0,
//         rankEngagementLevel: rankingData[0]?.rankEngagementLevel || 0,
//         totalRankaverage: rankingData[0]?.totalRankaverage || 0
//       });
//     } else {
//       setEditedValues(initialEditedValues);
//     }
//   }, [rankingData]);

//   const closePopup = () => {
//     setShowPopup(false);
//   };
//   const closeAnecdotesPopup = () => {
//     setShowAnecdotesPopup(false);
//     setShowAnecdotesPopupnew(false)

//   };

//   const closeQuizPopup = () => {
//     setShowQuizPopup(false);
//   };


//   const dispatch = useDispatch();




//   const { error, data, message } = useSelector((state) => state.perviousvideo);

//   const newlearning = data?.length > 0 ? data[0].id : null;

//   // console.log("newlearning++++++++++++++++++++:", newlearning);



//   const pageCount = data.data
//     ? Math.ceil(data.data.length / itemsPerPage)
//     : 1;





//   const offset = currentPage * itemsPerPage;
//   const paginatedData = postData.slice(offset, offset + itemsPerPage);

//   const handlePageClick = ({ selected }) => {
//     setCurrentPage(selected);
//   };

//   const extractVideoId = (link) => {
//     const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
//     return videoIdMatch ? videoIdMatch[1] : null;
//   };


//   const saverow = (learningId) => {
//     dispatch(fetchrankingdata(learningId, setRankingData, setShowPopup));
//   };

//   useEffect(() => {
//     dispatch(fetchPREVIOUSvideo());
//   }, [dispatch]);


//   const updateanecdotes = (learningId) => {
//     dispatch(fetchanecdotesdata(learningId, setAnecdotesData, setShowAnecdotesPopup));
//     console.log("++++++++++++++++++QQQQQQQQQQQQQQQQQQQQQQQQQqq");
//     setShowAnecdotesPopup(true);
//   };

//   const saveVideoRanking = (id, editedValues) => {
//     dispatch(updateRankindData(id, editedValues));
//     console.log("++++++++++++++++++QQQQQQQQQQQQQQQQQQQQQQQQQqq", id);

//   };
//   const calculateAverageRating = () => {
//     const ratings = [
//       parseInt(editedValues.rankdelivery),
//       parseInt(editedValues.rankContentQuality),
//       parseInt(editedValues.rankEngagementLevel),
//       parseInt(editedValues.rankDuration)
//     ];

//     const totalRating = ratings.reduce((sum, rating) => sum + rating, 0);
//     const averageRating = totalRating / ratings.length;

//     setEditedValues((prevValues) => ({
//       ...prevValues,
//       totalRankaverage: averageRating.toString(),
//     }));
//   };

//   useEffect(() => {
//     calculateAverageRating();
//   }, [
//     editedValues.rankdelivery,
//     editedValues.rankContentQuality,
//     editedValues.rankEngagementLevel,
//     editedValues.rankDuration
//   ]);

//   const saveannecdotes = (id, editedValues) => {
//     dispatch(updateanecdotesnew(id, editedValues));
//     console.log("++++++++++++++++++QQQQQQQQQQQQQQQQQQQQQQQQQqq");

//   };
//   const getquizes = (learningId) => {
//     dispatch(fetchquizuserwise(learningId, setquizData, setShowQuizPopup));
//   };

//   const handleAnswerChange = (id, newAnswer) => {
//     setquizData((prevData) =>
//       prevData.map((item) => (item.id === id ? { ...item, answer: newAnswer } : item))
//     );
//   };
//   const handleAnswerNewChange = (id, newAnswer) => {
//     setquizData((prevData) =>
//       prevData.map((item) => (item.id === id ? { ...item, QuestionFeedback: newAnswer } : item))
//     );
//   };


  

//   // const savenewanecdotes = (editedValues) => {
//   //   const id = anecdotesId
//   //   // const id = rankingData[0]?.AnecdotesId || '';
//   //   console.log("id", id)

//   //   const accessTokenWithQuotes = localStorage.getItem("user");
//   //   const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
//   //   const [header, payload] = accessTokenWithoutQuotes.split(".");
//   //   const decodedPayload = JSON.parse(atob(payload));
//   //   const userid = decodedPayload.id;

//   //   console.log("++++++++++++++++++++++++", learningidnew);
//     // const updatedData = {
//     //   Anedotes1: editedValues.Anedotes1 || '',
//     //   Anedotes2: editedValues.Anedotes2 || '',
//     //   Anedotes3: editedValues.Anedotes3 || '',
//     //   Anedotes4: editedValues.Anedotes4 || '',
//     //   Anedotestime1: editedValues.Anedotestime1 || '',
//     //   Anedotestime2: editedValues.Anedotestime2 || '',
//     //   Anedotestime3: editedValues.Anedotestime3 || '',
//     //   Anedotestime4: editedValues.Anedotestime4 || '',
//     //   tags1: editedValues.tags1 || '',
//     //   tags2: editedValues.tags2 || '',
//     //   tags3: editedValues.tags3 || '',
//     //   tags4: editedValues.tags4 || '',
//     //   tags5: editedValues.tags5 || '',
//     //   tags6: editedValues.tags6 || '',
//     //   tags7: editedValues.tags7 || '',
//     //   tags8: editedValues.tags8 || '',
//     //   tags9: editedValues.tags9 || '',
//     //   tags10: editedValues.tags10 || '',
//     //   suggestedLinks: editedValues.suggestedLinks || '',
//     //   Annecdotesfeedback: editedValues.Annecdotesfeedback || '',
//     //   learningId: learningidnew,
//     //   userId: userid



//     // };

//   //   console.log("!!!!!!!!!!!!@#$%^&*()))))))))))))))))", updatedData);



//   //   fetch(`${baseURL}/Admin/post/anedotestags`, {
//   //     method: "POST",
//   //     headers: {
//   //       "Content-Type": "application/json",
//   //       Authorization: `Bearer ${accessTokenWithoutQuotes}`,
//   //     },
//   //     body: JSON.stringify(updatedData),
//   //   })
//   // }

//   const userId = getUserIdFromToken();

//   const savenewanecdotes = () => {
//     const updatedData = {
//       Anedotes1: editedValues.Anedotes1 || '',
//       Anedotes2: editedValues.Anedotes2 || '',
//       Anedotes3: editedValues.Anedotes3 || '',
//       Anedotes4: editedValues.Anedotes4 || '',
//       Anedotestime1: editedValues.Anedotestime1 || '',
//       Anedotestime2: editedValues.Anedotestime2 || '',
//       Anedotestime3: editedValues.Anedotestime3 || '',
//       Anedotestime4: editedValues.Anedotestime4 || '',
//       tags1: editedValues.tags1 || '',
//       tags2: editedValues.tags2 || '',
//       tags3: editedValues.tags3 || '',
//       tags4: editedValues.tags4 || '',
//       tags5: editedValues.tags5 || '',
//       tags6: editedValues.tags6 || '',
//       tags7: editedValues.tags7 || '',
//       tags8: editedValues.tags8 || '',
//       tags9: editedValues.tags9 || '',
//       tags10: editedValues.tags10 || '',
//       suggestedLinks: editedValues.suggestedLinks || '',
//       Annecdotesfeedback: editedValues.Annecdotesfeedback || '',
//       learningId: newlearning,
//       userId:userId
//   };
//     dispatch(postannecdotes(updatedData));


//     // initialEditedValuesanecdotes({
//     //   Anedotes1: "",
//     //   Anedotes2: "",
//     //   Anedotes3: "",
//     //   Anedotes4: "",
//     //   Anedotestime1: "",
//     //   Anedotestime2: "",
//     //   Anedotestime3: "",
//     //   Anedotestime4: "",
//     //   tags1: "",
//     //   tags2: "",
//     //   tags3: "",
//     //   tags4: "",
//     //   tags5: "",
//     //   tags6: "",
//     //   tags7: "",
//     //   tags8: "",
//     //   tags9: "",
//     //   tags10: "",
//     //   suggestedLinks: "",
//     // });

//     // setSelectedvideo(false);
//   }
//   const renderData = () => {
//     const startIndex = currentPage * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;

//     const currentData = data.slice(startIndex, endIndex);

//     return (
//       <>
//         <div className="container mt-5">


//           <Link to={`/MyReviews`}>
//             <img src="/Images/left-arrow.png" height={50} width={50} />
//           </Link>
//           <br /><br />
//           <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
//             <h3>Edit My Reviews</h3>
//           </div>
//           {isLoading && (
//             <div className="Loader">
//               <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
//             </div>
//           )}



//           <div className="row mt-5">
//             <div className="col-md-12">
//               <div className="table-container" style={{ overflowY: 'auto' }}>
//                 <table className="table table-dark">
//                   <thead>
//                     <tr className="table-primary">
//                       <th>Video</th>
//                       <th>Ranking</th>
//                       <th>Anecdotes</th>
//                       <th>Quizzes</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {currentData.map((dataItem) => (
//                       <tr key={dataItem.id}>
//                         <td>
//                           <div>
//                             <iframe
//                               width="560"
//                               height="315"
//                               src={`https://www.youtube.com/embed/${extractVideoId(
//                                 `${dataItem.id}-${dataItem.link}`
//                               )}?modestbranding=1`}
//                               frameBorder="0"
//                               allowFullScreen
//                             ></iframe>
//                           </div>
//                         </td>

//                         <td>
//                           <button class="btn btn-primary" onClick={() => saverow(dataItem.id)}>Ranking</button>

//                         </td>
//                         <td>
//                           <button class="btn btn-primary" onClick={() => updateanecdotes(dataItem.id)}> Anecdotes</button>

//                         </td>
//                         <td>
//                           <button class="btn btn-primary" onClick={() => getquizes(dataItem.id)}> QUIZZES</button>

//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>

//             {showPopup && rankingData && rankingData.length > 0 && rankingData[0] && (
//               <div className="popup">
//                 <div className="popup-content">
//                   <button
//                     className="btn-close text-white"
//                     onClick={closePopup}
//                   ></button>
//                   <br />
//                   <br />

//                   <div class="d-flex flex-row mb-3 bg-secondary">
//                     <div class="p-2">
//                       <div>
//                         <h5
//                           className="modal-title text-center"
//                           id="exampleModalLabel"
//                         >
//                           Ranking
//                         </h5>
//                         <div>
//                           <div className="form-outline mb-4">
//                             <span style={{ fontSize: "15px" }}>Delivery</span>
//                             <br />
//                             <Rate
//                               allowHalf
//                               count={5}
//                               value={
//                                 editedValues.rankdelivery !== undefined
//                                   ? parseInt(editedValues.rankdelivery)
//                                   : 0
//                               }
//                               onChange={(value) =>
//                                 setEditedValues({
//                                   ...editedValues,
//                                   rankdelivery: value.toString(),
//                                 })
//                               }
//                               character={<i className="fas fa-star" />}
//                               style={{ fontSize: 24, color: "yellow" }}
//                             />
//                           </div>

//                           <div className="form-outline mb-4">
//                             <span style={{ fontSize: "15px" }}>
//                               Content Quality
//                             </span>
//                             <br />
//                             <Rate
//                               allowHalf
//                               count={5}
//                               value={
//                                 editedValues.rankContentQuality !== undefined
//                                   ? parseInt(editedValues.rankContentQuality)
//                                   : 0
//                               }
//                               onChange={(value) =>
//                                 setEditedValues({
//                                   ...editedValues,
//                                   rankContentQuality: value.toString(),
//                                 })
//                               }
//                               character={<i className="fas fa-star" />}
//                               style={{ fontSize: 24, color: "yellow" }}
//                             />
//                           </div>

//                           <div className="form-outline mb-4">
//                             <span style={{ fontSize: "15px" }}>
//                               Engagement Level
//                             </span>
//                             <br />
//                             <Rate
//                               allowHalf
//                               count={5}
//                               value={
//                                 editedValues.rankEngagementLevel !== undefined
//                                   ? parseInt(editedValues.rankEngagementLevel)
//                                   : 0
//                               }
//                               onChange={(value) =>
//                                 setEditedValues({
//                                   ...editedValues,
//                                   rankEngagementLevel: value.toString(),
//                                 })
//                               }
//                               character={<i className="fas fa-star" />}
//                               style={{ fontSize: 24, color: "yellow" }}
//                             />
//                           </div>

//                           <div className="form-outline mb-4">
//                             <span style={{ fontSize: "15px" }}>Duration</span>
//                             <br />
//                             <Rate
//                               allowHalf
//                               count={5}
//                               value={
//                                 editedValues.rankDuration !== undefined
//                                   ? parseInt(editedValues.rankDuration)
//                                   : 0
//                               }
//                               onChange={(value) =>
//                                 setEditedValues({
//                                   ...editedValues,
//                                   rankDuration: value.toString(),
//                                 })
//                               }
//                               character={<i className="fas fa-star" />}
//                               style={{ fontSize: 24, color: "yellow" }}
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="vl"> </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="p-2 w-50">
//                       <div>
//                         <h5
//                           className="modal-title text-center"
//                           id="exampleModalLabel"
//                         >
//                           Justification
//                         </h5>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Delivery</span>
//                           <textarea
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Delivery"
//                             value={
//                               editedValues.Delivery !== undefined
//                                 ? editedValues.Delivery
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValues({
//                                 ...editedValues,
//                                 Delivery: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Content Quality</span>
//                           <textarea
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Content"
//                             value={
//                               editedValues.ContentQuality !== undefined
//                                 ? editedValues.ContentQuality
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValues({
//                                 ...editedValues,
//                                 ContentQuality: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Engagement Level</span>
//                           <textarea
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="level"
//                             value={
//                               editedValues.EngagementLevel !== undefined
//                                 ? editedValues.EngagementLevel
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValues({
//                                 ...editedValues,
//                                 EngagementLevel: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Duration</span>
//                           <textarea
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Duration"
//                             value={
//                               editedValues.Duration !== undefined
//                                 ? editedValues.Duration
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValues({
//                                 ...editedValues,
//                                 Duration: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="average-ranking bg-primary text-white">
//                     <h6>Average Ranking: </h6>
//                     <Rate
//                       allowHalf
//                       count={5}
//                       value={
//                         editedValues.totalRankaverage !== undefined
//                           ? parseFloat(editedValues.totalRankaverage)
//                           : 0
//                       }
//                       character={<i className="fas fa-star" />}
//                       style={{ fontSize: 24, color: "yellow" }}
//                     />
//                   </div>
//                   <center>
//                     <br />
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Ranking Feedback</span>
//                       <textarea
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-secondary"
//                         name="rankingfeedback"
//                         value={editedValues.rankingfeedback}
//                         onChange={(e) =>
//                           setEditedValues({
//                             ...editedValues,
//                             rankingfeedback: e.target.value,
//                           })
//                         }
//                         required
//                         placeholder="Video Ranking Feedback (Optional)"
//                         rows="2"
//                       />
//                     </div>
//                     <button
//                       className="btn btn-primary"
//                       onClick={() => saveVideoRanking(editedValues.id, editedValues)}
//                     >
//                       Save
//                     </button>
//                   </center>
//                 </div>
//               </div>
//             )}
//             {showAnecdotesPopup && (
//               <div className="popup">
//                 <div className="popup-content">
//                   <button
//                     className="btn-close text-white"
//                     onClick={closeAnecdotesPopup}
//                   ></button>
//                   {/* <h6>Quiz name: {selectedvideo}</h6> */}
//                   <br />
//                   <br />
//                   <div class="d-flex flex-row mb-3 bg-secondary">
//                     <div class="p-2 w-50">
//                       <div>
//                         <h5
//                           className="modal-title text-center"
//                           id="exampleModalLabel"
//                         >
//                           Add Anecdotes
//                         </h5>
//                         <form>
//                           <div>
//                             <div>
//                               <div class="form-outline mb-4">
//                                 <span style={{ fontSize: "15px" }}>
//                                   Anecdotes 1
//                                 </span>
//                                 <input
//                                   type="text"
//                                   id="form1Example1"
//                                   class="form-control bg-light"
//                                   name="Anedotes1"
//                                   value={
//                                     editedValuesanecdotes.Anedotes1 !== undefined
//                                       ? editedValuesanecdotes.Anedotes1
//                                       : "NULL"
//                                   }
//                                   onChange={(e) =>
//                                     setEditedValuesanecdotes({
//                                       ...editedValuesanecdotes,
//                                       Anedotes1: e.target.value,
//                                     })
//                                   }
//                                   required
//                                   rows="2"
//                                 />
//                               </div>
//                               <div class="form-outline mb-4">
//                                 <span style={{ fontSize: "15px" }}>
//                                   Anecdotes 2
//                                 </span>
//                                 <input
//                                   type="text"
//                                   id="form1Example1"
//                                   class="form-control bg-light"
//                                   name="Anedotes2"
//                                   value={
//                                     editedValuesanecdotes.Anedotes2 !== undefined
//                                       ? editedValuesanecdotes.Anedotes2
//                                       : "NULL"
//                                   }
//                                   onChange={(e) =>
//                                     setEditedValuesanecdotes({
//                                       ...editedValuesanecdotes,
//                                       Anedotes2: e.target.value,
//                                     })
//                                   }
//                                   required
//                                   rows="2"
//                                 />
//                               </div>
//                               <div class="form-outline mb-4">
//                                 <span style={{ fontSize: "15px" }}>
//                                   Anecdotes 3
//                                 </span>
//                                 <input
//                                   type="text"
//                                   id="form1Example1"
//                                   class="form-control bg-light"
//                                   name="Anedotes3"
//                                   value={
//                                     editedValuesanecdotes.Anedotes3 !== undefined
//                                       ? editedValuesanecdotes.Anedotes3
//                                       : "NULL"
//                                   }
//                                   onChange={(e) =>
//                                     setEditedValuesanecdotes({
//                                       ...editedValuesanecdotes,
//                                       Anedotes3: e.target.value,
//                                     })
//                                   }
//                                   required
//                                   rows="2"
//                                 />
//                               </div>
//                               <div class="form-outline mb-4">
//                                 <span style={{ fontSize: "15px" }}>
//                                   Anecdotes 4
//                                 </span>
//                                 <input
//                                   type="text"
//                                   id="form1Example1"
//                                   class="form-control bg-light"
//                                   name="Anedotes4"
//                                   value={
//                                     editedValuesanecdotes.Anedotes4 !== undefined
//                                       ? editedValuesanecdotes.Anedotes4
//                                       : "NULL"
//                                   }
//                                   onChange={(e) =>
//                                     setEditedValuesanecdotes({
//                                       ...editedValuesanecdotes,
//                                       Anedotes4: e.target.value,
//                                     })
//                                   }
//                                   required
//                                   rows="2"
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         </form>
//                       </div>
//                     </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="vl"> </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="p-2 w-50">
//                       <div>
//                         <h5
//                           className="modal-title text-center"
//                           id="exampleModalLabel"
//                         >
//                           Add TimeStamp
//                         </h5>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Anecdotes Time 1</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Anedotestime1"
//                             value={
//                               editedValuesanecdotes.Anedotestime1 !== undefined
//                                 ? editedValuesanecdotes.Anedotestime1
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 Anedotestime1: e.target.value,
//                               })
//                             }
//                             placeholder="MM:SS"
//                             required
//                             rows="2"
//                             maxLength={5}
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Anecdotes Time 2</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Anedotestime2"
//                             value={
//                               editedValuesanecdotes.Anedotestime2 !== undefined
//                                 ? editedValuesanecdotes.Anedotestime2
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 Anedotestime2: e.target.value,
//                               })
//                             }
//                             placeholder="MM:SS"
//                             required
//                             rows="2"
//                             maxLength={5}
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Anedotes Time 3</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Anedotestime3"
//                             value={
//                               editedValuesanecdotes.Anedotestime3 !== undefined
//                                 ? editedValuesanecdotes.Anedotestime3
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 Anedotestime3: e.target.value,
//                               })
//                             }
//                             placeholder="MM:SS"
//                             required
//                             rows="2"
//                             maxLength={5}
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Anedotes Time 4</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Anedotestime4"
//                             value={
//                               editedValuesanecdotes.Anedotestime4 !== undefined
//                                 ? editedValuesanecdotes.Anedotestime4
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 Anedotestime4: e.target.value,
//                               })
//                             }
//                             placeholder="MM:SS"
//                             required
//                             rows="2"
//                             maxLength={5}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="vl"> </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="p-2 w-50">
//                       <div>
//                         <h5
//                           className="modal-title text-center"
//                           id="exampleModalLabel"
//                         >
//                           Add Tags
//                         </h5>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 1</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags1"
//                             value={
//                               editedValuesanecdotes.tags1 !== undefined
//                                 ? editedValuesanecdotes.tags1
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags1: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 2</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags2"
//                             value={
//                               editedValuesanecdotes.tags2 !== undefined
//                                 ? editedValuesanecdotes.tags2
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags2: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 3</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags3"
//                             value={
//                               editedValuesanecdotes.tags3 !== undefined
//                                 ? editedValuesanecdotes.tags3
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags3: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 4</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags4"
//                             value={
//                               editedValuesanecdotes.tags4 !== undefined
//                                 ? editedValuesanecdotes.tags4
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags4: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 5</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags5"
//                             value={
//                               editedValuesanecdotes.tags5 !== undefined
//                                 ? editedValuesanecdotes.tags5
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags5: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="vl"> </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="p-2 w-50">
//                       <div>
//                         <h5
//                           className="modal-title text-center"
//                           id="exampleModalLabel"
//                         >
//                           Add Tags
//                         </h5>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 6</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags6"
//                             value={
//                               editedValuesanecdotes.tags6 !== undefined
//                                 ? editedValuesanecdotes.tags6
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags6: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 7</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags7"
//                             value={
//                               editedValuesanecdotes.tags7 !== undefined
//                                 ? editedValuesanecdotes.tags7
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags7: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 8</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags8"
//                             value={
//                               editedValuesanecdotes.tags8 !== undefined
//                                 ? editedValuesanecdotes.tags8
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags8: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 9</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags9"
//                             value={
//                               editedValuesanecdotes.tags9 !== undefined
//                                 ? editedValuesanecdotes.tags9
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags9: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 10</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags10"
//                             value={
//                               editedValuesanecdotes.tags10 !== undefined
//                                 ? editedValuesanecdotes.tags10
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags10: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <center>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>suggestedLinks</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-secondary"
//                         name="suggestedLinks"
//                         value={
//                           editedValuesanecdotes.suggestedLinks !== undefined
//                             ? editedValuesanecdotes.suggestedLinks
//                             : "NULL"
//                         }
//                         onChange={(e) =>
//                           setEditedValuesanecdotes({
//                             ...editedValuesanecdotes,
//                             suggestedLinks: e.target.value,
//                           })
//                         }
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Anecdotes Feedback</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-secondary"
//                         name="Annecdotesfeedback"
//                         value={
//                           editedValuesanecdotes.Annecdotesfeedback !== undefined
//                             ? editedValuesanecdotes.Annecdotesfeedback
//                             : "NULL"
//                         }
//                         onChange={(e) =>
//                           setEditedValuesanecdotes({
//                             ...editedValuesanecdotes,
//                             Annecdotesfeedback: e.target.value,
//                           })
//                         }
//                         required
//                         rows="2"
//                       />
//                     </div>
//                   </center>
//                   {/* <center>
//               <button
//                 className="btn btn-primary"
//                 onClick={() => saveannecdotes(editedValuesanecdotes)}

//               >
//                 Save
//               </button>
//             </center> */}
//                   <center>
//                     <button
//                       className="btn btn-primary"
//                       onClick={() => savenewanecdotes()}

//                     >
//                       Save
//                     </button>
//                   </center>
//                 </div>
//               </div>

//             )}




//             {showAnecdotesPopup && anecdotesData && anecdotesData.length > 0 && anecdotesData[0] && (
//               <div className="popup">
//                 <div className="popup-content">
//                   <button
//                     className="btn-close text-white"
//                     onClick={closeAnecdotesPopup}
//                   ></button>
//                   <br />
//                   <br />
//                   <div class="d-flex flex-row mb-3 bg-secondary">
//                     <div class="p-2 w-50">
//                       <div>
//                         <h5
//                           className="modal-title text-center"
//                           id="exampleModalLabel"
//                         >
//                           Add Anecdotes
//                         </h5>
//                         <form>
//                           <div>
//                             <div>
//                               <div class="form-outline mb-4">
//                                 <span style={{ fontSize: "15px" }}>
//                                   Anecdotes 1
//                                 </span>
//                                 <input
//                                   type="text"
//                                   id="form1Example1"
//                                   class="form-control bg-light"
//                                   name="Anedotes1"
//                                   value={
//                                     editedValuesanecdotes.Anedotes1 !== undefined
//                                       ? editedValuesanecdotes.Anedotes1
//                                       : "NULL"
//                                   }
//                                   onChange={(e) =>
//                                     setEditedValuesanecdotes({
//                                       ...editedValuesanecdotes,
//                                       Anedotes1: e.target.value,
//                                     })
//                                   }
//                                   required
//                                   rows="2"
//                                 />
//                               </div>
//                               <div class="form-outline mb-4">
//                                 <span style={{ fontSize: "15px" }}>
//                                   Anecdotes 2
//                                 </span>
//                                 <input
//                                   type="text"
//                                   id="form1Example1"
//                                   class="form-control bg-light"
//                                   name="Anedotes2"
//                                   value={
//                                     editedValuesanecdotes.Anedotes2 !== undefined
//                                       ? editedValuesanecdotes.Anedotes2
//                                       : "NULL"
//                                   }
//                                   onChange={(e) =>
//                                     setEditedValuesanecdotes({
//                                       ...editedValuesanecdotes,
//                                       Anedotes2: e.target.value,
//                                     })
//                                   }
//                                   required
//                                   rows="2"
//                                 />
//                               </div>
//                               <div class="form-outline mb-4">
//                                 <span style={{ fontSize: "15px" }}>
//                                   Anecdotes 3
//                                 </span>
//                                 <input
//                                   type="text"
//                                   id="form1Example1"
//                                   class="form-control bg-light"
//                                   name="Anedotes3"
//                                   value={
//                                     editedValuesanecdotes.Anedotes3 !== undefined
//                                       ? editedValuesanecdotes.Anedotes3
//                                       : "NULL"
//                                   }
//                                   onChange={(e) =>
//                                     setEditedValuesanecdotes({
//                                       ...editedValuesanecdotes,
//                                       Anedotes3: e.target.value,
//                                     })
//                                   }
//                                   required
//                                   rows="2"
//                                 />
//                               </div>
//                               <div class="form-outline mb-4">
//                                 <span style={{ fontSize: "15px" }}>
//                                   Anecdotes 4
//                                 </span>
//                                 <input
//                                   type="text"
//                                   id="form1Example1"
//                                   class="form-control bg-light"
//                                   name="Anedotes4"
//                                   value={
//                                     editedValuesanecdotes.Anedotes4 !== undefined
//                                       ? editedValuesanecdotes.Anedotes4
//                                       : "NULL"
//                                   }
//                                   onChange={(e) =>
//                                     setEditedValuesanecdotes({
//                                       ...editedValuesanecdotes,
//                                       Anedotes4: e.target.value,
//                                     })
//                                   }
//                                   required
//                                   rows="2"
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         </form>
//                       </div>
//                     </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="vl"> </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="p-2 w-50">
//                       <div>
//                         <h5
//                           className="modal-title text-center"
//                           id="exampleModalLabel"
//                         >
//                           Add TimeStamp
//                         </h5>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Anecdotes Time 1</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Anedotestime1"
//                             value={
//                               editedValuesanecdotes.Anedotestime1 !== undefined
//                                 ? editedValuesanecdotes.Anedotestime1
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 Anedotestime1: e.target.value,
//                               })
//                             }
//                             placeholder="MM:SS"
//                             required
//                             rows="2"
//                             maxLength={5}
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Anecdotes Time 2</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Anedotestime2"
//                             value={
//                               editedValuesanecdotes.Anedotestime2 !== undefined
//                                 ? editedValuesanecdotes.Anedotestime2
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 Anedotestime2: e.target.value,
//                               })
//                             }
//                             placeholder="MM:SS"
//                             required
//                             rows="2"
//                             maxLength={5}
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Anedotes Time 3</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Anedotestime3"
//                             value={
//                               editedValuesanecdotes.Anedotestime3 !== undefined
//                                 ? editedValuesanecdotes.Anedotestime3
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 Anedotestime3: e.target.value,
//                               })
//                             }
//                             placeholder="MM:SS"
//                             required
//                             rows="2"
//                             maxLength={5}
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Anedotes Time 4</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="Anedotestime4"
//                             value={
//                               editedValuesanecdotes.Anedotestime4 !== undefined
//                                 ? editedValuesanecdotes.Anedotestime4
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 Anedotestime4: e.target.value,
//                               })
//                             }
//                             placeholder="MM:SS"
//                             required
//                             rows="2"
//                             maxLength={5}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="vl"> </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="p-2 w-50">
//                       <div>
//                         <h5
//                           className="modal-title text-center"
//                           id="exampleModalLabel"
//                         >
//                           Add Tags
//                         </h5>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 1</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags1"
//                             value={
//                               editedValuesanecdotes.tags1 !== undefined
//                                 ? editedValuesanecdotes.tags1
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags1: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 2</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags2"
//                             value={
//                               editedValuesanecdotes.tags2 !== undefined
//                                 ? editedValuesanecdotes.tags2
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags2: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 3</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags3"
//                             value={
//                               editedValuesanecdotes.tags3 !== undefined
//                                 ? editedValuesanecdotes.tags3
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags3: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 4</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags4"
//                             value={
//                               editedValuesanecdotes.tags4 !== undefined
//                                 ? editedValuesanecdotes.tags4
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags4: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 5</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags5"
//                             value={
//                               editedValuesanecdotes.tags5 !== undefined
//                                 ? editedValuesanecdotes.tags5
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags5: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="vl"> </div>
//                     &nbsp;&nbsp;&nbsp;
//                     <div class="p-2 w-50">
//                       <div>
//                         <h5
//                           className="modal-title text-center"
//                           id="exampleModalLabel"
//                         >
//                           Add Tags
//                         </h5>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 6</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags6"
//                             value={
//                               editedValuesanecdotes.tags6 !== undefined
//                                 ? editedValuesanecdotes.tags6
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags6: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 7</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags7"
//                             value={
//                               editedValuesanecdotes.tags7 !== undefined
//                                 ? editedValuesanecdotes.tags7
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags7: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 8</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags8"
//                             value={
//                               editedValuesanecdotes.tags8 !== undefined
//                                 ? editedValuesanecdotes.tags8
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags8: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 9</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags9"
//                             value={
//                               editedValuesanecdotes.tags9 !== undefined
//                                 ? editedValuesanecdotes.tags9
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags9: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                         <div class="form-outline mb-4">
//                           <span style={{ fontSize: "15px" }}>Tag 10</span>
//                           <input
//                             type="text"
//                             id="form1Example1"
//                             class="form-control bg-light"
//                             name="tags10"
//                             value={
//                               editedValuesanecdotes.tags10 !== undefined
//                                 ? editedValuesanecdotes.tags10
//                                 : "NULL"
//                             }
//                             onChange={(e) =>
//                               setEditedValuesanecdotes({
//                                 ...editedValuesanecdotes,
//                                 tags10: e.target.value,
//                               })
//                             }
//                             required
//                             rows="2"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <center>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>suggestedLinks</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-secondary"
//                         name="suggestedLinks"
//                         value={
//                           editedValuesanecdotes.suggestedLinks !== undefined
//                             ? editedValuesanecdotes.suggestedLinks
//                             : "NULL"
//                         }
//                         onChange={(e) =>
//                           setEditedValuesanecdotes({
//                             ...editedValuesanecdotes,
//                             suggestedLinks: e.target.value,
//                           })
//                         }
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Anecdotes Feedback</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-secondary"
//                         name="Annecdotesfeedback"
//                         value={
//                           editedValuesanecdotes.Annecdotesfeedback !== undefined
//                             ? editedValuesanecdotes.Annecdotesfeedback
//                             : "NULL"
//                         }
//                         onChange={(e) =>
//                           setEditedValuesanecdotes({
//                             ...editedValuesanecdotes,
//                             Annecdotesfeedback: e.target.value,
//                           })
//                         }
//                         required
//                         rows="2"
//                       />
//                     </div>
//                   </center>
//                 </div>
//               </div>

//             )}




//             {showQuizPopup && quizData && quizData.length > 0 && quizData[0] && (
//               <div className="popup">
//                 <div className="popup-content">
//                   <button className="btn-close text-white" onClick={closeQuizPopup}></button>
//                   <table className="table table-bordered mt-5">
//                     <thead>
//                       <tr>
//                         <th style={{ display: "none" }}>Quiz ID</th>
//                         <th>Question</th>
//                         <th>Answer</th>
//                         <th>Feedback</th>
//                         <th>Answer</th>
//                         <th>Edit</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {quizData.map((dataItem) => (
//                         <tr key={dataItem.id}>
//                           <td style={{ display: "none" }}>{dataItem.quizId}</td>
//                           <td>{dataItem.quizName}</td>
//                           <td>
//                             {dataItem.options ? (
//                               <ul className="list-group">
//                                 {Object.entries(dataItem.options).map(
//                                   ([key, value]) => (
//                                     <li
//                                       className="list-group-item responsive-text-options"
//                                       key={key}
//                                     >
//                                       <strong>{key}:</strong> {value}
//                                     </li>
//                                   )
//                                 )}
//                               </ul>
//                             ) : (
//                               <div
//                                 className="alert alert-warning"
//                                 role="alert"
//                               >
//                                 No options available
//                               </div>
//                             )}
//                           </td>
//                           {/* <td>{dataItem.QuestionFeedback}</td> */}
//                           <td>
//                             {/* {editState[dataItem.id] ? (
//                         <textarea
//                           className="form-control"
//                           value={neweditedFeedback[dataItem.id] || dataItem.QuestionFeedback}
//                           onChange={(e) => handleFeedbackChange(dataItem.id, e.target.value)}
//                         />
//                       ) : (
//                         neweditedFeedback[dataItem.id] || dataItem.QuestionFeedback
//                         )} */}
//                             {editState[dataItem.id] ? (
//                               <div>
//                                 <input
//                                   type="text"
//                                   className="form-input"
//                                   value={dataItem.QuestionFeedback}
//                                   onChange={(e) => handleAnswerNewChange(dataItem.id, e.target.value)}
//                                 />
//                               </div>
//                             ) : (
//                               dataItem.QuestionFeedback
//                             )}


//                           </td>
//                           <td>
//                             {editState[dataItem.id] ? (
//                               <div>
//                                 <label className="form-check-label">
//                                   <input
//                                     type="radio"
//                                     className="form-check-input"
//                                     value="Correct"
//                                     checked={dataItem.answer === "Correct"}
//                                     onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
//                                   />
//                                   Correct
//                                 </label>
//                                 <label className="form-check-label">
//                                   <input
//                                     type="radio"
//                                     className="form-check-input"
//                                     value="In-Correct"
//                                     checked={dataItem.answer === "In-Correct"}
//                                     onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
//                                   />
//                                   In-Correct
//                                 </label>
//                                 <label className="form-check-label">
//                                   <input
//                                     type="radio"
//                                     className="form-check-input"
//                                     value="Not Sure"
//                                     checked={dataItem.answer === "Not Sure"}
//                                     onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
//                                   />
//                                   Not-Sure
//                                 </label>
//                               </div>
//                             ) : (
//                               dataItem.answer
//                             )}
//                           </td>

//                           <td>
//                             {editState[dataItem.id] ? (
//                               <button
//                                 // className="btn btn-secondary"
//                                 onClick={() => handleSave(dataItem.id, dataItem.answer, dataItem.QuestionFeedback)}
//                               >
//                                 Save
//                               </button>
//                             ) : (
//                               <button
//                                 // className="btn btn-secondary"
//                                 // disabled={parseFloat(dataItem.rankingscore) > 0}
//                                 onClick={() => handleEdit(dataItem.id)}
//                               >
//                                 Edit
//                               </button>
//                             )}
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             )}

//           </div>
//           <div className="pagination-container">
//             <ReactPaginate
//               previousLabel="Previous"
//               nextLabel="Next"
//               pageCount={Math.ceil(data.length / itemsPerPage)}
//               marginPagesDisplayed={2}
//               pageRangeDisplayed={5}
//               onPageChange={handlePageClick}
//               containerClassName="pagination"
//               subContainerClassName="pages pagination"
//               activeClassName="active"
//             />
//           </div>
//         </div>
//       </>
//     );
//   };

//   return (
//     <div>
//       {data.loading && <p>Loading...</p>}
//       {data.error && <p>Error: {data.error}</p>}
//       {data ? renderData() : null}
//     </div>
//   );
// }

// export default ViewQuestion;