import axios from "axios";
import {baseURL} from "../../Config/config"

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = (data) => ({
  type: FETCH_USERS_SUCCESS,
  payload: data,
});

export const fetchUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  payload: error,
});

export const fetchUsers = () => async (dispatch) => {
  dispatch(fetchUsersRequest());

  try {
    const response = await axios.get(
      `${baseURL}/Admin/getquizes/Total/Videos`
    );
    dispatch(fetchUsersSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchUsersFailure(error.message));
  }
};

export const POST_RANKING_REQUEST = "POST_RANKING_REQUEST";
export const POST_RANKING_SUCCESS = "POST_RANKING_SUCCESS";
export const POST_RANKING_FAILURE = "POST_RANKING_FAILURE";

export const postrankingRequest = () => ({
  type: POST_RANKING_REQUEST,
});

export const postrankingSuccess = (data) => ({
  type: FETCH_USERS_SUCCESS,
  payload: data,
});

export const postrankingFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  payload: error,
});

// export const postranking = (quiz, id) => {
//   return (dispatch) => {
//     dispatch(postrankingRequest());
//     const accessTokenWithQuotes = localStorage.getItem("user");
//     const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
//     const userId = getUserIdFromToken();
//     const formDataToSave = {
//        quizName: quiz,
//        learningId: id

//       };
//     console.log("))))))", formDataToSave);

//     axios
//       .post(
//         `${baseURL}/Admin/quiz/table`,
//         formDataToSave,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessTokenWithoutQuotes}`,
//           },
//         }
//       )
//       .then((response) => {
//         const responseData = response.data;
//         dispatch(postrankingSuccess(responseData));
//       })
//       .catch((error) => {
//         dispatch(postrankingFailure(error.message));
//       });
//   };
// };
// export const postranking = (postData) => {
//   return (dispatch) => {
//     dispatch(postrankingRequest());
//     const accessTokenWithQuotes = localStorage.getItem("user");
//     const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

//     const formDataToSaveForQuiz = {
//       // quizName: postData.quizName,
//       // answer: postData.answer,
//       // learningId: postData.learningId,
//       // quizId: postData.quizId,
//       quizName: postData.answer,
//       answer: postData.quizId,
//       learningId: postData.learningId,
//       quizId: postData.answer,
//     };
//     console.log(formDataToSaveForQuiz, "+++++++)");
//     axios
//       .post(
//         `${baseURL}/Admin/quiz/table`,
//         formDataToSaveForQuiz,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessTokenWithoutQuotes}`,
//           },
//         }
//       )
//       .then((response) => {
//         const responseData = response.data;
//         dispatch(postrankingSuccess(responseData));
//       })
//       .catch((error) => {
//         dispatch(postrankingFailure(error.message));
//       });
//   };
// };
