// import {
//     FETCH_RANKINGDATA_REQUEST,
//     FETCH_RANKINGDATA_SUCCESS,
//     FETCH_RANKINGDATA_FAILURE
//   } from '../Action/RankingData.action';
  
//   const initialState = {
//     data: [],
//     isLoading: false,
//     error: null,
//   };
  
//   const RankingDataNewReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case FETCH_RANKINGDATA_REQUEST:
//         return {
//           ...state,
//           isLoading: true,
//           error: null,
//         };
//       case FETCH_RANKINGDATA_SUCCESS:
//         return {
//           ...state,
//           isLoading: false,
//           data: action.payload,
//         };
//       case FETCH_RANKINGDATA_FAILURE:
//         return {
//           ...state,
//           isLoading: false,
//           error: action.payload,
//         };
//           default:
//             return state;
//     }
//   };
//   export default RankingDataNewReducer;
import {
    FETCH_RANKINGDATA_REQUEST,
    FETCH_RANKINGDATA_SUCCESS,
    FETCH_RANKINGDATA_FAILURE,
    FETCH_ANECDOTESDATA_REQUEST,
    FETCH_ANECDOTESDATA_SUCCESS,
    FETCH_ANECDOTESDATA_FAILURE
  } from '../Action/RankingData.action';
  
import {
  FETCH_PREVIOUS_REQUEST,
  FETCH_PREVIOUS_SUCCESS,
  FETCH_PREVIOUS_FAILURE
} from '../Action/EditRanking.action';

  const initialStateRankingData = {
    data: [],
    isLoading: false,
    error: null,
  };
  
  const RankingDataNewReducer = (state = initialStateRankingData, action) => {
    switch (action.type) {
      case FETCH_RANKINGDATA_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case FETCH_RANKINGDATA_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case FETCH_RANKINGDATA_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  const initialStateAnecdotesData = {
    data: [],
    isLoading: false,
    error: null,
  };
  
  const AnecdotesDataReducer = (state = initialStateAnecdotesData, action) => {
    switch (action.type) {
      case FETCH_ANECDOTESDATA_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case FETCH_ANECDOTESDATA_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case FETCH_ANECDOTESDATA_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  


  const previousvideoinitstate = {
    data: [],
    isLoading: false,
    error: null,
  };
  const getpreviousvideo = (state = previousvideoinitstate, action) => {
    switch (action.type) {
      case FETCH_PREVIOUS_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case FETCH_PREVIOUS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case FETCH_PREVIOUS_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  

  export {
    RankingDataNewReducer,
    AnecdotesDataReducer,
    getpreviousvideo
  };
  