import React, { useState } from "react";
import "../../Assets/Css/RegisterForm.css";
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { useDispatch} from "react-redux";
import { postraisecallreq } from "../../Redux/Action/RaiseCallRequest.action";

function Risercallequest() {
  const dispatch = useDispatch();

  const [isLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = React.useState({
    reason: "",
    preferTime: "",
    preferNumber: "",
  });

  const handleSubmit = (e) => {
    
    e.preventDefault();

    const validationErrors = {};

    if (!formData.preferTime.trim()) {
      validationErrors.preferTime = "Prefer time  is required";
    }

    if (!formData.preferNumber.trim()) {
      validationErrors.preferNumber = "Phone Number is required";
    }
    if (!formData.reason.trim()) {
      validationErrors.reason = "Reason for Request is required";
    }
    if (Object.keys(validationErrors).length === 0) {
      // setIsLoading(true);
      // axios
      //   .post("${baseURL}/raisecallrequest/", formData, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Accept: "application/json",
      //       Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      //     },
      //   })
      //   .then((response) => {
      //     Swal.fire("Request Send!", "", "success");

      //     setFormData({
      //       reason: "",
      //       preferTime: "",
      //       preferNumber: "",
      //     });
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error.response.data.message);
      //     Swal.fire({
      //       icon: "error",
      //       title: "Oops...",
      //       text: error.response.data.message,
      //     });
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });
      dispatch(postraisecallreq(formData));
      setFormData({
        reason: "",
        preferTime: "",
        preferNumber: "",
      })
      // setIsLoading(false);

    } else {
      setErrors(validationErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "preferNumber") {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      setFormData({ ...formData, [name]: cleanedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };
  return (
    <div className="p-3 mt-5">
      {isLoading && (
        <div className="Loader">
          <Bars
            height={80}
            width={80}
            color="#4fa94d"
            ariaLabel="bars-loading"
          />
        </div>
      )}

      <div className="registration-container mb-5">
        <form className="registration-form">
          <div className="form-group">
            <Link to="/Support">
              <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
              &nbsp;&nbsp;&nbsp;
            </Link>
            <label htmlFor="fullname">Raise Call Request</label>
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="preferTime">Prefer Time:</label>
            <input
              className="input-Role"
              type="datetime-local"
              id="preferTime"
              name="preferTime"
              value={formData.preferTime}
              onChange={handleChange}
            />
            {errors.preferTime && (
              <span className="text-danger">{errors.preferTime}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="preferNumber">Preferred Number:</label>
            <input
              type="number"
              className="input-Role"
              id="preferNumber"
              name="preferNumber"
              value={formData.preferNumber}
              maxLength={10}
              onChange={handleChange}
            />
            {errors.preferNumber && (
              <span className="text-danger">{errors.preferNumber}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="reason">Reason for Request:</label>
            <textarea
              type="text"
              className="input-Role"
              id="reason"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
            />
            {errors.reason && (
              <span className="text-danger">{errors.reason}</span>
            )}
          </div>
          <div className="mt-5">
            <center>
              <button
                type="button"
                // class="btn btn-primary btn-rounded w-25"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </center>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Risercallequest;
