// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchDATACOUNTFGBStaging, fetchDATACOUNTSignalProd } from "../../../Redux/Action/DataCount";
// import { Bars } from "react-loader-spinner";
// import { Link } from "react-router-dom";

// const YourComponent = () => {
//     const dispatch = useDispatch();

//     useEffect(() => {
//         dispatch(fetchDATACOUNTFGBStaging());
//         dispatch(fetchDATACOUNTSignalProd());
//     }, [dispatch]);

//     const { data: tableData, loading: tableLoading, error: tableError } = useSelector((state) => state.datacountstaging);
//     const { data: proddata, loading: prodLoading, error: prodError } = useSelector((state) => state.datacountprod);

//     if (tableLoading || prodLoading) {
//         return <div className="Loader">
//             <Bars
//                 height={80}
//                 width={80}
//                 color="#4fa94d"
//                 ariaLabel="bars-loading"
//             />
//         </div>;
//     }

//     if (tableError || prodError) {
//         return <p>Error: {tableError || prodError}</p>;
//     }

//     if (!tableData || tableData.length === 0 || !proddata || proddata.length === 0) {
//         return <p>No data available.</p>;
//     }

//     const attributeColors = ['#FF0000', '#00FF00', '#468499', '#FFFF00', '#FF00FF', '#81d8d0'];

//     return (
//         <div className="containera mt-2">
//             <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
//                 <div className="row align-items-center">
//                     <div className="col-auto">
//                         <Link to="/DataMasterData">
//                             <img src="/Images/left-arrow-new.png" height={50} width={50} />
//                         </Link>
//                     </div>
//                     <div className="col">
//                         <h3>FGB Staging Data Count</h3>
//                     </div>
//                 </div>
//             </div>
//             <div className="table-container" style={{ overflowY: 'auto' }}>
//                 <table className="table table-bordered table-striped">
//                     <thead className="thead-dark">
//                         <tr>
//                             <th><strong >Attribute</strong></th>
//                             <th><strong>Age5</strong></th>
//                             <th><strong>Age6</strong></th>
//                             <th><strong>Age7</strong></th>
//                             <th><strong>Age8</strong></th>
//                             <th><strong>Age9</strong></th>
//                             <th><strong>Age10</strong></th>
//                             <th><strong>Age11</strong></th>
//                             <th><strong>Age12</strong></th>
//                             <th><strong>Age13</strong></th>
//                             <th><strong>Age14</strong></th>
//                             <th><strong>Total Count Attribute Wise</strong></th>

//                         </tr>
//                     </thead>
//                     <tbody>
//                         {tableData.map((row, index) => (
//                             <tr key={index}>
//                                 <td>
//                                     <b>{row.attribute}</b>
//                                 </td>
//                                 <td> <b>{row.Age_5}</b></td>
//                                 <td> <b>{row.Age_6}</b></td>
//                                 <td><b>{row.Age_7}</b></td>
//                                 <td><b>{row.Age_8}</b></td>
//                                 <td><b>{row.Age_9}</b></td>
//                                 <td><b>{row.Age_10}</b></td>
//                                 <td><b>{row.Age_11}</b></td>
//                                 <td><b>{row.Age_12}</b></td>
//                                 <td><b>{row.Age_13}</b></td>
//                                 <td><b>{row.Age_14}</b></td>
//                                 <td ><b> {row.Total_Count}</b></td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>


//             <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
//                 <h3>Signal Production Data Count</h3>
//             </div>
//             <div className="table-container" style={{ overflowY: 'auto' }}>
//                 <table className="table table-bordered table-striped">
//                     <thead className="thead-dark">
//                         <tr>
//                             <th><strong >Attribute</strong></th>
//                             <th><strong>Age5</strong></th>
//                             <th><strong>Age6</strong></th>
//                             <th><strong>Age7</strong></th>
//                             <th><strong>Age8</strong></th>
//                             <th><strong>Age9</strong></th>
//                             <th><strong>Age10</strong></th>
//                             <th><strong>Age11</strong></th>
//                             <th><strong>Age12</strong></th>
//                             <th><strong>Age13</strong></th>
//                             <th><strong>Age14</strong></th>
//                             <th><strong>Total Count Attribute Wise</strong></th>

//                         </tr>
//                     </thead>
//                     <tbody>
//                         {proddata.map((row, index) => (
//                             <tr key={index}>
//                                 <td>
//                                     <b>{row.attribute}</b>
//                                 </td>
//                                 <td> <b>{row.Age_5}</b></td>
//                                 <td> <b>{row.Age_6}</b></td>
//                                 <td><b>{row.Age_7}</b></td>
//                                 <td><b>{row.Age_8}</b></td>
//                                 <td><b>{row.Age_9}</b></td>
//                                 <td><b>{row.Age_10}</b></td>
//                                 <td><b>{row.Age_11}</b></td>
//                                 <td><b>{row.Age_12}</b></td>
//                                 <td><b>{row.Age_13}</b></td>
//                                 <td><b>{row.Age_14}</b></td>
//                                 <td ><b> {row.Total_Count}</b></td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     );
// };

// export default YourComponent;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDATACOUNTFGBStaging, fetchDATACOUNTSignalProd } from "../../../Redux/Action/DataCount";
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { CSVLink } from 'react-csv';

const YourComponent = () => {
    const dispatch = useDispatch();
    const [csvDataFGB, setCsvDataFGB] = useState([]);
    const [csvDataProd, setCsvDataProd] = useState([]);

    useEffect(() => {
        dispatch(fetchDATACOUNTFGBStaging());
        dispatch(fetchDATACOUNTSignalProd());
    }, [dispatch]);

    const { data: tableData, loading: tableLoading, error: tableError } = useSelector((state) => state.datacountstaging);
    const { data: proddata, loading: prodLoading, error: prodError } = useSelector((state) => state.datacountprod);

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            setCsvDataFGB(tableData);
        }
    }, [tableData]);

    useEffect(() => {
        if (proddata && proddata.length > 0) {
            setCsvDataProd(proddata);
        }
    }, [proddata]);

    if (tableLoading || prodLoading) {
        return (
            <div className="Loader">
                <Bars
                    height={80}
                    width={80}
                    color="#4fa94d"
                    ariaLabel="bars-loading"
                />
            </div>
        );
    }

    if (tableError || prodError) {
        return <p>Error: {tableError || prodError}</p>;
    }

    if (!tableData || tableData.length === 0 || !proddata || proddata.length === 0) {
        return <p>No data available.</p>;
    }

    return (
        <div className="containera mt-2">
            <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <Link to="/DataMasterData">
                            <img src="/Images/left-arrow-new.png" height={50} width={50} alt="Back" />
                        </Link>
                    </div>
                    <div className="col">
                        <h3>FGB Staging Data Count</h3>
                    </div>
                </div>
            </div>
            <div className="table-container" style={{ overflowY: 'auto' }}>
                <CSVLink data={csvDataFGB} filename={"FGB_Staging_Data.csv"} className="btn btn-primary m-2">Download FGB Staging CSV</CSVLink>
                <table className="table table-bordered table-striped">
                    <thead className="thead-dark">
                        <tr>
                            <th><strong>Attribute</strong></th>
                            <th><strong>Age5</strong></th>
                            <th><strong>Age6</strong></th>
                            <th><strong>Age7</strong></th>
                            <th><strong>Age8</strong></th>
                            <th><strong>Age9</strong></th>
                            <th><strong>Age10</strong></th>
                            <th><strong>Age11</strong></th>
                            <th><strong>Age12</strong></th>
                            <th><strong>Age13</strong></th>
                            <th><strong>Age14</strong></th>
                            <th><strong>Total Count Attribute Wise</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <b>{row.attribute}</b>
                                </td>
                                <td><b>{row.Age_5}</b></td>
                                <td><b>{row.Age_6}</b></td>
                                <td><b>{row.Age_7}</b></td>
                                <td><b>{row.Age_8}</b></td>
                                <td><b>{row.Age_9}</b></td>
                                <td><b>{row.Age_10}</b></td>
                                <td><b>{row.Age_11}</b></td>
                                <td><b>{row.Age_12}</b></td>
                                <td><b>{row.Age_13}</b></td>
                                <td><b>{row.Age_14}</b></td>
                                <td><b>{row.Total_Count}</b></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
                <h3>Signal Production Data Count</h3>
            </div>
            <div className="table-container" style={{ overflowY: 'auto' }}>
                <CSVLink data={csvDataProd} filename={"Signal_Production_Data.csv"} className="btn btn-primary m-2">Download Signal Production CSV</CSVLink>
                <table className="table table-bordered table-striped">
                    <thead className="thead-dark">
                        <tr>
                            <th><strong>Attribute</strong></th>
                            <th><strong>Age5</strong></th>
                            <th><strong>Age6</strong></th>
                            <th><strong>Age7</strong></th>
                            <th><strong>Age8</strong></th>
                            <th><strong>Age9</strong></th>
                            <th><strong>Age10</strong></th>
                            <th><strong>Age11</strong></th>
                            <th><strong>Age12</strong></th>
                            <th><strong>Age13</strong></th>
                            <th><strong>Age14</strong></th>
                            <th><strong>Total Count Attribute Wise</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {proddata.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <b>{row.attribute}</b>
                                </td>
                                <td><b>{row.Age_5}</b></td>
                                <td><b>{row.Age_6}</b></td>
                                <td><b>{row.Age_7}</b></td>
                                <td><b>{row.Age_8}</b></td>
                                <td><b>{row.Age_9}</b></td>
                                <td><b>{row.Age_10}</b></td>
                                <td><b>{row.Age_11}</b></td>
                                <td><b>{row.Age_12}</b></td>
                                <td><b>{row.Age_13}</b></td>
                                <td><b>{row.Age_14}</b></td>
                                <td><b>{row.Total_Count}</b></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default YourComponent;
