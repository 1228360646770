import axios from 'axios';
import getUserIdFromToken from '../../Utils/JWT/jwt';
import { baseURL } from "../../Config/config"
import { NotificationManager } from 'react-notifications';



export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';


export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = (data) => ({
  type: FETCH_USERS_SUCCESS,
  payload: data,
});

export const fetchUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  payload: error,
});



export const fetchUsers = () => async (dispatch) => {

  dispatch(fetchUsersRequest());
  const userId = getUserIdFromToken();
  try {
    const response = await axios.get(`${baseURL}/Admin/getquizes/all/data/${userId}`);
    dispatch(fetchUsersSuccess(response.data.data))
  } catch (error) {
    dispatch(fetchUsersFailure(error.message));
  }
};




export const POST_ANECDOTES_REQUEST = "POST_ANECDOTES_REQUEST";
export const POST_ANECDOTES_SUCCESS = "POST_ANECDOTES_SUCCESS";
export const POST_ANECDOTES_FAILURE = "POST_ANECDOTES_FAILURE";

const userId = getUserIdFromToken();
// const token = getToken();

export const postANECDOTESRequest = () => ({
  type: POST_ANECDOTES_REQUEST,
});

export const postANECDOTESSuccess = (data) => ({
  type: POST_ANECDOTES_SUCCESS,
  payload: data,
});

export const postANECDOTESFailure = (error) => ({
  type: POST_ANECDOTES_FAILURE,
  payload: error,
});

export const postannecdotes = (formData) => {
  return (dispatch) => {
    dispatch(postANECDOTESRequest());
    // const userId = getUserIdFromToken();

    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

    fetch(`${baseURL}/Admin/post/anedotestags`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.success) {
          dispatch(postANECDOTESSuccess({ message: "Successfully posted" }));
          NotificationManager.success('Data saved successfully!', 'Success');
        } else {
          NotificationManager.error(responseData.message, 'Error');
        }
      })
      .catch((error) => {
        dispatch(postANECDOTESFailure(error.message))
        NotificationManager.error(error.message, 'Error');
      })
      .finally(() => {
      });
  };
};





export const POST_RANKING_REQUEST = "POST_RANKING_REQUEST";
export const POST_RANKING_SUCCESS = "POST_RANKING_SUCCESS";
export const POST_RANKING_FAILURE = "POST_RANKING_FAILURE";


export const postRANKINGRequest = () => ({
  type: POST_RANKING_REQUEST,
});

export const postRANKINGSuccess = (data) => ({
  type: POST_RANKING_SUCCESS,
  payload: data,
});

export const postRANKINGSFailure = (error) => ({
  type: POST_RANKING_FAILURE,
  payload: error,
});

export const postRANKING = (formData) => {
  return (dispatch) => {
    dispatch(postANECDOTESRequest());

    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

    fetch(`${baseURL}/Admin/add/videoranking`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.success) {
          dispatch(postANECDOTESSuccess({ message: "Successfully posted" }));
          NotificationManager.success('Data saved successfully!', 'Success');
        } else {
          NotificationManager.error(responseData.message, 'Error');
        }
      })
      .catch((error) => {
        dispatch(postANECDOTESFailure(error.message))
        NotificationManager.error(error.message, 'Error');
      })
      .finally(() => {
      });
  };
};






export const POST_QUIZ_REQUEST = "POST_QUIZ_REQUEST";
export const POST_QUIZ_SUCCESS = "POST_QUIZ_SUCCESS";
export const POST_QUIZ_FAILURE = "POST_QUIZ_FAILURE";


export const postQUIZRequest = () => ({
  type: POST_RANKING_REQUEST,
});

export const postQUIZSuccess = (data) => ({
  type: POST_RANKING_SUCCESS,
  payload: data,
});

export const postQUIZFailure = (error) => ({
  type: POST_RANKING_FAILURE,
  payload: error,
});

export const postQUIZ = (formData) => {
  return (dispatch) => {
    dispatch(postQUIZRequest());

    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

    fetch(`${baseURL}/Admin/quiz/table`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.success) {
          dispatch(postQUIZSuccess({ message: "Successfully posted" }));
          // NotificationManager.success('Data saved successfully!', 'Success');
        } else {
          NotificationManager.error(responseData.message, 'Error');
        }
      })
      .catch((error) => {
        dispatch(postQUIZFailure(error.message))
        NotificationManager.error(error.message, 'Error');
      })
      .finally(() => {
      });
  };
};





export const FETCH_REVIEWDATA_REQUEST = 'FETCH_REVIEWDATA_REQUEST';
export const FETCH_REVIEWDATA_SUCCESS = 'FETCH_REVIEWDATA_SUCCESS';
export const FETCH_REVIEWDATA_FAILURE = 'FETCH_REVIEWDATA_FAILURE';


export const fetchREVIEWDATARequest = () => ({
  type: FETCH_REVIEWDATA_REQUEST,
});

export const fetchREVIEWDATASuccess = (data) => ({
  type: FETCH_REVIEWDATA_SUCCESS,
  payload: data,
});

export const fetchREVIEWDATAFailure = (error) => ({
  type: FETCH_REVIEWDATA_FAILURE,
  payload: error,
});

export const fetchREVIEWDATA = (ageGroup, title, setIsLoading) => {
  return (dispatch) => {
    dispatch(fetchREVIEWDATARequest());
    setIsLoading(true);
    fetch(`${baseURL}/Admin/getattributes/all/attributes/${userId}/${ageGroup}/${title}`)
      .then((response) => response.json())
      .then((jsonData) => {
        if (jsonData.success === false && jsonData.message === "No data available.") {
          NotificationManager.error(
            'You have successfully reviewed the videos in this Age Group and Attribute. Please continue with another Age Group or Attribute for further review of videos.',
            'Error',
            20000
          );
        }
        dispatch(fetchREVIEWDATASuccess(jsonData.data || []));
        console.log("++++++++@@@@@@@$$$$%%%%", jsonData.data)

      })

      .catch((error) => {
        dispatch(fetchREVIEWDATAFailure(error.message))
      })
      .finally(() => {
        setIsLoading(false);
      })
  };
};



<NotificationManager />