const getToken = () => {
  const accessTokenWithQuotes = localStorage.getItem("user");

  if (!accessTokenWithQuotes) {
    return null;
  }
  const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

  return accessTokenWithoutQuotes;
};

export default getToken;
