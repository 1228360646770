// // reducers.js
// import {
//     FETCH_REVIEW_REQUEST,
//     FETCH_REVIEW_SUCCESS,
//     FETCH_REVIEW_FAILURE,
//   } from "../Action/MyReview.action";
  
//   const initialState = {
//     loading: false,
//     data: null,
//     error: null,
//   };
  
//   const MyReview = (state = initialState, action) => {
//     switch (action.type) {
//       case FETCH_REVIEW_REQUEST:
//         return { ...state, loading: true, error: null };
//       case FETCH_REVIEW_SUCCESS:
//         return { ...state, loading: false, data: action.payload, error: null };
//       case FETCH_REVIEW_FAILURE:
//         return { ...state, loading: false, data: null, error: action.payload };
//       default:
//         return state;
//     }
//   };
  
//   export default MyReview;
// // reducers.js
import {
    FETCH_REVIEW_REQUEST,
    FETCH_REVIEW_SUCCESS,
    FETCH_REVIEW_FAILURE,
  } from "../Action/MyReview.action";
  
  const initialState = {
    loading: false,
    data: null,
    error: null,
  };
const MyReview = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REVIEW_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_REVIEW_SUCCESS:
        return { ...state, loading: false, data: action.payload, error: null };
      case FETCH_REVIEW_FAILURE:
        console.error("Fetch Review Failure:", action.payload); // Log the error
        return { ...state, loading: false, data: null, error: action.payload };
      default:
        return state;
    }
  };
  export default MyReview;