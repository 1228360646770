import { Outlet } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import "../Assets/Css/AdminDashboard.css";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
const Nav = () => {
  const [isIconVisible, setIsIconVisible] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const navigationRef = useRef(null);
  const mainRef = useRef(null);
  const handleToggle = () => {
    setIsLeftArrow(!isLeftArrow);
    setIsIconVisible(!isIconVisible);
    setIsActive((prevIsActive) => !prevIsActive);
    toggleLeftArrow();
  };
  const activeLink = (event) => {
    const listItems = document.querySelectorAll(".navigation li");
    listItems.forEach((item) => item.classList.remove("hovered"));
    event.target.classList.add("hovered");
  };
  useEffect(() => {
    const listItems = document.querySelectorAll(".navigation li");
    listItems.forEach((item) => {
      item.addEventListener("mouseover", activeLink);
    });
    return () => {
      listItems.forEach((item) => {
        item.removeEventListener("mouseover", activeLink);
      });
    };
  }, []);
  useEffect(() => {
    navigationRef.current.classList.toggle("active", isActive);
    mainRef.current.classList.toggle("active", isActive);
  }, [isActive]);

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("profile");

    navigate("/Signin");
  };
  const accessTokenWithQuotes = localStorage.getItem("user");
  const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

  const [header, payload] = accessTokenWithoutQuotes.split(".");
  const decodedPayload = JSON.parse(atob(payload));
  const role = decodedPayload.role;

  // const toggleLogo = () => {
  //   setIsActive(!isActive);
  //   setIsLeftArrow(false);
  // };

  const [isLeftArrow, setIsLeftArrow] = useState(true);

  const toggleLeftArrow = () => {
    setIsLeftArrow(!isLeftArrow);
  };
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className={`container-a ${isActive ? "active" : ""}`}>
      <div
        className={`navigation ${isActive ? "active" : ""}`}
        ref={navigationRef}
      >
        <ul>
          <center>
            {isLeftArrow ? (
              // <i
              //   className="fa fa-arrow-left"
              // style={{
              //   fontSize: "30px",
              //   marginTop: "15px",
              //   marginLeft: "150px",
              //   cursor: "pointer",
              //   color: "white",
              // }}
              //   onClick={handleToggle}
              // ></i>
              <img src="https://uat-api.signalz.ai/frontend/LeftRigth.svg" alt="SVG Image" onClick={handleToggle}
                style={{
                  fontSize: "30px",
                  marginTop: "15px",
                  marginLeft: "150px",
                  cursor: "pointer",
                  color: "white",
                }} />
            ) : (
              <img src="https://uat-api.signalz.ai/frontend/LeftRigth.svg" alt="SVG Image" onClick={handleToggle}
                style={{
                  fontSize: "30px",
                  marginTop: "15px",
                  marginLeft: "0",
                  cursor: "pointer",
                  color: "white",
                }}
              ></img>
            )}
          </center>
          <img
            src="https://fgb.signalz.ai/Images/mainlogo.png"
            height={isActive ? 20 : 40}
            style={{
              marginTop: "50px",
              marginLeft: isActive ? "110px" : "15px",
            }}
            alt="logo"
          />
          <br />
          <li></li>

          {role === "ADMIN" && (
            <li>
              <Link to={"/"}>
                <a href="#">
                  <span className="icon">
                    <ion-icon name="home-outline"></ion-icon>
                  </span>
                  <span className="title">Dashboard</span>
                </a>
              </Link>
            </li>
          )}

          {role === "EVALUATOR" && (
            <li>
              <Link to={"/Evaluator"}>
                <a href="#">
                  <span className="icon">
                    <ion-icon name="home-outline"></ion-icon>
                  </span>
                  <span className="title">Dashboard</span>
                </a>
              </Link>
            </li>
          )}
          {role === "ADMIN" && (
            <li>
              <Link to="/Adminview">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Admin View"
                >
                  <span className="icon">
                    <ion-icon name="people-outline"></ion-icon>
                  </span>
                  <span className="title">Admin View</span>
                </a>
              </Link>
            </li>
          )}

          {/* {role === "ADMIN" && (
            <li>
              <Link to="/SignalAppMain">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Admin View"
                >
                  <span className="icon">
                    <ion-icon name="people-outline"></ion-icon>
                  </span>
                  <span className="title">Signal App</span>
                </a>
              </Link>
            </li>
          )} */}




          {role === "ADMIN" && (
            <li>
              <Link to="/DataMasterData">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Admin View"
                >
                  <span className="icon">
                    <i
                      class="fa fa-graduation-cap fa-2x"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <span className="title">Master Data</span>
                </a>
              </Link>
            </li>
          )}

          {role === "ADMIN" && (
            <li>
              <Link to="/DataTransferProd">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Data Transfer"
                >
                  <span className="icon">
                    <i
                      class="fa fa-rocket fa-2x"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <span className="title">Data Migration</span>
                </a>
              </Link>
            </li>
          )}
          {role === "FGB" && (
            <li>
              <Link to="/DataTableQuiz">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Review Board"
                >
                  <span className="icon">
                    <i class="fas fa-table-cells-large  fa-2x"></i>
                  </span>
                  <span className="title">Review Board</span>
                </a>
              </Link>
            </li>
          )}
          {role === "FGB" && (
            <li>
              <Link to="/MyReviews">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="My Reviews"
                >
                  <span className="icon">
                    <i class="fa fa-random fa-2x" aria-hidden="true"></i>
                  </span>
                  <span className="title">My Reviews</span>
                </a>
              </Link>
            </li>
          )}
          {role === "FGB" && (
            <li>
              <Link to="/LeadBoard">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Leader Board"
                >
                  <span className="icon">
                    <i
                      class="fa fa-graduation-cap fa-2x"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <span className="title">Leaderboard</span>
                </a>
              </Link>
            </li>
          )}
          {role === "FGB" && (
            <li >
              <Link to="/NoticeBoard">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Alerts"
                >
                  <span className="icon">
                    <i class="fa fa-bell fa-2x" aria-hidden="true"></i>
                  </span>
                  <span className="title">
                    Alerts
                  </span>
                </a>
              </Link>
            </li>
          )}
          {role === "FGB" && (
            <li>
              <Link to="/Referal">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Referal"
                >
                  <span className="icon">
                    <ion-icon name="people-outline"></ion-icon>
                  </span>
                  <span className="title">Referral</span>
                </a>
              </Link>
            </li>
          )}

          {role === "FGB" && (
            <li>
              <Link to="/Support">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Support"
                >
                  <span className="icon">
                    <i
                      class="fa fa-question-circle fa-2x"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <span className="title">Support</span>
                </a>
              </Link>
            </li>
          )}
        </ul>
      </div>

      <div className={`main ${isActive ? "active" : ""}`} ref={mainRef}>
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container-fluid">
            <center>
              {isIconVisible && (
                <>
                  <img src="https://uat-api.signalz.ai/frontend/LeftRigth.svg" alt="L" onClick={handleToggle}
                    className={`fa ${isLeftArrow ? "fa-arrow-left" : "fa-arrow-right"
                      } mobile-only`}
                    style={{
                      fontSize: "30px",
                      cursor: "pointer",
                      color: "black",
                    }} />

                  {/* <i
                    className={`fa ${isLeftArrow ? "fa-arrow-left" : "fa-arrow-right"
                      } mobile-only`}
                    style={{
                      fontSize: "30px",
                      cursor: "pointer",
                      color: "black",
                    }}
                    onClick={handleToggle}
                  ></i> */}
                </>



              )}
            </center>
            <button
              className="navbar-toggler"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            ></button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {role === "ADMIN" && (
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    {/* <a className="nav-link" href="#">
                      AdminDashboard
                    </a> */}
                  </li>
                </ul>
              )}
            </div>

            <div className="d-flex align-items-center">
              <div className="dropdown">
                <a
                  className="dropdown-toggle d-flex align-items-center hidden-arrow"
                  href="#"
                  id="navbarDropdownMenuAvatar"
                  role="button"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="user">
                    <img src="Images/profileNew.gif" alt=""/>
                  </div>
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdownMenuAvatar"
                >
                  <li>
                    <Link to="/Profile">
                      <a className="dropdown-item" href="#">
                        My profile
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Updatepassword">
                      <a className="dropdown-item" href="#">
                        Settings
                      </a>
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={handleLogout}
                      style={{ cursor: "pointer" }}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
              {isModalOpen && (
                <div className="modal">
                  <div className="modal-content">
                    <h2 className="text-primary">Coming Soon....</h2>
                    <button
                      className="modal-close"
                      onClick={closeModal}
                      class="btn btn-primary"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>

        <Outlet />
      </div>

      <NotificationContainer />
    </div>
  );
};

export default Nav;