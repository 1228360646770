import axios from 'axios';
// import getUserIdFromToken from '../Utils/JWT/jwt';
import getUserIdFromToken from '../../Utils/JWT/jwt';
import {baseURL} from "../../Config/config"
import {NotificationManager } from 'react-notifications';

export const FETCH_QUIZES_REQUEST = 'FETCH_QUIZES_REQUEST';
export const FETCH_QUIZES_SUCCESS = 'FETCH_QUIZES_SUCCESS';
export const FETCH_QUIZES_FAILURE = 'FETCH_QUIZES_FAILURE';

export const fetchQuizesRequest = () => ({
  type: FETCH_QUIZES_REQUEST,
});

export const fetchQuizesSuccess = (data) => ({
  type: FETCH_QUIZES_SUCCESS,
  payload: data,
});

export const fetchQuizesFailure = (error) => ({
  type: FETCH_QUIZES_FAILURE,
  payload: error,
});

export const fetchquizuserwise = (learningId,setquizData, setShowQuizPopup) => {
  return (dispatch) => {
    const userId = getUserIdFromToken();

    dispatch(fetchQuizesRequest());
    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
    axios
    .get(`${baseURL}/Admin/getquiz/Completed/${userId}/${learningId}`, {
      headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          dispatch(fetchQuizesSuccess(data));
          setquizData(data);
          setShowQuizPopup(true);
        } else {
          dispatch(fetchQuizesFailure('Data received is not an array'));
        }
      })
      .catch((error) => {
        dispatch(fetchQuizesFailure(error.message));
      });
  };
};




export const UPDATE_QUIZ_ANSWER_REQUEST = 'UPDATE_QUIZ_ANSWER_REQUEST';
export const UPDATE_QUIZ_ANSWER_SUCCESS = 'UPDATE_QUIZ_ANSWER_SUCCESS';
export const UPDATE_QUIZ_ANSWER_FAILURE = 'UPDATE_QUIZ_ANSWER_FAILURE';

export const updateQuizAnswerRequest = () => ({
    type: UPDATE_QUIZ_ANSWER_REQUEST,
  });
  
  export const updateQuizAnswerSuccess = (data) => ({
    type: UPDATE_QUIZ_ANSWER_SUCCESS,
    payload: data,
  });
  
  export const updateQuizAnswerFailure = (error) => ({
    type: UPDATE_QUIZ_ANSWER_FAILURE,
    payload: error,
  });
  
  export const updateQuizAnswer = (quizId, updatedAnswer,QuestionFeedback) => {
    return (dispatch) => {
      dispatch(updateQuizAnswerRequest());
      const accessTokenWithQuotes = localStorage.getItem("user");
      const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
  
      const formDataToSave = { answer: updatedAnswer,QuestionFeedback:QuestionFeedback,quizId:quizId };
  
      axios
        .put(`${baseURL}/Admin/update/question/answer/${quizId}`, formDataToSave, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessTokenWithoutQuotes}`,
          },
        })
        .then((response) => {
          const responseData = response.data;
          dispatch(updateQuizAnswerSuccess(responseData));
          NotificationManager.success('Data Updated', 'Success');

        })
        .catch((error) => {
          dispatch(updateQuizAnswerFailure(error.message));
        });
    };
  };
  
  <NotificationManager/>