
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { fetchtotalvideos } from "../../../Redux/Action/TotalVideo.action";
import { Bars } from "react-loader-spinner";

function CardDetailsPage() {
  const dispatch = useDispatch();
  const totalvideodata = useSelector((state) => state.totalvideos);


  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 5;

  useEffect(() => {
    dispatch(fetchtotalvideos());
  }, [dispatch]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const pageCount = totalvideodata.data
    ? Math.ceil(totalvideodata.data.length / itemsPerPage)
    : 1; 

  const extractVideoId = (link) => {
    const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  const renderData = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = totalvideodata.data.slice(startIndex, endIndex);

    return (
      <div>
        <div className="table-responsive table-container">
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr className="table-primary">
                <th>ID</th>
                <th>Video Link</th>
                <th>Title</th>
                <th>Topic</th>
                <th>Age</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, parentIndex) => (
                <tr
                  key={`child-${item.id}-${item.Topic}-${item.Quiz}-${parentIndex}`}
                >
                  <td className="center-cell">{item.id}</td>
                  <td>
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${extractVideoId(
                        item.link
                      )}?modestbranding=1`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </td>
                  <td>{item.Title}</td>
                  <td>{item.Topic}</td>
                  <td>{item.Age}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <div className="pagination-container align-items-center">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {totalvideodata.loading && <p>Loading...</p>}
      {totalvideodata.error && <p>Error: {totalvideodata.error}</p>}
      {totalvideodata.data ? renderData() : null}
    </div>
  );
}

export default CardDetailsPage;

