import axios from 'axios';
import getUserIdFromToken from '../../Utils/JWT/jwt';
import {baseURL} from "../../Config/config"

export const FETCH_DATACOUNT_REQUEST = 'FETCH_DATACOUNT_REQUEST';
export const FETCH_DATACOUNT_SUCCESS = 'FETCH_DATACOUNT_SUCCESS';
export const FETCH_DATACOUNT_FAILURE = 'FETCH_DATACOUNT_FAILURE';

export const fetchDATACOUNTRequest = () => ({
  type: FETCH_DATACOUNT_REQUEST,
});

export const fetchDATACOUNTSuccess = (data) => ({
  type: FETCH_DATACOUNT_SUCCESS,
  payload: data,
});

export const fetchDATACOUNTFailure = (error) => ({
  type: FETCH_DATACOUNT_FAILURE,
  payload: error,
});

export const fetchDATACOUNTFGBStaging = () => async (dispatch) => {
  dispatch(fetchDATACOUNTRequest());
  try {
    const response = await axios.get(`https://fgb-staging.signalz.ai/Admin/get-count-data-staging`);
    dispatch(fetchDATACOUNTSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchDATACOUNTFailure(error.message));
  }
};



export const FETCH_DATACOUNTSIGANLPROD_REQUEST = 'FETCH_DATACOUNTSIGANLPROD_REQUEST';
export const FETCH_DATACOUNTSIGANLPROD_SUCCESS = 'FETCH_DATACOUNTSIGANLPROD_SUCCESS';
export const FETCH_DATACOUNTSIGANLPROD_FAILURE = 'FETCH_DATACOUNTSIGANLPROD_FAILURE';

export const fetchDATACOUNTSIGANLPRODRequest = () => ({
  type: FETCH_DATACOUNTSIGANLPROD_REQUEST,
});

export const fetchDATACOUNTSIGANLPRODSuccess = (data) => ({
  type: FETCH_DATACOUNTSIGANLPROD_SUCCESS,
  payload: data,
});

export const fetchDATACOUNTSIGANLPRODFailure = (error) => ({
  type: FETCH_DATACOUNTSIGANLPROD_FAILURE,
  payload: error,
});

export const fetchDATACOUNTSignalProd = () => async (dispatch) => {
  dispatch(fetchDATACOUNTSIGANLPRODRequest());
  try {
    const response = await axios.get(`https://signal-prod.signalz.ai/Admin/get-count-data-prod`);
    dispatch(fetchDATACOUNTSIGANLPRODSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchDATACOUNTSIGANLPRODFailure(error.message));
  }
};
