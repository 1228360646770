import axios from 'axios';
// import getUserIdFromToken from '../Utils/JWT/jwt';
import getUserIdFromToken from '../../Utils/JWT/jwt';
import {baseURL} from "../../Config/config"
import {NotificationManager } from 'react-notifications';

export const FETCH_COMPLETEDDATA_REQUEST = 'FETCH_COMPLETEDDATA_REQUEST';
export const FETCH_COMPLETEDDATA_SUCCESS = 'FETCH_COMPLETEDDATA_SUCCESS';
export const FETCH_COMPLETEDDATA_FAILURE = 'FETCH_COMPLETEDDATA_FAILURE';

export const fetchQuizesRequest = () => ({
  type: FETCH_COMPLETEDDATA_REQUEST,
});

export const fetchQuizesSuccess = (data) => ({
  type: FETCH_COMPLETEDDATA_SUCCESS,
  payload: data,
});

export const fetchQuizesFailure = (error) => ({
  type: FETCH_COMPLETEDDATA_FAILURE,
  payload: error,
});

export const fetchQuizes = () => {
  return (dispatch) => {
    const userId = getUserIdFromToken();

    dispatch(fetchQuizesRequest());
    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
    axios
      .get(`${baseURL}/Admin/getranking/Completed/${userId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          dispatch(fetchQuizesSuccess(data));
        } else {
          dispatch(fetchQuizesFailure('Data received is not an array'));
        }
      })
      .catch((error) => {
        dispatch(fetchQuizesFailure(error.message));
      });
  };
};




export const UPDATE_QUIZ_ANSWER_REQUEST = 'UPDATE_QUIZ_ANSWER_REQUEST';
export const UPDATE_QUIZ_ANSWER_SUCCESS = 'UPDATE_QUIZ_ANSWER_SUCCESS';
export const UPDATE_QUIZ_ANSWER_FAILURE = 'UPDATE_QUIZ_ANSWER_FAILURE';

export const updateQuizAnswerRequest = () => ({
    type: UPDATE_QUIZ_ANSWER_REQUEST,
  });
  
  export const updateQuizAnswerSuccess = (data) => ({
    type: UPDATE_QUIZ_ANSWER_SUCCESS,
    payload: data,
  });
  
  export const updateQuizAnswerFailure = (error) => ({
    type: UPDATE_QUIZ_ANSWER_FAILURE,
    payload: error,
  });

  export const updateRankindData = (id, updatedData) => {
    return (dispatch) => {
      dispatch(updateQuizAnswerRequest());
      const accessTokenWithQuotes = localStorage.getItem("user");
      const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
  
      const formDataToSave = {
        Delivery: updatedData.Delivery,
        ContentQuality: updatedData.ContentQuality,
        EngagementLevel: updatedData.EngagementLevel,
        Duration: updatedData.Duration,
        rankdelivery: updatedData.rankdelivery,
        rankContentQuality: updatedData.rankContentQuality,
        rankDuration: updatedData.rankDuration,
        rankEngagementLevel: updatedData.rankEngagementLevel,
        totalRankaverage: updatedData.totalRankaverage,
      };
  
      axios
        .put(`${baseURL}/Admin/data/update/${id}`, formDataToSave, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessTokenWithoutQuotes}`,
          },
        })
        .then((response) => {
          const responseData = response.data;
          dispatch(updateQuizAnswerSuccess(responseData));
          NotificationManager.success('Data Updated', 'Success');

        })
        .catch((error) => {
          dispatch(updateQuizAnswerFailure(error.message));
        });
    };
  };




export const FETCH_PREVIOUS_REQUEST = 'FETCH_PREVIOUS_REQUEST';
export const FETCH_PREVIOUS_SUCCESS = 'FETCH_PREVIOUS_SUCCESS';
export const FETCH_PREVIOUS_FAILURE = 'FETCH_PREVIOUS_FAILURE';

export const fetchPREVIOUSRequest = () => ({
  type: FETCH_PREVIOUS_REQUEST,
});

export const fetchPREVIOUSSuccess = (data) => ({
  type: FETCH_PREVIOUS_SUCCESS,
  payload: data,
});

export const fetchPREVIOUSFailure = (error) => ({
  type: FETCH_PREVIOUS_FAILURE,
  payload: error,
});

export const fetchPREVIOUSvideo = () => {
  return (dispatch) => {
    const userId = getUserIdFromToken();

    dispatch(fetchPREVIOUSRequest());
    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
    axios
    .get(`${baseURL}/Admin/get-previousVideo/${userId}`, {
      headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          console.log("+@!!!!!!!!!!!!!!!!!!!!!!@@@@@@@@@@@@@@@@@@@@@@@@@22",data)
          dispatch(fetchPREVIOUSSuccess(data));
        } else {
          dispatch(fetchPREVIOUSFailure('Data received is not an array'));
        }
      })
      .catch((error) => {
        dispatch(fetchPREVIOUSFailure(error.message));
      });
  };
};
  <NotificationManager/>