
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import {Link } from "react-router-dom";
import {baseURL} from "../../Config/config"


function YourComponent() {
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState([]);
  const [formDataList, setFormDataList] = useState([]);
  const [isEditingList, setIsEditingList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

    const [header, payload] = accessTokenWithoutQuotes.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;

    axios
      .get(`${baseURL}/Admin/getall/anedotestags/${userid}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          setPostData(data);
          const initialFormDataList = data.map((dataItem) => ({
            ...dataItem,
          }));
          const initialEditingList = Array(data.length).fill(false);

          setFormDataList(initialFormDataList);
          setIsEditingList(initialEditingList);
        } else {
          console.error("Data received is not an array:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleInputChange = (event, rowIndex) => {
    const { name, value } = event.target;
    const updatedFormDataList = [...formDataList];
    updatedFormDataList[rowIndex][name] = value;
    setFormDataList(updatedFormDataList);
  };

  const handleSave = (id) => {
    setIsLoading(true);

    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

    const formDataToSave = formDataList.find((item) => item.id === id);

    if (!formDataToSave) {
      console.error("Data not found for id:", id);
      return;
    }

    fetch(`${baseURL}/Admin/update/anedotestags/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(formDataToSave),
    })
      .then((response) => response.json())
      .then((responseData) => {
        Swal.fire("Data Update successfully!", "", "success");
        const updatedEditingList = [...isEditingList];
        const rowIndex = formDataList.findIndex((item) => item.id === id);
        if (rowIndex !== -1) {
          updatedEditingList[rowIndex] = false;
          setIsEditingList(updatedEditingList);
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error saving data: " + error.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEdit = (rowIndex) => {
    if (!isEditingList[rowIndex]) {
      const updatedEditingList = [...isEditingList];
      updatedEditingList[rowIndex] = true;
      setIsEditingList(updatedEditingList);
    }
  };

  const hiddenColumns = ["createdAt", "updatedAt"];

  const editableFields = [
    "id",
    "Anedotes1",
    "Anedotes2",
    "Anedotes3",
    "Anedotes4",
    "Anedotestime1",
    "Anedotestime2",
    "Anedotestime3",
    "Anedotestime4",
    "tags1",
    "tags2",
    "tags3",
    "tags4",
    "tags5",
    "tags6",
    "tags7",
    "tags8",
    "tags9",
    "tags10",
    "suggestedLinks",
  ];
  const itemsPerPage = 2;
  const offset = currentPage * itemsPerPage;
  const paginatedData = postData.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  const youtubeUrl = "https://www.youtube.com/embed/NfYZ1poI67A";

  function getYouTubeVideoId(url) {
    const videoIdMatch = url.match(/\/embed\/([a-zA-Z0-9_-]+)/);
    if (videoIdMatch) {
      return videoIdMatch[1];
    }
    return null;
  }

  const videoId = getYouTubeVideoId(youtubeUrl);
  const isEditableField = (fieldName) => {
    const editableFields = [
      "Anedotes1",
      "Anedotes2",
      "Anedotes3",
      "Anedotes4",
      "Anedotestime1",
      "Anedotestime2",
      "Anedotestime3",
      "Anedotestime4",
      "tags1",
      "tags2",
      "tags3",
      "tags4",
      "tags5",
      "tags6",
      "tags7",
      "tags8",
      "tags9",
      "tags10",
      "suggestedLinks",
    ];
    return editableFields.includes(fieldName);
  };
  const extractVideoId = (Learning_link) => {
    const videoIdMatch = Learning_link.match(/v=([A-Za-z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };
  return (
    <div className="container mt-5">
        <Link to={`/DataTableEdit`}>
          <img src="/Images/left-arrow.png" height={50} width={50} />
        </Link> <br/><br/>
      <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
        <h3>Edit Anecdotes & Tags</h3>
      </div>

      {isLoading && (
        <div className="Loader">
          <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
        <div className="table-container h-75" style={{overflowY: "auto" }}>
          <table className="table table-striped table-light" >
            <thead>
              <tr className="table-primary">
              <th>Id</th>
              <th>Anecdote 1 </th>
              <th>Anecdote 2 </th>
              <th>Anecdote 3 </th>
              <th>Anecdote 4 </th>
                <th>Anecdote Timestamp 1</th>
                <th>Anecdote Timestamp 2</th>
                <th>Anecdote Timestamp 3</th>
                <th>Anecdote Timestamp 4</th>
                <th>Tags 1</th>
                <th>Tags 2</th>
                <th>Tags 3</th>
                <th>Tags 4</th>
                <th>Tags 5</th>
                <th>Tags 6</th>
                <th>Tags 7</th>
                <th>Tags 8</th>
                <th>Tags 9</th>
                <th>Tags 10</th>
                <th>Suggested Links</th>
                <th>Video</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {postData.map((dataItem, index) => (
                <tr key={dataItem.id}>
{editableFields.map((key) => (
  <td key={key}>
    {key !== "id" && isEditingList[index] && isEditableField(key) ? (
      <div style={{ width: "150px" }}>
        <input
          type="text"
          name={key}
          className="form-control"
          value={formDataList[index][key] || ""}
          onChange={(event) => handleInputChange(event, index)}
        />
      </div>
    ) : (
      <span>{formDataList[index][key]}</span>
    )}
  </td>
))}
<td>
<div>
<iframe
  width="560" 
  height="315"
  src={`https://www.youtube.com/embed/${extractVideoId(
(`${dataItem.id}-${dataItem.Learning_link}`)
  )}?modestbranding=1`}
  frameborder="0"
  allowfullscreen
></iframe>
                </div>
</td>
                  <td>
                    {isEditingList[index] ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSave(dataItem.id)}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                      class="btn btn-primary btn-lg" 
                      onClick={() => handleEdit(index)}
                        disabled={parseFloat(dataItem.score) > 0}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>

    </div>
  );
  
}

export default YourComponent;
