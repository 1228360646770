
import React, { useEffect, useState } from "react";
import { fetchSAMPLELEADERBOARD } from "../../Redux/Action/leadboard.action";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../Config/config";
import ReactPaginate from "react-paginate";
import { Link } from 'react-router-dom';

import axios from "axios";
function LeadBoard() {
  const dispatch = useDispatch();
  const { isLoading, error, data, message } = useSelector((state) => state.sampleleaderboard);
  const [selectedUserScore, setSelectedUserScore] = useState();
  const [openpopup, setpopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  console.log("+@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",data);

  const offset = currentPage * itemsPerPage;

  const paginatedData = selectedUserScore ? selectedUserScore.slice(offset, offset + itemsPerPage) : [];

  const [selectedMonth, setSelectedMonth] = useState("02");
  const [selectedYear, setSelectedYear] = useState("2024");
//   const [months, setMonths] = useState([]);

//   useEffect(() => {
//     if (selectedYear === "2023") {
//       setMonths([
//         { value: "02", label: "February" },
//         { value: "03", label: "March" },
//         { value: "04", label: "April" },
//         { value: "05", label: "May" },
//         { value: "06", label: "June" },
//         { value: "07", label: "July" },
//         { value: "08", label: "August" },
//         { value: "09", label: "September" },
//         { value: "10", label: "October" },
//         { value: "11", label: "November" },
//         { value: "12", label: "December" }
//       ]);
//     } else if (selectedYear === "2024") {
//       setMonths([
//         { value: "02", label: "January" },
//         { value: "03", label: "February" },
//         { value: "04", label: "March" },
//         { value: "04", label: "April" },
//         { value: "06", label: "May" },
//         { value: "07", label: "June" },
//         { value: "08", label: "July" },
//         { value: "09", label: "August" },
//         { value: "10", label: "September" },
//         { value: "11", label: "October" },
//         { value: "12", label: "November" },
//         { value: "12", label: "December" }
//       ]);
//     }
//   }, [selectedYear]);
  useEffect(() => {
    dispatch(fetchSAMPLELEADERBOARD(selectedMonth, selectedYear));
  }, [dispatch, selectedMonth, selectedYear]);

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    setSelectedYear(e.target.value);
  };
  const closeQuizPopup = () => {
    setpopup(false);
  };
  const months = [
    { value: "02", label: "February" }
  ];
  // const months = [
  //   { value: "02", label: "January" },
  //   { value: "03", label: "February" },
  //   { value: "04", label: "March" },
  //   { value: "04", label: "April" },
  //   { value: "06", label: "May" },
  //   { value: "07", label: "June" },
  //   { value: "08", label: "July" },
  //   { value: "09", label: "August" },
  //   { value: "10", label: "September" },
  //   { value: "11", label: "October" },
  //   { value: "12", label: "November" },
  //   { value: "12", label: "December" },
  // ];
  const years = [
    { value: "2024", label: "2024" }
  ];


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>

      <div className="card m-2 shadow-5">
      <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
          <div className="row align-items-center">
            <div className="col-auto">
              <Link to="/Adminview">
                <img src="/Images/backbuttonew.png" height={50} width={50} />
              </Link>
            </div>
            <div className="col">
              <h3>Leaderboard</h3>
            </div>
          </div>
        </div>

        {/* <div className="d-flex align-items-center">
          <label htmlFor="monthSelector" className="me-2">Select Month:</label>
          <select
            id="monthSelector"
            onChange={handleMonthChange}
            value={selectedMonth}
            className="form-select w-25"
          >
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>&nbsp;
          <label htmlFor="monthSelector" className="me-2">Select Year:</label>
          <select
            id="monthSelector"
            onChange={handleYearChange}
            value={selectedYear}
            className="form-select w-25"
          >
            {years.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
        </div> */}

      </div>

      <div className="no-data-available"> No data available for the selected month </div></div>;
  }

  const handleScoreClick = async (score) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>> Hi Manish:_", score)
    const accessTokenWithQuotes = localStorage.getItem("profile");
    const accessTokenObject = JSON.parse(accessTokenWithQuotes);

    const username = accessTokenObject.username;
    const userId = accessTokenObject.id;
    const role = accessTokenObject.role;



    console.log(">><@!@@@@@@!!!!!!!!!!!!!!!!!1", username);
    if (username === score) {

      // if(role === "PARENT" || role === "ADMIN"){
      //   console.log("THis is Admin")
      //   setpopup(true);
      // }
      // else{
      //   console.log("This is FGb")
      // }

      const response = await axios.get(`${baseURL}/Admin/get/FGB/Score/${userId}/${selectedYear}/${selectedMonth}`);
      const responseData = response.data;
      if (Array.isArray(responseData.data)) {
        const data = responseData.data;
        setSelectedUserScore(data);
        setpopup(true);
        // setPostData(data);
        console.log(">>>>>>>>>>>>>>>>", data);
      } else {
        console.error('Data received is not an array:', responseData);
      }

      // alert(`Welcome`)
    }
    else {
      alert("Alert: Unauthorized Access - Access to Other FBG Data Prohibited")
    }
  };
  const accessTokenWithQuotes = localStorage.getItem("user");
  const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
  const [header, payload] = accessTokenWithoutQuotes.split(".");
  const decodedPayload = JSON.parse(atob(payload));
  const userId = decodedPayload.id;
  const username = decodedPayload.username;

  // const fetchMyReview = () => async () => {
  //   const accessTokenWithQuotes = localStorage.getItem("user");
  //   const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
  //   const [header, payload] = accessTokenWithoutQuotes.split(".");
  //   const decodedPayload = JSON.parse(atob(payload));
  //   const userId = decodedPayload.id;
  //   const username = decodedPayload.username;
  //   try {
  //     const response = await axios.get(`${baseURL}/Admin/get/FGB/Score/${userId}`);
  //     console.log(">>>>>>>>>>>>>>>>This is the Response Data:", response.data);
  //   } catch (error) {
  //     console.log("<<<<<<<<<<<<<<<>>>>>>>>>>>>>>.. This is an Error ", error);
  //   }
  // };

  // const myReviewFetcher = fetchMyReview();
  // myReviewFetcher();
  const extractVideoId = (link) => {
    const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  if (data) {
    return (
      <div className="card m-2 shadow-5">
        {/* <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">

          <h3>Leaderboard (February)</h3>
        </div> */}
        <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
          <div className="row align-items-center">
            <div className="col-auto">
              <Link to="/Adminview">
                <img src="/Images/left-arrow-new.png" height={50} width={50} />
              </Link>
            </div>
            <div className="col">
              <h3>Leaderboard (April)</h3>
            </div>
          </div>
        </div>

        <div className="table-container" style={{ overflowY: 'auto' }}>


          <table class="table align-middle mb-0 bg-white">
            <thead class="bg-light">
              <tr className="table-primary">
                <th> &nbsp;&nbsp;&nbsp;Name</th>
                <th>Stage</th>
                <th>Points</th>
                <th>Completed Videos</th>

              </tr>
            </thead>
            <tbody>
              {data.map((dataItem) => (
                <tr
                  key={dataItem.username}
                  className={dataItem.username === username ? 'highlighted-row' : ''}
                >                <td>
                    <div class="d-flex align-items-center">
                      <div class="ms-3">
                        <p class="fw-bold mb-1">{dataItem.fullName}</p>
                        <p class="text-muted mb-0">{dataItem.username}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p class="fw-normal mb-1">{dataItem.tier}</p>
                  </td>
                  <td onClick={() => handleScoreClick(dataItem.username)}>
                    <span class="badge badge-success rounded-pill d-inline">
                    {Math.floor(dataItem.score)}
                    </span>
                  </td>
                  <td>
                    <p class="fw-normal mb-1">{dataItem.CompletedVideo}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {openpopup && (
          <div className="popup">
            <div className="popup-content">
              <button
                className="btn-close text-white"
                onClick={closeQuizPopup}
              ></button>
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Video</th>
                      <th>Ranking Score</th>
                      <th>Anecdotes Score</th>
                      <th>Total Score</th>

                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((scoreItem) => (
                      <tr key={scoreItem.userId}>
                        <td>
                          <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${extractVideoId(
                              (`${scoreItem.userId}-${scoreItem.link}`)
                            )}?modestbranding=1`}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>



                        </td>
                        <td>{scoreItem.rankingscore}</td>
                        <td>{scoreItem.score}</td>
                        <td>{scoreItem.total_score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="pagination-container">
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageCount={Math.ceil(selectedUserScore.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return <div className="no-data-available">No data available.</div>;
}

export default LeadBoard;
