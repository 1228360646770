import getUserIdFromToken from "../../Utils/JWT/jwt";
import getToken from "../../Utils/JWT/token";
import {baseURL} from "../../Config/config"
import Swal from "sweetalert2";

export const POST_REFERAL_REQUEST = "POST_REFERAL_REQUEST";
export const POST_REFERAL_SUCCESS = "POST_REFERAL_SUCCESS";
export const POST_REFERAL_FAILURE = "POST_REFERAL_FAILURE";

const userId = getUserIdFromToken();
const token = getToken();

export const updateQuizAnswerRequest = () => ({
  type: POST_REFERAL_REQUEST,
});

export const updateQuizAnswerSuccess = (data) => ({
  type: POST_REFERAL_SUCCESS,
  payload: data,
});

export const updateQuizAnswerFailure = (error) => ({
  type: POST_REFERAL_FAILURE,
  payload: error,
});

export const postreferal = (formData) => {
  return (dispatch) => {
    dispatch(updateQuizAnswerRequest());
    const accessTokenWithQuotes = localStorage.getItem('user');
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');

  formData.userId = userId;

    fetch(`${baseURL}/referfgb/refer/fgb`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          dispatch(updateQuizAnswerSuccess({ message: "Successfully posted" }));
          // alert("data Post ");
          Swal.fire("Request Send!", "", "success");

        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .catch((error) => {
        alert("An Error", error.message);
        dispatch(updateQuizAnswerFailure(error.message));
      });
  };
};
