import axios from 'axios';
import getUserIdFromToken from '../../Utils/JWT/jwt';
import {baseURL} from "../../Config/config"

export const FETCH_REVIEW_REQUEST = 'FETCH_REVIEW_REQUEST';
export const FETCH_REVIEW_SUCCESS = 'FETCH_REVIEW_SUCCESS';
export const FETCH_REVIEW_FAILURE = 'FETCH_REVIEW_FAILURE';

export const fetchMyReviewRequest = () => ({
  type: FETCH_REVIEW_REQUEST,
});

export const fetchMyReviewSuccess = (data) => ({
  type: FETCH_REVIEW_SUCCESS,
  payload: data,
});

export const fetchMyReviewFailure = (error) => ({
  type: FETCH_REVIEW_FAILURE,
  payload: error,
});

export const fetchMyReview = () => async (dispatch) => {
  dispatch(fetchMyReviewRequest());
  const userId = getUserIdFromToken();

  try {
    const response = await axios.get(`${baseURL}/Admin/getquizes/all/count/${userId}`);
    dispatch(fetchMyReviewSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchMyReviewFailure(error.message));
  }
};
