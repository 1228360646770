import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { Bars } from "react-loader-spinner";
import { Link } from 'react-router-dom';
import { baseURL } from "../../Config/config"


function ViewQuestion() {
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 6;
  const [editState, setEditState] = useState({});

  useEffect(() => {
    setIsLoading(true);

    const accessTokenWithQuotes = localStorage.getItem('user');
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');

    const [header, payload] = accessTokenWithoutQuotes.split('.');
    const decodedPayload = JSON.parse(atob(payload));
    const userid = decodedPayload.id;

    axios
      .get(`${baseURL}/Admin/getquiz/Completed/${userid}`, {
        headers: {
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          setPostData(data);
        } else {
          console.error('Data received is not an array:', responseData);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const offset = currentPage * itemsPerPage;
  const paginatedData = postData.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleEdit = (id) => {
    setEditState((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleSave = (id, answer) => {

    setEditState((prevState) => ({
      ...prevState,
      [id]: false,
    }));
    console.log(id, answer, "?????>>>>")

    const accessTokenWithQuotes = localStorage.getItem('user');
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, '');


    const finaldata = {
      answer: answer,
      quizId: id
    }
    fetch(`${baseURL}/Admin/update/question/answer/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(finaldata),
    })
      .then((response) => response.json())
      .then((responseData) => {

      })
      .catch((error) => {
        console.error("Error saving data:", error);

      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleAnswerChange = (id, newAnswer) => {
    setPostData((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, answer: newAnswer } : item))
    );
  };

  return (
    <div className="container mt-5">
      <Link to={`/DataTableEdit`}>
        <img src="/Images/left-arrow.png" height={50} width={50} />         </Link> <br /><br />
      <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
        <h3>Edit Quiz</h3>
      </div>
      {isLoading && (
        <div className="Loader">
          <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
        </div>
      )}
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="table-container" style={{ overflowY: 'auto' }}>
            <table className="table table-striped table-light">
              <thead>
                <tr className="table-primary">
                  <th>Quiz Name</th>
                  <th>Answer</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {/* {paginatedData.map((dataItem) => (
                  <tr key={dataItem.id}>
                    <td style={{display:"none"}}>{dataItem.quizId}</td>
                    <td>{dataItem.quizName}</td>
                    <td>
                      {editState[dataItem.id] ? (
                        <input
                          type="text"
                          value={dataItem.answer}
                          onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
                        />
                      ) : (
                        dataItem.answer
                      )}
                    </td>
                    <td>
                      {editState[dataItem.id] ? (
                        <button className="btn btn-secondary" onClick={() => handleSave(dataItem.id,dataItem.answer)}>
                          Save
                        </button>
                      ) : (
                        <button className="btn btn-secondary" disabled={parseFloat(dataItem.rankingscore) > 0}
                         onClick={() => handleEdit(dataItem.id)}>
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))} */}
                {paginatedData.map((dataItem) => (
                  <tr key={dataItem.id}>
                    <td style={{ display: "none" }}>{dataItem.quizId}</td>
                    <td>{dataItem.quizName}</td>
                    <td>
                      {editState[dataItem.id] ? (
                        <div>
                          <label>
                            <input
                              type="radio"
                              value="Correct"
                              checked={dataItem.answer === "Correct"}
                              onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
                            />
                            Correct
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="In-Correct"
                              checked={dataItem.answer === "In-Correct"}
                              onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
                            />
                            In-Correct
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="none"
                              checked={dataItem.answer === "none"}
                              onChange={(e) => handleAnswerChange(dataItem.id, e.target.value)}
                            />
                            Not-Sure
                          </label>
                        </div>
                      ) : (
                        dataItem.answer
                      )}
                    </td>
                    <td>
                      {editState[dataItem.id] ? (
                        <button
                          className="btn btn-secondary"
                          onClick={() => handleSave(dataItem.id, dataItem.answer)}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn btn-secondary"
                          disabled={parseFloat(dataItem.rankingscore) > 0}
                          onClick={() => handleEdit(dataItem.id)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="pagination-container">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageCount={Math.ceil(postData.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      </div>
    </div>
  );
}

export default ViewQuestion;
