import React, { useState } from "react";
import "../../Assets/Css/RegisterForm.css";
import { Bars } from "react-loader-spinner";
import { postreferal } from "../../Redux/Action/Referal.action";
import { useDispatch} from "react-redux";

function Referal() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const accessTokenWithQuotes = localStorage.getItem("user");
  const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

  const [header, payload] = accessTokenWithoutQuotes.split(".");
  const decodedPayload = JSON.parse(atob(payload));
  const userId = decodedPayload.id;
  const role = decodedPayload.role;
  const nameofUser = decodedPayload.fullName;
  const getname = JSON.parse(localStorage.getItem("profile"));
console.log("+++++++++++++++++!!!!!!!!!!!!!!!!!!!1",getname.fullName);
  const sendername=getname.fullName;
  const [formData, setFormData] = React.useState({
    email: "",
    fullName: "",
    phonenumber: "",
    userId: userId,
    nameofUser:sendername,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!formData.fullName.trim()) {
      validationErrors.fullName = "Full Name is required";
    }

    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is not valid";
    }

    if (!formData.phonenumber.trim()) {
      validationErrors.phonenumber = "Phone Number is required";
    }
    if (Object.keys(validationErrors).length === 0) {
      // setIsLoading(true);
      // axios
      //   .post("${baseURL}/referfgb/refer/fgb", formData, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Accept: "application/json",
      //       Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      //     },
      //   })
      //   .then((response) => {
      //     Swal.fire("Register Done!", response.data.data.fullName, "success");

      //     setFormData({
      //       email: "",
      //       fullName: "",
      //       phonenumber: "",
      //     });
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error.response.data.message);
      //     Swal.fire({
      //       icon: "error",
      //       title: "Oops...",
      //       text: error.response.data.message,
      //     });
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });

      dispatch(postreferal(formData));
      setFormData(
        {
          email: "",
          fullName: "",
          phonenumber: "",
          userId: "",
          nameofUser: "",
        }
      )
    } else {
      setErrors(validationErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  return (
    <div className="p-3">
      {isLoading && (
        <div className="Loader">
          <Bars
            height={80}
            width={80}
            color="#4fa94d"
            ariaLabel="bars-loading"
          />
        </div>
      )}
      <div class="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
        <h3>Referral Details</h3>
      </div>

      <div className="registration-container mb-5">
        <form onSubmit={handleSubmit} className="registration-form">
          <div className="form-group">
            <label htmlFor="fullName">Full Name:</label>
            <input
              className="input-Role"
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
            {errors.fullName && (
              <span className="text-danger">{errors.fullName}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="input-Role"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="phonenumber">Phone Number:</label>
            <input
              type="text"
              className="input-Role"
              id="phonenumber"
              name="phonenumber"
              value={formData.phonenumber}
              onChange={handleChange}
            />
            {errors.phonenumber && (
              <span className="text-danger">{errors.phonenumber}</span>
            )}
          </div>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default Referal;
