import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams, Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { Rate } from "antd";
import axios from "axios";
import {baseURL} from "../../../Config/config"

function CardDetailsPage() {
  const { userId } = useParams();
  const [selectedQuiz, setSelectedQuiz] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [userData, setUserData] = useState({});
  const [rankingscore, setRankingscore] = useState(45);

  const [tagsValue, setTagsValue] = useState('0');
  const handleChange = (event) => {
    setTagsValue(event.target.value);
  };
  const handleChangeranking = (e) => {
    setRankingscore(e.target.value)
  };
  const accessTokenforuserid = localStorage.getItem("user");

  const accessTokenforuseridtwo = accessTokenforuserid.replace(/"/g, "");

  const [header, payload] = accessTokenforuseridtwo.split(".");
  const decodedPayload = JSON.parse(atob(payload));
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${baseURL}/Admin/getquizes/all/fgbusers/count/${userId}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();
        setUserData(jsonData.data);
      } catch (error) {}
    };

    fetchData();
  }, [userId]);
  const fetchDataall = () => {
    setIsLoading(true);
    fetch(`${baseURL}/Admin/get/Ranking/${userId}`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDataall();
  }, []);

  const uniqueData = Array.from(
    new Set(
      data.map((item) => JSON.stringify({ id: item.id, Topic: item.Topic }))
    )
  ).map((stringified) => JSON.parse(stringified));

  const uniqueAgesMap = new Map();
  const uniquelink = new Map();
  const uniquetopic = new Map();

  data.forEach((entry) => {
    const key = `${entry.id}-${entry.Topic}`;
    if (!uniquetopic.has(key)) {
      uniquetopic.set(key, entry.Title);
    }
  });

  data.forEach((entry) => {
    const key = `${entry.id}-${entry.Topic}`;
    if (!uniquelink.has(key)) {
      uniquelink.set(key, entry.link);
    }
  });

  data.forEach((entry) => {
    const key = `${entry.id}-${entry.Topic}`;
    if (!uniqueAgesMap.has(key)) {
      uniqueAgesMap.set(key, entry.Age);
    }
  });

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredData = searchQuery
    ? uniqueData.filter((item) =>
        item.Topic.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : uniqueData;

  const currentData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(0);
  };
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const extractVideoId = (link) => {
    const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  const filteredDatas = data.filter((item) => item.id === selectedQuiz);
  const openRankingPopup = (id) => {
    setSelectedQuiz(id);
  };

  const closeRankingPopup = () => {
    setSelectedQuiz(null);
  };

  // function calculateAndLogRankingScores(entries) {
  //   const scores = entries.map((entry) =>{
  //     const contentQualityRating = parseFloat(entry.rankContentQuality) || 0;
  //     const qualityOfDeliveryRating = parseFloat(entry.rankdelivery) || 0;
  //     const engagementLevelRating = parseFloat(entry.rankEngagementLevel) || 0;
  //     const durationRating = parseFloat(entry.rankDuration) || 0;
      
  //     const contentQualityWeight = 0.3;
  //     const qualityOfDeliveryWeight = 0.3;
  //     const engagementLevelWeight = 0.3;
  //     const durationWeight = 0.1;
      
  //     const contentQualityWeightedRating = contentQualityRating * contentQualityWeight;
  //     const qualityOfDeliveryWeightedRating = qualityOfDeliveryRating * qualityOfDeliveryWeight;
  //     const engagementLevelWeightedRating = engagementLevelRating * engagementLevelWeight;
  //     const durationWeightedRating = durationRating * durationWeight;
      
  //     const totalWeightedRating =
  //       contentQualityWeightedRating +
  //       qualityOfDeliveryWeightedRating +
  //       engagementLevelWeightedRating +
  //       durationWeightedRating;
      
  //     const maxPossibleRating = 5 * (contentQualityWeight + qualityOfDeliveryWeight + engagementLevelWeight + durationWeight);
      
  //     // const scaledScore = (totalWeightedRating / maxPossibleRating) * 45;
  //     const scaledScore = parseInt((totalWeightedRating / maxPossibleRating) * 45);

  //     console.log("Scaled Score (out of 45):", scaledScore);
  //     setRankingscore(scaledScore);
  //  console.log(entry.id,"+_+_+_+");

  //   })

  // }
  
  
  const savescore = (tagsValue,rankingscoree,videorankingId) => {
       
    const rankingscore = parseInt(tagsValue) + rankingscoree;
    let data = JSON.stringify({
      "rankingscore":rankingscore
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseURL}/Admin/update/rankingscore/${videorankingId}`,
      headers: { 
        'Content-Type': 'application/json', 
        "Authorization": `Bearer ${accessTokenforuseridtwo}`,
     },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setTagsValue("0");
        alert("Data Saved")
        closeRankingPopup();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {isLoading && (
        <div className="Loader">
          <Bars
            height={80}
            width={80}
            color="#4fa94d"
            ariaLabel="bars-loading"
          />
        </div>
      )}

      <div>
        <br />
        &nbsp;
        <Link to={`/Fgbstatus/${userId}`}>
          <img src="/Images/left-arrow.png" height={50} width={50} alt="leftarrow" />
        </Link>
        <div className="container mt-5">
          <div className="row">

            <div className="col">
              <strong>User ID:</strong> {userData.userId}
            </div>
            <div className="col">
              <strong>Full Name:</strong> {userData.fullName}
            </div>
            <div className="col">
              <strong>Completed:</strong> {userData.Count}
            </div>
            <div className="col">
              <strong>Total Remaining:</strong> {userData.TotalRemaining}
            </div>
            <div className="col">
              <strong>Total Video:</strong> {userData.TotalVideo}
            </div>
          </div>
        </div>
        <div className="table-responsive table-container">
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr className="table-primary">
                {/* <th>ID</th> */}
                <th>Video Link</th>
                <th>Title</th>
                <th>Topic</th>
                <th>Age</th>
                <th>Video Ranking</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, parentIndex) => (
                <React.Fragment
                  key={`parent-${item.id}-${item.Topic}-${parentIndex}`}
                >
                  {data
                    .filter(
                      (entry) =>
                        entry.id === item.id && entry.Topic === item.Topic
                    )
                    .map((entry, childIndex) => (
                      <tr
                        key={`child-${entry.id}-${entry.Topic}-${entry.Quiz}-${childIndex}`}
                      >
                        {/* {childIndex === 0 ? (
                          <td
                            className="center-cell"
                            rowSpan={
                              data.filter(
                                (e) =>
                                  e.id === item.id && e.Topic === item.Topic
                              ).length
                            }
                          >
                            {item.id}
                          </td>
                        ) : null} */}
                        {childIndex === 0 ? (
                          <td
                            rowSpan={
                              data.filter(
                                (e) =>
                                  e.id === item.id && e.Topic === item.Topic
                              ).length
                            }
                          >
                            <iframe
                              width="560"
                              height="315"
                              src={`https://www.youtube.com/embed/${extractVideoId(
                                uniquelink.get(`${item.id}-${item.Topic}`)
                              )}?modestbranding=1`}
                              frameborder="0"
                              allowfullscreen
                            ></iframe>
                          </td>
                        ) : null}

                        {childIndex === 0 ? (
                          <td
                            rowSpan={
                              data.filter(
                                (e) =>
                                  e.id === item.id && e.Topic === item.Topic
                              ).length
                            }
                          >
                            {uniquetopic.get(`${item.id}-${item.Topic}`)}
                          </td>
                        ) : null}
                        {childIndex === 0 ? (
                          <td
                            rowSpan={
                              data.filter(
                                (e) =>
                                  e.id === item.id && e.Topic === item.Topic
                              ).length
                            }
                          >
                            {item.Topic}
                          </td>
                        ) : null}
                        {childIndex === 0 ? (
                          <td
                            rowSpan={
                              data.filter(
                                (e) =>
                                  e.id === item.id && e.Topic === item.Topic
                              ).length
                            }
                          >
                            {uniqueAgesMap.get(`${item.id}-${item.Topic}`)}
                          </td>
                        ) : null}

                        <div className="d-inline-block">
                          <td>
                            <div style={{ width: "180px" }}>
                              <button
                                className="btn btn-secondary"
                                onClick={() => openRankingPopup(entry.id,entry.videorankingId)}
                              >
                                Ranking
                              </button>
                              {/* <div class="form-check">
  <input class="form-check-input" type="checkbox"  onClick={() => handleCheckout(entry.id)}/>
  <label class="form-check-label" for="flexCheckDefault">Push to production</label>
</div> */}

                            </div>
                          </td>
                          {selectedQuiz && (
                            <div className="popup">
                              <div className="popup-content">
      
                                <td>
                                  {selectedQuiz && (
                                    <div className="popup">
                                      <div className="popup-content">

  
                                        {filteredDatas.map((entry) => (
                                          <div key={entry.id}>
                                            
                                             <div className="popup-header d-flex justify-content-between align-items-center">
    <p className="mb-0 fs-4">Total Score: <span className="badge bg-primary">{entry.rankingscore}</span></p>
    <button
      className="btn btn-close text-white"
      onClick={closeRankingPopup}
    ></button>
  </div>
  <h5
                                          className="modal-title text-center"
                                          id="exampleModalLabel"
                                        >
                                          Ranking & Justification
                                        </h5>
                                          <div
                                            className="row bg-secondary mb-4"
                                          >
                                            {/* <h1>Score:-{entry.rankingscore}</h1> */}

                                            <div className="col-md-6 col-sm-12">
                                              <div style={{ height: "106px" }}>
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  Delivery
                                                </span>
                                                <br />
                                                <Rate
                                                  allowHalf
                                                  count={5}
                                                  value={parseFloat(
                                                    entry.rankdelivery
                                                  )}
                                                  character={
                                                    <i className="fas fa-star" />
                                                  }
                                                  style={{
                                                    fontSize: 24,
                                                    color: "yellow",
                                                  }}
                                                />
                                              </div>
                                              <div style={{ height: "106px" }}>
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  ContentQuality
                                                </span>
                                                <br />
                                                <Rate
                                                  allowHalf
                                                  count={5}
                                                  value={parseFloat(
                                                    entry.rankContentQuality
                                                  )}
                                                  character={
                                                    <i className="fas fa-star" />
                                                  }
                                                  style={{
                                                    fontSize: 24,
                                                    color: "yellow",
                                                  }}
                                                />
                                              </div>
                                              <div style={{ height: "106px" }}>
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  Engagement Level-
                                                </span>
                                                <br />
                                                <Rate
                                                  allowHalf
                                                  count={5}
                                                  value={parseFloat(
                                                    entry.rankEngagementLevel
                                                  )}
                                                  character={
                                                    <i className="fas fa-star" />
                                                  }
                                                  style={{
                                                    fontSize: 24,
                                                    color: "yellow",
                                                  }}
                                                />
                                              </div>
                                              <div style={{ height: "106px" }}>
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  Duration
                                                </span>
                                                <br />
                                                <Rate
                                                  allowHalf
                                                  count={5}
                                                  value={parseFloat(
                                                    entry.rankDuration
                                                  )}
                                                  character={
                                                    <i className="fas fa-star" />
                                                  }
                                                  style={{
                                                    fontSize: 24,
                                                    color: "yellow",
                                                  }}
                                                />

                       
                                              </div>

                     </div>
                                            <div className="col-md-6 col-sm-12">
                                              <div className="form-outline mb-4">
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  Delivery
                                                </span>
                                                <textarea
                                                  type="text"
                                                  id="form1Example1"
                                                  class="form-control bg-light"
                                                  name="Delivery"
                                                  required
                                                  value={entry.Delivery}
                                                  rows="2"
                                                />
                                              </div>
                                              <div className="form-outline mb-4">
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  ContentQuality
                                                </span>
                                                <textarea
                                                  type="text"
                                                  id="form1Example1"
                                                  className="form-control bg-light"
                                                  name="Content"
                                                  required
                                                  value={entry.ContentQuality}
                                                  rows="2"
                                                />
                                              </div>
                                              <div className="form-outline mb-4">
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  Engagement Level-
                                                </span>
                                                <textarea
                                                  type="text"
                                                  id="form1Example1"
                                                  class="form-control bg-light"
                                                  name="level"
                                                  required
                                                  value={entry.EngagementLevel}
                                                  rows="2"
                                                />
                                              </div>
                                              <div className="form-outline mb-4">
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  Duration
                                                </span>
                                                <textarea
                                                  type="text"
                                                  id="form1Example1"
                                                  class="form-control bg-light"
                                                  name="Duration"
                                                  required
                                                  rows="2"
                                                  value={entry.Duration}
                                                />
                                              </div>
                                            </div>
                                            <div className="average-ranking bg-primary text-white p-2">
                                              <h6>Total Ranking</h6>
                                              <Rate
                                                allowHalf
                                                count={5}
                                                value={parseFloat(
                                                  entry.totalRankaverage
                                                )}
                                                character={
                                                  <i className="fas fa-star" />
                                                }
                                                style={{
                                                  fontSize: 24,
                                                  color: "yellow",
                                                }}
                                              />
                                            </div>
                                            <div className="d-flex flex-row justify-content-center mb-3">
                                              <div className="p-2 text-center">
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <a
                                                    data-mdb-toggle="tooltip"
                                                    title="Rating* (Weighted average of below parameters) Content Quality (1-5) as per legend - 30% Quality of Delivery(1-5) 30% Engagement Level (1-5) 30% Duration (justified or not)(1-5) 10%"
                                                  >
                                                    Ranking{" "}
                                                    <i
                                                      className="fa fa-info-circle"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </a>
                                                </span>
                                                <input
                                                  type="number"
                                                  id="anecdotesInput"
                                                  className="form-control bg-light"
                                                  name="anecdotes"
                                                 value={rankingscore}
                                                 onChange={handleChangeranking}

                                                  required
                                                  style={{ width: "80px" }}
                                                />
                                              </div>
                                              <div className="p-2 text-center">
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <a
                                                    data-mdb-toggle="tooltip"
                                                    title="Justification for each of the Ratings Score 25"
                                                  >
                                                    Justification{" "}
                                                    <i
                                                      className="fa fa-info-circle"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </a>
      <input
        type="number"
        id="tagsInput"
        className="form-control bg-light"
        name="tags"
        value={tagsValue}
        onChange={handleChange}
        required
        style={{ width: "80px" }}
      />                                                </span>

                                              </div>
                                              <div className="p-2 text-center">
                                                {/* <button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  onClick={() => savescore(tagsValue,rankingscore,entry.videorankingId)}

                                                >
                                                  Submit
                                                </button> */}
                                                                <button
                  type="button"
                  class="btn btn-primary btn-lg" 
                  disabled={parseFloat(entry.rankingscore) > 0}
                  onClick={() => savescore(tagsValue,rankingscore,entry.videorankingId)}
                  >
                  Save
                </button>
                                              </div>
                                            </div>
                                          </div>
                                          </div>

                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </td>
                                <td></td>
                                <br />
                              </div>
                            </div>
                          )}
                        </div>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="pagination-container align-items-center">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CardDetailsPage;
