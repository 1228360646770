import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {baseURL} from "../../Config/config"

const UserProfile = () => {
  const [editMode, setEditMode] = useState(false);
  const storedUserDetails = JSON.parse(localStorage.getItem("profile"));
  const [errors, setErrors] = useState({});
  const [userDetails, setUserDetails] = useState(storedUserDetails || {});
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const accessTokenWithQuotes = localStorage.getItem("user");
  const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
  const [header, payload] = accessTokenWithoutQuotes.split(".");
  const decodedPayload = JSON.parse(atob(payload));
  const userId = decodedPayload.id;

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `${baseURL}/users/${userId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${accessTokenWithoutQuotes}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        setUserDetails(data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId, accessTokenWithoutQuotes]);

  const handleEditClick = () => {
    setEditMode(true);
    setErrors({});
  };

  const handleSaveClick = () => {
    const validationErrors = {};

    if (!userDetails.fullName || !userDetails.fullName.trim()) {
      validationErrors.fullName = "Full Name is required";
    }

    if (!userDetails.dob || !userDetails.dob.trim()) {
      validationErrors.dob = "Date of Birth is required";
    }

    if (!userDetails.gender || !userDetails.gender.trim()) {
      validationErrors.gender = "Gender is required";
    }

    if (!userDetails.email || !userDetails.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(userDetails.email)) {
      validationErrors.email = "Email is not valid";
    }

    if (!userDetails.phoneNumber || !userDetails.phoneNumber.trim()) {
      validationErrors.phoneNumber = "Phone Number is required";
    }

    if (!userDetails.role || !userDetails.role.trim()) {
      validationErrors.role = "Role is required";
    }

    if (Object.keys(validationErrors).length === 0) {
      const updatedUser = {
        fullName: userDetails.fullName,
        gender: userDetails.gender,
        dob: userDetails.dob,
        email: userDetails.email,
        phoneNumber: userDetails.phoneNumber,
        role: userDetails.role,
      };

      const accessTokenWithQuotes = localStorage.getItem("user");
      const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

      fetch(`${baseURL}/users/update/profile/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
        body: JSON.stringify(updatedUser),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(errorData.message);
            });
          }
          return response.json();
        })
        .then((responseData) => {
          setSuccessMessage("User details saved successfully.");
          setEditMode(false);
        })
        .catch((error) => {
          console.error("Error saving data:", error.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
        });
    } else {
      setErrors(validationErrors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
    setSuccessMessage("");
  };

  return (
    <div className="container p-3">
      <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold ">
        <h3>User Profile</h3>
      </div>
      <div className="row justify-content-center">
        <div className="registration-container mb-3 col-md-6 mt-5">
          <div>
            <div className="card-body">
              {successMessage && (
                <div className="alert alert-success">{successMessage}</div>
              )}
              {editMode ? (
                <form>
                  <div className="mb-3">
                    <label htmlFor="fullName" className="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      name="fullName"
                      value={userDetails.fullName}
                      onChange={handleInputChange}
                    />
                    {errors.fullName && (
                      <span className="text-danger">{errors.fullName}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label">
                      Gender
                    </label>
                    {/* <select
         id="gender"
         name="gender"
         className="input-Role"
         value={userDetails.gender}
         onChange={handleInputChange}
       >
         <option value="">Select Gender</option>
         <option value="Male">Male</option>
         <option value="Female">Female</option>
         <option value="prefer not to disclose">Prefer Not To Disclose</option>

       </select> */}
                    <div className="select-with-icon">
                      <select
                        id="gender"
                        name="gender"
                        className="input-Role"
                        value={userDetails.gender}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="prefer not to disclose">
                          Prefer Not To Disclose
                        </option>
                      </select>
                      <span className="custom-dropdown-icon"></span>
                    </div>
                    {errors.gender && (
                      <span className="text-danger">{errors.gender}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="dob" className="form-label">
                      Date Of Birth
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="dob"
                      name="dob"
                      value={userDetails.dob}
                      onChange={handleInputChange}
                    />
                    {errors.dob && (
                      <span className="text-danger">{errors.dob}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      value={userDetails.email}
                      onChange={handleInputChange}
                      disabled
                    />
                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={userDetails.phoneNumber}
                      onChange={handleInputChange}
                      disabled
                    />
                    {errors.phoneNumber && (
                      <span className="text-danger">{errors.phoneNumber}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      value={userDetails.username}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="role" className="form-label">
                      Role
                    </label>

                    <select
                      id="role"
                      name="role"
                      className="input-Role"
                      value={userDetails.role}
                      onChange={handleInputChange}
                      disabled
                    >
                      <option value="FGB">{userDetails.role}</option>
                    </select>
                    {errors.role && (
                      <span className="text-danger">{errors.role}</span>
                    )}
                  </div>
                  {/* <div className="mb-3">
  <label htmlFor="role" className="form-label">
    Role
  </label>

  <div className="select-with-icon">
    <select
      id="role"
      name="role"
      className="input-Role"
      value={userDetails.role}
      onChange={handleInputChange}
      disabled
    >
      <option value="FGB">{userDetails.role}</option>
    </select>
  </div>
  {errors.role && (
    <span className="text-danger">{errors.role}</span>
  )}
</div> */}

                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSaveClick}
                    >
                      Save
                    </button>
                  </div>
                </form>
              ) : (
                <table class="table">
                  <tbody>
                    <tr style={{ height: "35px" }}>
                      <td>
                        <span className="fw-bolder">Full Name </span>
                      </td>
                      <td>
                        <span class="space-span text-primary fw-bold">
                          :&nbsp;&nbsp;&nbsp;{userDetails.fullName || "N/A"}
                        </span>
                      </td>
                    </tr>
                    <tr style={{ height: "35px" }}>
                      <td>
                        <span className="fw-bolder">Gender</span>
                      </td>
                      <td>
                        <span class="space-span text-primary fw-bold">
                          : &nbsp;&nbsp;&nbsp;{userDetails.gender || "N/A"}
                        </span>
                      </td>
                    </tr>
                    <tr style={{ height: "35px" }}>
                      <td>
                        <span className="fw-bolder">Date Of Birth</span>
                      </td>
                      <td>
                        <span class="space-span text-primary fw-bold">
                          : &nbsp;&nbsp;&nbsp;{userDetails.dob || "N/A"}
                        </span>
                      </td>
                    </tr>
                    <tr style={{ height: "35px" }}>
                      <td>
                        <span className="fw-bolder">Email</span>
                      </td>
                      <td>
                        <span class="space-span text-primary fw-bold">
                          :&nbsp;&nbsp;&nbsp;{userDetails.email || "N/A"}
                        </span>
                      </td>
                    </tr>
                    <tr style={{ height: "35px" }}>
                      <td>
                        <span className="fw-bolder">Phone Number</span>
                      </td>
                      <td>
                        <span class="space-span text-primary fw-bold">
                          :&nbsp;&nbsp;&nbsp;{userDetails.phoneNumber || "N/A"}
                        </span>
                      </td>
                    </tr>
                    <tr style={{ height: "35px" }}>
                      <td>
                        <span className="fw-bolder">Username</span>
                      </td>
                      <td>
                        <span class="space-span text-primary fw-bold">
                          :&nbsp;&nbsp;&nbsp;{userDetails.username || "N/A"}
                        </span>
                      </td>
                    </tr>
                    <tr style={{ height: "35px" }}>
                      <td>
                        <span className="fw-bolder">Role</span>
                      </td>
                      <td>
                        <span class="space-span text-primary fw-bold">
                          :&nbsp;&nbsp;&nbsp;{userDetails.role || "N/A"}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              {!editMode && (
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleEditClick}
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          span {
            height: 50px;
          }
          /* Add your CSS styles here */
          .vertical-line {
            border-left: 1px solid #ccc;
            height: 100%;
            margin-right: 20px;
            margin-left: 20px;
          }
        `}
      </style>
    </div>
  );
};

export default UserProfile;
