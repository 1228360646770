// import React, { useState } from "react";
// import axios from "axios";
// import "../Css/RegisterForm.css";
// import Swal from "sweetalert2";
// import { Bars } from "react-loader-spinner";

// function RegisterForm() {
//   const [isLoading, setIsLoading] = useState(false);

//   const [formData, setFormData] = useState({
//     email: "",
//     username: "",
//     password: "",
//     role: "",
//     // gender: "",
//     fullName: "",
//     // dob: "",
//     phoneNumber: "",
//   });
//   const [errors, setErrors] = useState({});

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const validationErrors = {};

//     if (!formData.fullName.trim()) {
//       validationErrors.fullName = "Full Name is required";
//     }

//     // if (!formData.dob.trim()) {
//     //   validationErrors.dob = "Date of Birth is required";
//     // }

//     // if (!formData.gender.trim()) {
//     //   validationErrors.gender = "Gender is required";
//     // }

//     if (!formData.email.trim()) {
//       validationErrors.email = "Email is required";
//     } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
//       validationErrors.email = "Email is not valid";
//     }

//     if (!formData.phoneNumber.trim()) {
//       validationErrors.phoneNumber = "Phone Number is required";
//     }

//     // if (!formData.username.trim()) {
//     //   validationErrors.username = "Username is required";
//     // }
//     if (!formData.role.trim()) {
//         validationErrors.role = "Role is required";
//       }

//     if (!formData.password.trim()) {
//       validationErrors.password = "Password is required";
//     } else if (formData.password.length < 6) {
//       validationErrors.password = "Password should be at least 6 characters";
//     }

//     if (Object.keys(validationErrors).length === 0) {
//       setIsLoading(true);
//       axios
//         .post("${baseURL}/auth/register-with-email", formData, {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//           },
//         })
//         .then((response) => {
//             Swal.fire("Register Done!", response.data.data.fullName, "success");

//             setFormData({
//               email: "",
//               username: formData.email,
//               password: "",
//               role: "",
//               // gender: "",
//               fullName: "",
//               // dob: "",
//               phoneNumber: "",
//             });
//           })
//         .catch((error) => {
//           console.error("Error:", error.response.data.message);
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: error.response.data.message,
//           });
//         })
//         .finally(() => {
//           setIsLoading(false);
//         });
//     } else {
//       setErrors(validationErrors);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     setErrors({ ...errors, [name]: "" });
//   };

//   return (
//     <div className="p-3">
//        {isLoading && (
//         <div className="Loader">
//           <Bars
//             height={80}
//             width={80}
//             color="#4fa94d"
//             ariaLabel="bars-loading"
//           />
//         </div>
//       )}
//       <div class="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
//         <h3>Add New User</h3>
//       </div>

//       <div className="registration-container mb-5">
//         <form onSubmit={handleSubmit} className="registration-form">
//           <div className="form-group">
//             <label htmlFor="fullName">Full Name:</label>
//             <input
//               className="input-Role"
//               type="text"
//               id="fullName"
//               name="fullName"
//               value={formData.fullName}
//               onChange={handleChange}
//             />
//             {errors.fullName && (
//               <span className="text-danger">{errors.fullName}</span>
//             )}
//           </div>

//           {/* <div className="form-group">
//             <label htmlFor="dob">Date Of Birth</label>
//             <input
//               className="input-Role"
//               type="date"
//               id="dob"
//               name="dob"
//               value={formData.dob}
//               onChange={handleChange}
//             />
//             {errors.dob && <span className="text-danger">{errors.dob}</span>}
//           </div>

//           <div className="form-group">
//             <label htmlFor="gender">Gender</label>
//             <select
//               id="gender"
//               name="gender"
//               className="input-Role"
//               value={formData.gender}
//               onChange={handleChange}
//             >
//               <option value="">Select Gender</option>
//               <option value="Male">Male</option>
//               <option value="Female">Female</option>
//               <option value="prefer not to disclose">Prefer Not To Disclose</option>
//             </select>
//             {errors.gender && <span className="text-danger">{errors.gender}</span>}
//           </div> */}

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input
//               type="email"
//               className="input-Role"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//             />
//             {errors.email && <span className="text-danger">{errors.email}</span>}
//           </div>

//           <div className="form-group">
//             <label htmlFor="phoneNumber">Phone Number:</label>
//             <input
//               type="text"
//               className="input-Role"
//               id="phoneNumber"
//               name="phoneNumber"
//               value={formData.phoneNumber}
//               onChange={handleChange}
//             />
//             {errors.phoneNumber && (
//               <span className="text-danger">{errors.phoneNumber}</span>
//             )}
//           </div>

//           <div className="form-group">
//             <label htmlFor="username">Username:</label>
//             <input
//               type="text"
//               id="username"
//               className="input-Role"
//               name="username"
//               value={formData.email}
//              onChange={handleChange}
//              disabled
//             />
//             {/* {errors.username && <span className="text-danger">{errors.username}</span>} */}
//           </div>

//           <div className="form-group">
//             <label htmlFor="password">Password:</label>
//             <input
//               type="password"
//               id="password"
//               className="input-Role"
//               name="password"
//               value={formData.password}
//               onChange={handleChange}
//             />
//             {errors.password && <span className="text-danger">{errors.password}</span>}
//           </div>

//           <div className="form-group">
//             <label htmlFor="role">Role:</label>
//             <select
//               id="role"
//               name="role"
//               className="input-Role"
//               value={formData.role}
//               onChange={handleChange}
//             >
//               <option value="">Select Role</option>
//               <option value="FGB">FGB</option>
//               <option value="FGB">ADMIN</option>

//             </select>
//             {errors.role && <span className="text-danger">{errors.role}</span>}

//           </div>

//           <button type="submit">Register</button>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default RegisterForm;
import React, { useState } from "react";
import axios from "axios";
// import "../Css/RegisterForm.css";
import "../../../Assets/Css/RegisterForm.css";
import { baseURL } from "../../../Config/config"
import { Link } from 'react-router-dom';

import Swal from "sweetalert2";
import { Bars } from "react-loader-spinner";

function RegisterForm() {
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "Signal@123",
    role: "",
    fullName: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!formData.fullName.trim()) {
      validationErrors.fullName = "Full Name is required";
    }

    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is not valid";
    }

    if (!formData.phoneNumber.trim()) {
      validationErrors.phoneNumber = "Phone Number is required";
    }

    if (!formData.role.trim()) {
      validationErrors.role = "Role is required";
    }

    if (!formData.password.trim()) {
      validationErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      validationErrors.password = "Password should be at least 6 characters";
    }

    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      axios
        .post(`${baseURL}/auth/register-with-email`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          Swal.fire("Register Done!", response.data.data.fullName, "success");
          setFormData({
            ...formData,
            fullName: "",
            phoneNumber: "",
            role: "",
            email: ""

          });

          setIsLoading(false);
        })

        .catch((error) => {
          setIsLoading(false);

          console.error("Error:", error.response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {

      setErrors(validationErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setFormData({ ...formData, [name]: value, username: value });
    }
    else if (name === "phoneNumber") {
      const cleanedValue = value.replace(/\D/g, '').slice(0, 10);
      setFormData({ ...formData, [name]: cleanedValue });
    }
    else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };


  return (
    <div className="p-3">
      {isLoading && (
        <div className="Loader">
          <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
        </div>
      )}
      {/* <div class="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
        <h3>Add New User</h3>
      </div> */}
      <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
          <div className="row align-items-center">
            <div className="col-auto">
              <Link to="/Adminview">
                <img src="/Images/left-arrow-new.png" height={50} width={50}/>
              </Link>
            </div>
            <div className="col">
              <h3>Add New User</h3>
            </div>
          </div>
        </div>

      {/* <div className="mt-2">
        <Link to="/Adminview">
          <img src="/Images/left-arrow.png"
            height={50}
            width={50}
          />
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </div> */}

      <div className="registration-container mb-5">
        <form onSubmit={handleSubmit} className="registration-form">
          <div className="form-group">
            <label htmlFor="fullName">Full Name:</label>
            <input
              className="input-Role"
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
            {errors.fullName && <span className="text-danger">{errors.fullName}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="input-Role"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className="text-danger">{errors.email}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              type="number"
              className="input-Role"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              maxLength={10}
            />
            {errors.phoneNumber && (
              <span className="text-danger">{errors.phoneNumber}</span>
            )}
          </div>

          <div className="form-group" style={{ display: "none" }}>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              className="input-Role"
              name="username"
              value={formData.email}
              onChange={handleChange}
              disabled
            />
          </div>

          <div className="form-group" style={{ display: "none" }}>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              className="input-Role"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && <span className="text-danger">{errors.password}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="role">Role:</label>
            <div className="select-with-icon">
              <select
                id="role"
                name="role"
                className="input-Role"
                value={formData.role}
                onChange={handleChange}
              >
                <option value="">Select Role</option>
                <option value="FGB">FGB</option>
                <option value="ADMIN">ADMIN</option>
                <option value="EVALUATOR">EVALUATOR</option>

              </select>
              <span className="custom-dropdown-icon"></span>
            </div>
            {errors.role && <span className="text-danger">{errors.role}</span>}
          </div>


          <button type="submit">Register</button>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm;
