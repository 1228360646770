import axios from "axios";
import {baseURL} from "../../Config/config"

export const FETCH_PENDINGVIDEOS_REQUEST = "FETCH_PENDINGVIDEOS_REQUEST";
export const FETCH_PENDINGVIDEOS_SUCCESS = "FETCH_PENDINGVIDEOS_SUCCESS";
export const FETCH_PENDINGVIDEOS_FAILURE = "FETCH_PENDINGVIDEOS_FAILURE";

export const fetchpendingvideosRequest = () => ({
  type: FETCH_PENDINGVIDEOS_REQUEST,
});

export const fetchpendingvideosSuccess = (data) => ({
  type: FETCH_PENDINGVIDEOS_SUCCESS,
  payload: data,
});

export const fetchpendingvideosFailure = (error) => ({
  type: FETCH_PENDINGVIDEOS_FAILURE,
  payload: error,
});

export const fetchpendingvideos = (userId,selectedMonth) => async (dispatch) => {
  dispatch(fetchpendingvideosRequest());
  try {
    const response = await axios.get(
      `${baseURL}/Admin/getquizes/Pending/All/${userId}/${selectedMonth}`
    );
    dispatch(fetchpendingvideosSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchpendingvideosFailure(error.message));
  }
};
