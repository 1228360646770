import axios from 'axios';
import getUserIdFromToken from '../../Utils/JWT/jwt';
import {baseURL} from "../../Config/config"
import {NotificationManager } from 'react-notifications';

export const FETCH_QUIZES_REQUEST = 'FETCH_QUIZES_REQUEST';
export const FETCH_QUIZES_SUCCESS = 'FETCH_QUIZES_SUCCESS';
export const FETCH_QUIZES_FAILURE = 'FETCH_QUIZES_FAILURE';

export const fetchQuizesRequest = () => ({
  type: FETCH_QUIZES_REQUEST,
});

export const fetchQuizesSuccess = (data) => ({
  type: FETCH_QUIZES_SUCCESS,
  payload: data,
});

export const fetchQuizesFailure = (error) => ({
  type: FETCH_QUIZES_FAILURE,
  payload: error,
});

export const fetchanecdotestags = () => {
  return (dispatch) => {
    const userId = getUserIdFromToken();

    dispatch(fetchQuizesRequest());
    const accessTokenWithQuotes = localStorage.getItem("user");
    const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
    axios
      .get(`${baseURL}/Admin/getall/anedotestags/${userId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessTokenWithoutQuotes}`,
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData.data)) {
          const data = responseData.data;
          dispatch(fetchQuizesSuccess(data));
        } else {
          dispatch(fetchQuizesFailure('Data received is not an array'));
        }
      })
      .catch((error) => {
        dispatch(fetchQuizesFailure(error.message));
      });
  };
};




export const UPDATE_QUIZ_ANSWER_REQUEST = 'UPDATE_QUIZ_ANSWER_REQUEST';
export const UPDATE_QUIZ_ANSWER_SUCCESS = 'UPDATE_QUIZ_ANSWER_SUCCESS';
export const UPDATE_QUIZ_ANSWER_FAILURE = 'UPDATE_QUIZ_ANSWER_FAILURE';

export const updateQuizAnswerRequest = () => ({
    type: UPDATE_QUIZ_ANSWER_REQUEST,
  });
  
  export const updateQuizAnswerSuccess = (data) => ({
    type: UPDATE_QUIZ_ANSWER_SUCCESS,
    payload: data,
  });
  
  export const updateQuizAnswerFailure = (error) => ({
    type: UPDATE_QUIZ_ANSWER_FAILURE,
    payload: error,
  });
  
  export const updateanecdotesnew = (id, updatedData) => {
    return (dispatch) => {
      dispatch(updateQuizAnswerRequest());
      const accessTokenWithQuotes = localStorage.getItem("user");
      const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
  
      const formDataToSave = {
        Anedotes1: updatedData.Anedotes1,
        Anedotes2: updatedData.Anedotes2,
        Anedotes3: updatedData.Anedotes3,
        Anedotes4: updatedData.Anedotes4,
        Anedotestime1: updatedData.Anedotestime1,
        Anedotestime2: updatedData.Anedotestime2,
        Anedotestime3: updatedData.Anedotestime3,
        Anedotestime4: updatedData.Anedotestime4,
        tags1: updatedData.tags1,
        tags2: updatedData.tags2,
        tags3: updatedData.tags3,
        tags4: updatedData.tags4,
        tags5: updatedData.tags5,
        tags6: updatedData.tags6,
        tags7: updatedData.tags7,
        tags8: updatedData.tags8,
        tags9: updatedData.tags9,
        tags10: updatedData.tags10
      };
  
      console.log("_______________________!!!!!!!!!@#$%^&&&&&&&&&&&&&&&&&&&&&",id)
      axios
        .put(`${baseURL}/Admin/update/anedotestags/${id}`, formDataToSave, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessTokenWithoutQuotes}`,
          },
        })
        .then((response) => {
          const responseData = response.data;
          dispatch(updateQuizAnswerSuccess(responseData));
          NotificationManager.success('Data Updated', 'Success');

        })
        .catch((error) => {
          dispatch(updateQuizAnswerFailure(error.message));
        });
    };
  };
  <NotificationManager/>